import styled from "styled-components";

import { minMedia } from "container/global.styles";
import { maxMedia } from "container/global.styles";

const Container = styled.div`
    background-color: ${(props) => props.backgroundColor};

    ${(props) => (props.withColor ? "padding" : "margin")}: 25px 0 25px;

    ${minMedia.desktop`
        display: grid;
        text-align: center;
        ${(props) => (props.withColor ? "padding" : "margin")}: 70px 0 70px;
   `};
`;

const VideoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const IframeWrapper = styled.div`
    ${minMedia.desktop`
        ${(props) => (props.heightDesktop ? `height: ${props.heightDesktop}px;` : "")}
        ${(props) => (props.widthDesktop ? `width: ${props.widthDesktop}px;` : "")}
   `};

   ${maxMedia.desktop`
        ${(props) => (props.heightMobile ? `height: ${props.heightMobile}px;` : "")}
        ${(props) => (props.widthMobile ? `width: ${props.widthMobile}px;` : "")}
   `};
`;

export default {
    Container,
    VideoWrapper,
    IframeWrapper
};
