import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

import { Link } from "utils/link";

const Overlay = styled.div`
    background-color: rgba(0, 0, 0, 0.45);
    bottom: 0;
    left: 0;
    padding: ${(props) => (props.enablePadding ? "90px 20px 30px 20px" : "0 0 0 0")};
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${(props) => props.theme.zIndex.overlay};
`;

const Container = styled.div`
    align-items: center;
    border-radius: 0;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto auto;
    max-width: 950px;
    overflow-y: auto;
    position: relative;
    max-height: 100vh;

    ${minMedia.desktop`
        height: 100%;
        max-height: 100%;
        padding: 40px 20px 20px 20px;
    `};
`;

const ImageContainer = styled(Link)`
    display: block;
    margin: 0 auto;
    position: relative;
`;

const Close = styled.div`
    align-items: center;
    color: ${Colors.WHITE_FFFFFF};
    cursor: pointer;
    display: flex;
    height: 60px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    z-index: ${(props) => props.theme.zIndex.modalClose};
`;

const ImageWrapper = styled.div`
    height: 100%;
    position: relative;

    img {
        bottom: 0;
        height: 100%;
        left: 0;
        object-fit: contain;
        max-height: 100vh;
        max-width: 100%;
        right: 0;
        top: 0;
    }
`;

export default {
    Overlay,
    Container,
    Close,
    ImageContainer,
    ImageWrapper,
};
