import styled from "styled-components";

import { Colors } from "container/global.colors";
import { maxMedia, minMedia } from "container/global.styles";

const Frame = styled.div`
    margin: 20px 0;

    ${minMedia.desktop`
        background-color: ${(props) => props.backgroundColor};
        margin: 5px 0;
    `};
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;

    ${minMedia.desktop`
        align-items: center;
        display: table-row;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: auto auto;
        justify-content: start;
        min-height: ${(props) => props.minHeightDesktop}px;
    `};
`;

const ImageWrapper = styled.div`
    background-color: ${(props) => props.backgroundColor};
    display: flex;
    flex-direction: column;
    height: ${(props) => (props.objectFit === "contain" ? "initial" : "250px")};
    justify-content: center;
    min-height: 200px;
    position: relative;
    width: 100%;

    img {
        position: ${(props) => (props.objectFit === "contain" ? "relative" : "absolute")};
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        object-fit: ${(props) => props.objectFit};
        object-position: center ${(props) => props.objectPosition}%;
    }

    ${minMedia.desktop`
        align-self: stretch;
        height: 100%;
        min-height: ${(props) => props.minHeightDesktop}px;
        transform: translateX(${(props) => (props.rtl ? "30px" : "-30px")});
        max-height: 476px;
        max-width: 676px;
        min-width: 126px;
    `}
`;

const Content = styled.div`
    background-color: ${(props) => props.backgroundColor};
    box-sizing: border-box;
    padding: 20px 15px;
    width: 100%;

    ${minMedia.desktop`
        display: table-cell;
        flex-direction: column;
        flex: 1 1 660px;
        justify-content: center;
        margin: 0;
        padding: 20px 50px;
    `}
`;

const TextContainer = styled.div`
    hyphens: auto;
`;

const DescriptionContainer = styled.div`
    display: none;

    ${maxMedia.desktop`
        display: block;
        margin-top: 20px;
    `};
`;

const MobileDescriptionContainer = styled.div`
    display: block;
    margin-top: 20px;

    ${minMedia.desktop`
        display: none;
        margin-top: 0;
    `};
`;

const TabletDescriptionContainer = styled.div`
    display: none;

    ${minMedia.desktop`
        display: block;
        padding: 70px 25px;
    `};

    ${maxMedia.desktop`
        display: none;
        margin-top: 0;
    `};
`;

const Link = styled.div`
    color: ${Colors.BLUE_005EA8};
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 16px 0 0;
    text-decoration: none;

    ${minMedia.desktop`
        font-size: 20px;
        line-height: 27px;
        margin: 20px 0 0;
    `}
`;

export default {
    Frame,
    Container,
    ImageWrapper,
    Content,
    TextContainer,
    DescriptionContainer,
    MobileDescriptionContainer,
    TabletDescriptionContainer,
    Link,
};
