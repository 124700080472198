import React, { memo, useContext } from "react";
import { GoogleMap as Map, useJsApiLoader } from "@react-google-maps/api";
import PropTypes from "prop-types";

import { Markers } from "components/map/component/google-map/markers";

import { MapContext } from "context/map.context";

import geoJSON from "../../germany2.geo.json";
import mapStyleJSON from "./google-map.style.json";

export const GoogleMap = memo(({ locationsJson, center, zoom, children }) => {
    const { mapRef } = useContext(MapContext);
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.GOOGLE_API_KEY || "AIzaSyDj2tdX9NyXSVu0-cnKPgfYnZlhQI25AZQ",
        language: "de",
    });

    const onMapLoad = (map) => {
        map.data.addGeoJson(geoJSON);
        map.data.setStyle({
            fillColor: "#e2f1ee",
            fillOpacity: 0.4,
            opacity: 0,
            strokeWeight: 1,
            zIndex: 0,
        });

        mapRef.current = map;
    };

    const containerStyle = {
        width: "100%",
        height: "100%",
    };

    return (
        <>
            {isLoaded ? (
                <Map
                    ref={mapRef}
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={zoom}
                    options={{
                        fullscreenControl: false,
                        mapTypeControl: false,
                        streetViewControl: false,
                        zoomControl: true,
                        zoomControlOptions: {
                            position: 1,
                        },
                        styles: mapStyleJSON,
                    }}
                    mapTypeId="terrain"
                    onLoad={onMapLoad}
                >
                    <Markers locationsJson={locationsJson} />

                    {children}
                </Map>
            ) : (
                <></>
            )}
        </>
    );
});

GoogleMap.displayName = "GoogleMap";

GoogleMap.defaultProps = {
    locationsJson: null,
    children: null,
};

GoogleMap.propTypes = {
    locationsJson: PropTypes.object,
    center: PropTypes.object.isRequired,
    zoom: PropTypes.number.isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default GoogleMap;
