export class ArrayHelper {
    static isNullOrEmpty(array) {
        return array === null || array.length === 0;
    }

    static includes(needle, haystack) {
        return haystack.some((item) => item === needle);
    }

    static first(array, callback) {
        const filteredArray = array.filter(callback);
        if (Array.isArray(filteredArray) && filteredArray.length > 0) {
            return filteredArray.shift();
        }
        return null;
    }

    static last(array, callback) {
        const filteredArray = array.filter(callback);
        if (Array.isArray(filteredArray) && filteredArray.length > 0) {
            return filteredArray.pop();
        }
        return null;
    }

    static move(array, from, to) {
        const startIndex = from < 0 ? array.length + from : from;
        if (startIndex >= 0 && startIndex < array.length) {
            const endIndex = to < 0 ? array.length + to : to;

            const [item] = array.splice(from, 1);
            array.splice(endIndex, 0, item);
        }
        return null;
    }
}

export default ArrayHelper;
