import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Container = styled.div`
    background-color: ${(props) => props.backgroundColor};
    margin: 15px 0 0;
    text-align: left;
    height: 100%;
    width: 100%;

    &:first-child {
        margin-top: 0;
    }

    ${minMedia.desktop`
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0 20px;

        &:first-child {
            margin-left: 0;
         }

        &:last-child {
            margin-right: 0;
        }
    `};
`;

const ImageWrapper = styled.div`
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 70%;

    img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

const Flag = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: ${Colors.WHITE_FFFFFF};
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    position: absolute;
    left: 0;
    top: 0;
    height: 33px;
    background-color: ${Colors.BLUE_063773};
    padding: 0 10px;
    width: 210px;
    z-index: 1;

    &:after {
        width: 0;
        height: 0;
        border-top: 33px solid ${Colors.BLUE_063773};
        border-right: 10px solid transparent;
        display: block;
        position: absolute;
        content: "";
        right: -10px;
        top: 0;
    }

    ${minMedia.desktop`
        height: 50px;
        padding: 0 25px;
        font-size: 20px;
        width: 285px;

        &:after {
            border-top: 50px solid ${Colors.BLUE_063773};
            border-right: 15px solid transparent;
            right: -15px;
        }
    `};
`;

const Content = styled.div`
    box-sizing: border-box;
    flex: 1 1 auto;
    padding: 20px 15px;
    // text-align: ${(props) => props.textAlign};
    width: 100%;

    ${minMedia.desktop`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 25px 35px 40px;
    `};
`;

const TextContainer = styled.div`
    hyphens: auto;

    p {
        a,
        span.linkPlaceholder {
            color: ${Colors.BLUE_005EA8} !important;
            font-weight: ${(props) => props.theme.fontWeight.regular} !important;
        }
    }
`;

const SubTitle = styled.div`
    color: ${Colors.GREY_575757};
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    margin: 0;
    overflow: hidden;

    ${minMedia.desktop`
        line-height: 27px;
        font-size: 20px;
        min-height: 81px;
    `}
`;

const Date = styled.div`
    border-bottom: 1px solid ${Colors.GREY_999999};
    color: ${Colors.GREY_999999};
    font-size: 14px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 8px 0 0;
    width: 63%;

    ${minMedia.desktop`
        font-size: 16px;
        line-height: 30px;
        margin: 12px 0 0;
    `}
`;

const Paragraph = styled.div`
    color: ${Colors.GREY_575757};
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 16px 0 0;

    ${minMedia.desktop`
        font-size: 20px;
        line-height: 27px;
        margin: 20px 0 0;
    `}
`;

const Link = styled.div`
    color: ${Colors.BLUE_005EA8};
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 16px 0 0;
    text-decoration: none;

    ${minMedia.desktop`
        font-size: 20px;
        line-height: 27px;
        margin: 20px 0 0;
    `}
`;

export default {
    Container,
    ImageWrapper,
    Flag,
    Content,
    TextContainer,
    SubTitle,
    Paragraph,
    Date,
    Link,
};
