import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const spinAround = keyframes`
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
`;

const Wrapper = styled.div`
    height: ${(props) => props.size};
    left: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: ${(props) => props.size};
`;

const Span = styled.span`
    animation: ${spinAround} 700ms infinite linear;
    border: 0;
    border-radius: 0;
    content: "";
    height: ${(props) => props.size};
    left: 0;
    position: absolute;
    top: 0;
    width: ${(props) => props.size};
`;

export const SpinnerCircleIcon = ({ size, ...rest }) => {
    return (
        <svg
            height={size}
            width={size}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            x="0px"
            y="0px"
            {...rest}
        >
            <path
                opacity={0.3}
                fill="currentColor"
                d="M10,0C4.5,0,0,4.5,0,10c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10C20,4.5,15.5,0,10,0z M10,16c-3.3,0-6-2.7-6-6c0-3.3,2.7-6,6-6c3.3,0,6,2.7,6,6C16,13.3,13.3,16,10,16z"
            />

            <path fill="currentColor" d="M0,10h4c0-3.3,2.7-6,6-6V0C4.5,0,0,4.5,0,10z" />
        </svg>
    );
};

SpinnerCircleIcon.defaultProps = {
    size: "20px",
};

SpinnerCircleIcon.propTypes = {
    size: PropTypes.string,
};

export const Spinner = ({ size }) => {
    return (
        <Wrapper size={size}>
            <Span size={size}>
                <SpinnerCircleIcon size={size} />
            </Span>
        </Wrapper>
    );
};

Spinner.defaultProps = {
    size: "20px",
};

Spinner.propTypes = {
    size: PropTypes.string,
};

export default Spinner;
