import React from "react";
import PropTypes from "prop-types";

import { Next } from "icons/next";
import { Previous } from "icons/previous";

import Styles from "./arrows.styles";

// eslint-disable-next-line react/display-name
export const ArrowLeft = React.forwardRef(
    ({ arrows, arrowLeft, arrowLeftDisabled, addArrowClickHandler, prevSlide, disabled }, ref) => {
        const leftArrowWithAddedHandler = (element, name, disable = false) => (
            <Styles.LeftCustomArrowWrapper
                onClick={addArrowClickHandler ? prevSlide : null}
                ref={ref}
                disabled={disable}
            >
                {element}
            </Styles.LeftCustomArrowWrapper>
        );

        if (arrowLeft) {
            if (!disabled) {
                return leftArrowWithAddedHandler(arrowLeft, "arrowLeft");
            }
            const arrow = arrowLeftDisabled || arrowLeft;
            return leftArrowWithAddedHandler(arrow, "arrowLeft", disabled);
        }

        if (arrows) {
            return (
                <Styles.LeftArrowWrapper onClick={prevSlide} ref={ref} disabled={disabled}>
                    <Previous height="100px" width="27px" />
                </Styles.LeftArrowWrapper>
            );
        }
        return null;
    }
);

ArrowLeft.defaultProps = {
    arrowLeft: null,
    arrowLeftDisabled: null,
};

ArrowLeft.propTypes = {
    addArrowClickHandler: PropTypes.bool.isRequired,
    arrowLeft: PropTypes.element,
    arrowLeftDisabled: PropTypes.element,
    arrows: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    prevSlide: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/display-name
export const ArrowRight = React.forwardRef(
    ({ arrows, arrowRight, arrowRightDisabled, addArrowClickHandler, nextSlide, disabled }, ref) => {
        const rightArrowWithAddedHandler = (element, name, disable = false) => (
            <Styles.RightCustomArrowWrapper
                onClick={addArrowClickHandler ? nextSlide : null}
                ref={ref}
                disabled={disable}
            >
                {element}
            </Styles.RightCustomArrowWrapper>
        );

        if (arrowRight) {
            if (!disabled) {
                return rightArrowWithAddedHandler(arrowRight, "arrowRight");
            }
            const arrow = arrowRightDisabled || arrowRight;
            return rightArrowWithAddedHandler(arrow, "arrowRight", disabled);
        }
        if (arrows) {
            return (
                <Styles.RightArrowWrapper onClick={nextSlide} ref={ref} disabled={disabled}>
                    <Next height="100px" width="27px" />
                </Styles.RightArrowWrapper>
            );
        }
        return null;
    }
);

ArrowRight.defaultProps = {
    arrowRight: null,
    arrowRightDisabled: null,
};

ArrowRight.propTypes = {
    addArrowClickHandler: PropTypes.bool.isRequired,
    arrowRight: PropTypes.element,
    arrowRightDisabled: PropTypes.element,
    arrows: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    nextSlide: PropTypes.func.isRequired,
};
