import { useReducer } from "react";
import axios from "axios";

import { getStrapiURL } from "lib/api";

export const API_REQUEST_FETCHING = "API_REQUEST_FETCHING";
export const API_REQUEST_SUCCESS = "API_REQUEST_SUCCESS";
export const API_REQUEST_ERROR = "API_REQUEST_ERROR";

axios.interceptors.response.use(undefined, (error) => {
    return Promise.reject(!navigator.onLine ? { ...error, offline: true } : error);
});

const initialState = {
    status: null,
    response: null,
};

const reducer = (state = initialState, { type, response } = {}) => {
    switch (type) {
        case API_REQUEST_FETCHING:
            return { ...initialState, status: API_REQUEST_FETCHING };
        case API_REQUEST_SUCCESS:
            return { ...state, status: API_REQUEST_SUCCESS, response };
        case API_REQUEST_ERROR:
            return { ...state, status: API_REQUEST_ERROR, response };
        default:
            return state;
    }
};

const fetching = () => ({ type: API_REQUEST_FETCHING });
const success = (response) => ({ type: API_REQUEST_SUCCESS, response });
const error = (response) => ({ type: API_REQUEST_ERROR, response });

export const usePostApiRequest = (endpoint, params = {}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const request = (data, getParams = {}) => {
        dispatch(fetching());
        return new Promise((resolve, reject) => {
            axios
                .post(getStrapiURL(endpoint), data, {
                    validateStatus: (status) => status >= 200 && status < 307,
                    timeout: 20000,
                    ...params,
                    ...getParams,
                })
                .then((response) => {
                    // console.log(response);
                    dispatch(success(response));
                    resolve(response);
                })
                .catch((e) => {
                    // console.log("error");
                    // console.log(e);
                    dispatch(error(e));
                    reject(e);
                });
        });
    };
    return [state, request];
};

export default usePostApiRequest;
