import { useState } from "react";

export const useResettableState = (initialState) => {
    const [state, setState] = useState(initialState);

    const resetState = () => {
        setState(initialState);
    };

    return [state, setState, resetState];
};

export default useResettableState;
