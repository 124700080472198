import React from "react";
import PropTypes from "prop-types";

export const Checkbox = ({ height, width, isChecked, ...rest }) => {
    return (
        (isChecked && (
            <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...rest}>
                <g fillRule="evenodd">
                    <path fill="#005EA8" d="M0 3a3 3 0 013-3h16a3 3 0 013 3v16a3 3 0 01-3 3H3a3 3 0 01-3-3V3z" />
                    <path
                        fill="#FFFFFF"
                        d="M4.889 11.188l1.344-1.316 3.457 3.384 7.299-7.145 1.344 1.316L9.69 15.89 4.89 11.188z"
                    />
                </g>
            </svg>
        )) || (
            <svg height={height} width={width} viewBox="0 0 92 92" xmlns="http://www.w3.org/2000/svg" {...rest}>
                <g fillRule="evenodd">
                    <path
                        d="M0 12.5C0 5.596 5.596 0 12.5 0h66.667c6.904 0 12.5 5.596 12.5 12.5v66.667c0 6.904-5.596 12.5-12.5 12.5H12.5c-6.904 0-12.5-5.596-12.5-12.5V12.5z"
                        fill="#FFFFFF"
                    />
                    <path
                        d="M0 12.5C0 5.596 5.596 0 12.5 0h66.667c6.904 0 12.5 5.596 12.5 12.5v66.667c0 6.904-5.596 12.5-12.5 12.5H12.5c-6.904 0-12.5-5.596-12.5-12.5V12.5zm4.167 0A8.331 8.331 0 0112.5 4.167h66.667A8.331 8.331 0 0187.5 12.5v66.667a8.331 8.331 0 01-8.333 8.333H12.5a8.331 8.331 0 01-8.333-8.333V12.5z"
                        fill="currentColor"
                    />
                </g>
            </svg>
        )
    );
};

Checkbox.defaultProps = {
    width: "22px",
    height: "22px",
};

Checkbox.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
    isChecked: PropTypes.bool.isRequired,
};

export default Checkbox;
