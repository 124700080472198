import React from "react";
import PropTypes from "prop-types";

export const Close = ({ height, width, ...rest }) => {
    return (
        <svg height={height} width={width} viewBox="0 0 352 352" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fill="currentColor"
                d="M242.72 176L342.79 75.93c12.28-12.28 12.28-32.19 0-44.48L320.55 9.21c-12.28-12.28-32.19-12.28-44.48 0L176 109.28 75.93 9.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 31.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 176 9.21 276.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 242.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 176z"
            />
        </svg>
    );
};

Close.propTypes = {
    height: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
};

export default Close;
