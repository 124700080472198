import React, { useContext, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import set from "lodash/set";
import PropTypes from "prop-types";

import { ErrorMessage } from "components/error-message/error-message";

import { FormDataContext } from "context/form-data.context";

import { FileTypeEnum } from "enumeration/file-type.enum";
import { FileUploadErrorTypeEnum } from "enumeration/file-upload-error.enum";

import { ArrayHelper } from "helper/array-helper";
import { FileUploadHelper } from "helper/file-upload-helper";
import { StringHelper } from "helper/string-helper";

import { Bin } from "icons/bin";
import { File } from "icons/file";

import Styles from "./file-upload.styles";

const mapErrors = (uploadStatus, fileName) => {
    switch (uploadStatus) {
        case FileUploadErrorTypeEnum.FILE_MAX_NUMBER:
            return `${fileName} konnte nicht hochgeladen werden (max. Anzahl überschritten)`;
        case FileUploadErrorTypeEnum.FILE_MAX_SIZE:
            return `${fileName} konnte nicht hochgeladen werden (max. Größe überschritten)`;
        case FileUploadErrorTypeEnum.FILE_MIME_TYPE:
            return `${fileName} konnte nicht hochgeladen werden (ungültiges Dateiformat)`;
        case FileUploadErrorTypeEnum.FILE_DUPLICATE:
            return `${fileName} konnte nicht hochgeladen werden (Dokument bereits vorhanden)`;
        case FileUploadErrorTypeEnum.FILE_CREATE_CHUNKS:
            return "Ihre Dokumente konnten gelesen werden";
        case FileUploadErrorTypeEnum.FILE_UPLOAD_CHUNKS:
        case FileUploadErrorTypeEnum.FILE_FINISH_UPLOAD:
            return `${fileName} konnte nicht hochgeladen werden (Serverfehler)`;
        case FileUploadErrorTypeEnum.FILE_UPLOAD:
            return "Es ist ein Problem mit der Datei aufgetreten. Versuchen Sie es erneut";
        default:
            return uploadStatus;
    }
};

export const FileUpload = ({
    name,
    label,
    disabled,
    readOnly,
    defaultValues,
    validFileMaxSize,
    validFileTypes,
    maxNumberOfFilesPerUpload,
}) => {
    const [hasFocus, setHasFocus] = useState(false);
    const [currentFiles, setCurrentFiles] = useState([]);
    const [uploadErrors, setUploadErrors] = useState([]);

    const {
        control,
        setValue,
        trigger,
        formState: { errors, isSubmitted },
    } = useFormContext();
    const { files } = useContext(FormDataContext);

    const addError = (error, fileName) => {
        setUploadErrors([...uploadErrors, mapErrors(error, fileName)]);
    };

    const handleChange = (e, handleFileData) => {
        let inputFiles;
        let proceed = true;
        const generateSubset = false;

        setUploadErrors([]);

        if (e.dataTransfer) {
            inputFiles = { ...e.dataTransfer.files };
        } else if (e.target) {
            inputFiles = { ...e.target.files };
        }

        handleFileData(e);

        const validMimeTypes = validFileTypes.map((type) => FileUploadHelper.mapFileTypeToMimeType(type));
        const maxNumberOfFiles = maxNumberOfFilesPerUpload;
        const filesPerUpload = maxNumberOfFilesPerUpload;
        const fileKeys = currentFiles.map((file) => file.key);

        const validFiles = Object.values(inputFiles)
            .map((inputFile, index) => {
                const key = `${inputFile.name.replace(".", "_")}_${inputFile.lastModified}`;

                if (index > filesPerUpload - 1) {
                    addError(FileUploadErrorTypeEnum.FILE_MAX_NUMBER, inputFile.name);
                    proceed = generateSubset;
                    return;
                }

                if (index > maxNumberOfFiles - 1) {
                    addError(FileUploadErrorTypeEnum.FILE_MAX_NUMBER, inputFile.name);
                    proceed = generateSubset;
                    return;
                }

                if (ArrayHelper.includes(key, fileKeys)) {
                    addError(FileUploadErrorTypeEnum.FILE_DUPLICATE, inputFile.name);
                    proceed = generateSubset;
                    return;
                }

                if (validFileMaxSize > 0 && inputFile.size > validFileMaxSize * 1024 * 1024) {
                    addError(FileUploadErrorTypeEnum.FILE_MAX_SIZE, inputFile.name);
                    proceed = generateSubset;
                    return;
                }

                if (!ArrayHelper.isNullOrEmpty(validMimeTypes) && !validMimeTypes.includes(inputFile.type)) {
                    addError(FileUploadErrorTypeEnum.FILE_MIME_TYPE, inputFile.name);
                    proceed = generateSubset;
                    return;
                }

                // eslint-disable-next-line consistent-return
                return {
                    key,
                    fieldName: name,
                    fileName: inputFile.name,
                    file: inputFile,
                };
            })
            .filter((item) => !!item);

        if (!ArrayHelper.isNullOrEmpty(validFiles) && proceed) {
            const tmpFiles = [...currentFiles, ...validFiles];
            setCurrentFiles(tmpFiles);
            set(files, name, tmpFiles);
            setValue(`${name}_count`, tmpFiles.length);
            trigger();
        }
    };

    const handleDelete = (key) => {
        const tmpFiles = [];

        currentFiles.forEach((item) => {
            if (item.key !== key) {
                tmpFiles.push(item);
            }
        });

        if (tmpFiles.length < 1) {
            setValue(name, "");
        }

        setCurrentFiles(tmpFiles);
        set(files, name, tmpFiles);
        setValue(`${name}_count`, tmpFiles.length);
        trigger();
    };

    const renderErrors = () => {
        const errorMessages = [];
        let key = 0;

        if (isSubmitted && typeof uploadErrors === "string") {
            errorMessages.push(<span key={key}>{uploadErrors}</span>);
            key += 1;
        }

        if (typeof uploadErrors === "object") {
            Object.values(uploadErrors).forEach((item) => {
                if (typeof item === "string") {
                    errorMessages.push(<span key={key}>{item}</span>);
                    key += 1;
                }

                if (typeof item === "object") {
                    Object.values(item).forEach((entry) => {
                        errorMessages.push(<span key={key}>{entry}</span>);
                        key += 1;
                    });
                }
            });
        }

        return errorMessages;
    };

    const errorMessages = renderErrors();

    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValues[name]}
                render={({ field: { onChange, onFocus, onBlur, value } }) => {
                    const hasLabel = StringHelper.isNotNullOrEmpty(label);
                    const hasError = !disabled && !!errors[`${name}_count`];

                    const handleFocus = (e) => {
                        if (readOnly) return;

                        setHasFocus(true);
                        if (onFocus) onFocus(e);
                    };

                    const handleBlur = (e) => {
                        if (readOnly) return;

                        setHasFocus(false);
                        if (onBlur) onBlur(e);
                    };

                    const renderUploadedFiles = currentFiles.map((item) => {
                        return (
                            <Styles.TileSection key={item.key}>
                                <Styles.UploadedFile>
                                    <Styles.FileIcon>
                                        <File height="25px" width="24px" />
                                    </Styles.FileIcon>

                                    <Styles.Text>{item.fileName}</Styles.Text>

                                    <Styles.Delete type="button" onClick={() => handleDelete(item.key)}>
                                        <Bin height="20px" width="20px" />
                                    </Styles.Delete>
                                </Styles.UploadedFile>
                            </Styles.TileSection>
                        );
                    });

                    const accept = validFileTypes.map((type) => FileUploadHelper.acquireFileExtension(type)).join(", ");

                    return (
                        <Styles.InputRow>
                            <Styles.DesktopLabel>{label}</Styles.DesktopLabel>

                            <div>
                                <Styles.Container
                                    as="label"
                                    htmlFor={name}
                                    hasFocus={hasFocus}
                                    hasError={hasError}
                                    isDisabled={disabled}
                                >
                                    <Styles.Column>
                                        {hasLabel && (
                                            <Styles.MobileLabel hasFocus={hasFocus} hasError={hasError}>
                                                {label}
                                            </Styles.MobileLabel>
                                        )}

                                        <Styles.Row hasLabel={hasLabel}>
                                            <Styles.TextLink>
                                                <span>jetzt hochladen</span>
                                            </Styles.TextLink>

                                            <Styles.Info>
                                                {`(max. ${validFileMaxSize} MB, .${validFileTypes.join("/.")})`}
                                            </Styles.Info>

                                            <Styles.Input
                                                id={name}
                                                name={name}
                                                type="file"
                                                autocomplete="off"
                                                accept={accept}
                                                disabled={disabled}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                onChange={(e) => handleChange(e, onChange)}
                                                value={value}
                                                multiple={maxNumberOfFilesPerUpload > 1}
                                            />
                                        </Styles.Row>
                                    </Styles.Column>
                                </Styles.Container>

                                {!ArrayHelper.isNullOrEmpty(errorMessages) && (
                                    <ErrorMessage>{errorMessages}</ErrorMessage>
                                )}

                                {errors[`${name}_count`] && (
                                    <ErrorMessage>{errors[`${name}_count`].message}</ErrorMessage>
                                )}

                                {!ArrayHelper.isNullOrEmpty(renderUploadedFiles) && (
                                    <Styles.Tile>{renderUploadedFiles}</Styles.Tile>
                                )}
                            </div>
                        </Styles.InputRow>
                    );
                }}
            />

            <Controller
                name={`${name}_count`}
                control={control}
                defaultValue={defaultValues[`${name}_count`]}
                render={({ field }) => <input type="hidden" {...field} />}
            />
        </>
    );
};

FileUpload.defaultProps = {
    label: null,
    disabled: false,
    readOnly: false,
    maxNumberOfFilesPerUpload: null,
};

FileUpload.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    defaultValues: PropTypes.object.isRequired,
    validFileMaxSize: PropTypes.number.isRequired,
    validFileTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(FileTypeEnum))).isRequired,
    maxNumberOfFilesPerUpload: PropTypes.number,
};

export default FileUpload;
