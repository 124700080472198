export class NumberHelper {
    static formatCurrencyAmount = (value) => {
        return new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);
    };

    static formatAmount = (value, digits) => {
        return new Intl.NumberFormat("de-DE", {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits,
        }).format(value);
    };

    static formatTotal = (value) => {
        return new Intl.NumberFormat("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(value / 1000000);
    };
}

export default NumberHelper;
