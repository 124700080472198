import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Container = styled.div`
    ${(props) => (props.withColor ? "padding" : "margin")}: 25px 0 25px;

    ${minMedia.desktop`
        text-align: center;
        ${(props) => (props.withColor ? "padding" : "margin")}: 70px 0 70px;
    `};
`;

const TextContainer = styled.div`
    text-align: ${(props) => props.textAlignMobile ?? "left"};
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    h2 {
        margin-top: 0;
    }

    ${minMedia.desktop`
        margin-bottom: 30px;
        text-align: ${(props) => props.textAlignDesktop ?? "center"};
    `};
`;

const Date = styled.div`
    color: ${Colors.GREY_999999};
    font-size: 14px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 16px 0 0;
    width: 63%;

    ${minMedia.desktop`
        font-size: 16px;
        line-height: 30px;
        margin: 20px 0 0;
        width: 400px;
    `}
`;

const ImageWrapper = styled.div`
    background-color: ${Colors.GREY_FAFAFA};
    display: block;
    margin: 0 auto;
    position: relative;
    max-width: ${(props) => (props.imageWidthMobile ? `${props.imageWidthMobile}px` : "1000px")};

    ${minMedia.desktop`
         max-width: ${(props) => (props.imageWidthDesktop ? `${props.imageWidthDesktop}px` : "1000px")};
    `};

    img {
        object-fit: contain;
        width: 100%;
    }
`;

const Caption = styled.div`
    background-color: ${Colors.GREY_FAFAFA};
    box-sizing: border-box;
    color: ${Colors.GREY_575757};
    display: block;
    font-size: 14px;
    line-height: 18px;
    padding: 7px;
    text-align: left;

    ${minMedia.desktop`
        font-size: 16px;
        line-height: 20px;
    `}
`;

export default {
    Container,
    TextContainer,
    Date,
    ImageWrapper,
    Caption,
};
