import React from "react";

import { EnumHelper } from "helper/enum-helper";

import { Markdown } from "utils/markdown";

import { Image } from "../image/image";
import { SlideShape } from "./slide.shape";
import Styles from "./slide.styles";

export const Slide = ({
    content,
    image,
    aspectRatio,
    imagePadding,
    flag,
    textAlign,
    linkTitle,
    backgroundColor,
    parentIsLink,
    ...rest
}) => {
    return (
        <Styles.Container backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)} {...rest}>
            <Styles.ImageWrapper aspectRatio={aspectRatio} padding={imagePadding}>
                {flag && <Styles.Flag>{flag}</Styles.Flag>}

                {image && image.url && <Image image={image} />}
            </Styles.ImageWrapper>

            <Styles.Content textAlign={textAlign}>
                <Styles.TextContainer>
                    <Markdown skipHtml parentIsLink={parentIsLink}>
                        {content}
                    </Markdown>
                </Styles.TextContainer>

                {linkTitle && <Styles.Link>{linkTitle}</Styles.Link>}
            </Styles.Content>
        </Styles.Container>
    );
};

Slide.defaultProps = {
    aspectRatio: 1.42,
    imagePadding: 0,
    flag: null,
    textAlign: "left",
    linkTitle: null,
    parentIsLink: false,
};

Slide.propTypes = SlideShape;

export default Slide;
