import React, { useState } from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";

import { ErrorMessage } from "components/error-message/error-message";

import { StringHelper } from "helper/string-helper";

import Styles from "./textarea.styles";

export const Textarea = ({
    name,
    label,
    placeholder,
    disabled,
    readOnly,
    control,
    errors,
    defaultValues,
    maxLength,
}) => {
    const [hasFocus, setHasFocus] = useState(false);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValues[name]}
            render={({ field: { onChange, onFocus, onBlur, value } }) => {
                const hasLabel = StringHelper.isNotNullOrEmpty(label);
                const hasError = !disabled && !!errors[name];
                const numberOfCharacters = value.length;

                const handleFocus = (e) => {
                    if (readOnly) return;

                    setHasFocus(true);
                    if (onFocus) onFocus(e);
                };

                const handleBlur = (e) => {
                    if (readOnly) return;

                    setHasFocus(false);
                    if (onBlur) onBlur(e);
                };

                const counterShouldWarn = maxLength && numberOfCharacters > maxLength;
                const charCounter =
                    (maxLength && numberOfCharacters > maxLength - 100 && `${numberOfCharacters} / ${maxLength}`) ||
                    null;
                const isCounterVisible = charCounter !== null;

                return (
                    <Styles.InputRow>
                        <Styles.DesktopLabel>{label}</Styles.DesktopLabel>

                        <div>
                            <Styles.Container
                                as="label"
                                htmlFor={name}
                                hasFocus={hasFocus}
                                hasError={hasError || counterShouldWarn}
                                isDisabled={disabled}
                            >
                                <Styles.Column>
                                    {hasLabel && (
                                        <Styles.MobileLabel hasFocus={hasFocus} hasError={hasError}>
                                            {label}
                                        </Styles.MobileLabel>
                                    )}

                                    <Styles.Row hasLabel={hasLabel} isCounterVisible={isCounterVisible}>
                                        <Styles.Textarea
                                            id={name}
                                            name={name}
                                            autoComplete="off"
                                            spellCheck="false"
                                            disabled={disabled}
                                            onFocus={handleFocus}
                                            placeholder={placeholder}
                                            onBlur={handleBlur}
                                            onChange={onChange}
                                            readOnly={readOnly}
                                            value={value}
                                        />
                                    </Styles.Row>

                                    {isCounterVisible && (
                                        <Styles.Counter isWarning={counterShouldWarn}>{charCounter}</Styles.Counter>
                                    )}
                                </Styles.Column>
                            </Styles.Container>

                            {errors[name] && <ErrorMessage>{errors[name].message}</ErrorMessage>}
                        </div>
                    </Styles.InputRow>
                );
            }}
        />
    );
};

Textarea.defaultProps = {
    label: null,
    placeholder: null,
    disabled: false,
    readOnly: false,
    maxLength: null,
};

Textarea.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    control: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    defaultValues: PropTypes.object.isRequired,
    maxLength: PropTypes.number,
};

export default Textarea;
