import { PropTypes } from "prop-types";

import { ImageShape } from "../image/image.shape";

export const PillarShape = {
    title: PropTypes.string,
    description: PropTypes.string.isRequired,
    image: PropTypes.shape(ImageShape).isRequired,
    titleColor: PropTypes.string,
    linkUrl: PropTypes.string,
    linkTitle: PropTypes.string,
    backgroundColor: PropTypes.string.isRequired,
    sameColor: PropTypes.bool,
    parentIsLink: PropTypes.bool,
};

export default PillarShape;
