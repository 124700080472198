// eslint-disable-next-line no-unused-vars
import React from "react";
import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Overlay = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    padding: 40px 15px 15px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${(props) => props.theme.zIndex.overlay};

    ${minMedia.desktop`
        padding: 50px 15px;
    `}
`;

const Container = styled.div`
    font-family: ${(props) => props.theme.fontFamily};
    background-color: ${Colors.WHITE_FFFFFF};
    border-radius: 10px;
    box-sizing: border-box;
    display: inline-block;
    max-width: 950px;
    max-height: 100%;
    margin: 0 auto;
    position: relative;
    width: 100%;

    ${minMedia.desktop`
        border-radius: 3px;
    `}
`;

const Close = styled.div`
    color: ${Colors.WHITE_FFFFFF};
    cursor: pointer;
    height: 20px;
    position: absolute;
    right: 10px;
    top: -30px;
    width: 20px;
    z-index: ${(props) => props.theme.zIndex.modalClose};

    &:after {
        left: -10px;
        height: 40px;
        position: absolute;
        top: -10px;
        width: 40px;
        content: "";
    }

    ${minMedia.desktop`
        right: 0;
    `}
`;

const Content = styled.div`
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
`;

export default {
    Overlay,
    Container,
    Close,
    Content,
};
