import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Container = styled.div`
    ${(props) => (props.withColor ? "padding" : "margin")}: 25px 0 25px;

    ${minMedia.desktop`
        text-align: center;
        ${(props) => (props.withColor ? "padding" : "margin")}: 70px 0 70px;
   `};
`;

const TextContainer = styled.div`
    text-align: ${(props) => props.textAlignMobile ?? "left"};
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    ${minMedia.desktop`
        margin-bottom: 30px;
        text-align: ${(props) => props.textAlignDesktop ?? "center"};
    `};
`;

const IconWrapper = styled.div`
    color: ${Colors.BLUE_063773};
    display: flex;
    flex-direction: row;
    height: 90px;
    justify-content: center;
    margin: 0 auto 20px;
    width: 90px;

    svg {
        height: inherit;
        width: inherit;
    }

    ${minMedia.desktop`
        height: 130px;
        width: 130px;
    `};
`;

const CounterContainer = styled.div`
    margin: 0 0 14px;

    ${minMedia.desktop`
        margin: 0;
    `};
`;

const Counter = styled.div`
    color: ${Colors.GREEN_14CCB7};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    font-size: 40px;
    text-align: center;

    ${minMedia.desktop`
        font-size: 48px;
    `};
`;

const Caption = styled.div`
    color: ${Colors.GREY_575757};
    font-size: 16px;
    text-align: center;

    ${minMedia.desktop`
        font-size: 20px;
    `};
`;

const BtnWrapper = styled.div`
    margin: 20px 0 0;

    ${minMedia.desktop`
        margin: 30px auto 0;
        width: 350px;
    `};
`;

export default {
    Container,
    TextContainer,
    IconWrapper,
    CounterContainer,
    Counter,
    Caption,
    BtnWrapper,
};
