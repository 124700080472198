import { PropTypes } from "prop-types";

import { ImageShape } from "../image/image.shape";

export const HorizontalTileShape = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    flag: PropTypes.string,
    backgroundColor: PropTypes.string,
    image: PropTypes.shape(ImageShape).isRequired,
    height: PropTypes.number,
};

export default HorizontalTileShape;
