import React from "react";
import PropTypes from "prop-types";

import { Colors } from "container/global.colors";

export const Donate = ({ height, width, ...rest }) => {
    return (
        <svg height={height} width={width} viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <circle cx="60" cy="60" r="60" fill="currentColor" />
            <path
                d="M74.32 37.758a7.232 7.232 0 00-2.479-5.163c-2.607-2.259-6.633-1.923-9.176.749l-2.659 2.774-2.659-2.774a7.132 7.132 0 00-5.11-2.26 6.091 6.091 0 00-4.066 1.498 7.234 7.234 0 00-2.478 5.176 7.358 7.358 0 001.988 5.433l12.3 12.894c.012.013.025.026.038.026.013 0 .026-.013.052-.026l12.3-12.894a7.412 7.412 0 001.949-5.433zM91.963 42.184c-.93 0-1.678.749-1.678 1.678v17.463L79.702 74.038a3.747 3.747 0 01-5.28.477 3.753 3.753 0 01-.244-.22l-1.446-1.445a3.766 3.766 0 01-.348-4.891l4.297-5.73c.555-.75.4-1.795-.335-2.35a1.683 1.683 0 00-2.35.336l-6.504 8.66a12.512 12.512 0 00-2.504 7.512v12.519h14.636a8.825 8.825 0 011.471-3.007L92.88 70.708c.49-.62.762-1.381.762-2.181V43.862c0-.929-.749-1.678-1.678-1.678zM46.016 60.202a1.683 1.683 0 00-2.698 2.013l4.298 5.744a3.766 3.766 0 01-.348 4.891l-1.446 1.446c-1.458 1.458-3.82 1.471-5.279.013-.09-.09-.168-.181-.258-.284l-10.583-12.7V43.862c0-.929-.749-1.678-1.678-1.678-.93 0-1.678.749-1.678 1.678v24.665c0 .787.271 1.561.762 2.18l.012.027 11.758 15.165a8.741 8.741 0 011.485 3.007h14.636v-12.52c0-2.71-.878-5.343-2.504-7.51l-6.48-8.674z"
                fill="none"
            />
            <path
                d="M75.158 45.876a11.25 11.25 0 003.033-8.312 11.04 11.04 0 00-3.82-7.899c-4.117-3.562-10.39-3.188-14.378.852-3.975-4.027-10.247-4.414-14.365-.852-2.31 2-3.69 4.866-3.807 7.925a11.302 11.302 0 003.046 8.299l12.287 12.893a3.858 3.858 0 002.826 1.226h.013a3.886 3.886 0 002.84-1.226l12.325-12.906zm-15.152 10.26c-.013 0-.026-.013-.038-.025l-12.3-12.894a7.43 7.43 0 01-1.988-5.434c.077-2 .98-3.872 2.478-5.175a6.14 6.14 0 014.066-1.497 7.059 7.059 0 015.11 2.258l2.672 2.75 2.646-2.788c2.543-2.672 6.57-2.995 9.177-.749a7.183 7.183 0 012.478 5.163 7.43 7.43 0 01-1.975 5.446l-12.3 12.894c0 .038-.013.051-.026.051z"
                fill={Colors.GREEN_14CCB7}
                fillRule="nonzero"
            />
            <path
                d="M91.963 38.313a5.558 5.558 0 00-5.55 5.55v16.055L76.81 71.443l-1.264-1.264 4.233-5.64a5.555 5.555 0 00-8.841-6.725c-.013.013-.026.039-.039.052l-6.505 8.686a16.38 16.38 0 00-3.278 9.835v12.648a3.746 3.746 0 003.743 3.743h14.868a3.719 3.719 0 003.601-2.762 4.821 4.821 0 01.826-1.742L95.9 73.12a7.382 7.382 0 001.6-4.581V43.862a5.53 5.53 0 00-5.536-5.55zm1.678 30.214a3.53 3.53 0 01-.762 2.18L81.095 85.9a8.384 8.384 0 00-1.47 3.007H64.987v-12.52c0-2.71.878-5.343 2.504-7.51l6.505-8.674a1.683 1.683 0 013.007.774c.065.44-.052.89-.323 1.24l-4.297 5.73a3.766 3.766 0 00.348 4.891l1.446 1.446a3.75 3.75 0 005.304-.013c.078-.077.155-.155.22-.245l10.583-12.713v-17.45c0-.929.748-1.678 1.678-1.678.929 0 1.678.749 1.678 1.678v24.665zM49.113 57.879c-1.858-2.44-5.343-2.904-7.782-1.059a5.535 5.535 0 00-1.098 7.706l4.234 5.64-1.265 1.265L33.6 59.905V43.862a5.558 5.558 0 00-5.55-5.55 5.558 5.558 0 00-5.55 5.55v24.665c0 1.665.568 3.278 1.6 4.581l11.745 15.153c.4.529.684 1.123.84 1.755a3.73 3.73 0 003.6 2.762h14.868a3.746 3.746 0 003.743-3.743V76.387a16.38 16.38 0 00-3.278-9.835l-6.505-8.673zm5.911 31.027H40.388a8.63 8.63 0 00-1.484-3.007L27.146 70.734l-.013-.026a3.502 3.502 0 01-.761-2.181V43.862c0-.929.748-1.678 1.678-1.678.929 0 1.678.749 1.678 1.678v17.463l10.583 12.7a3.742 3.742 0 005.537.258l1.445-1.446a3.766 3.766 0 00.349-4.891l-4.298-5.744a1.63 1.63 0 01-.31-1.239c.13-.916.981-1.561 1.897-1.432.44.064.84.297 1.11.658l6.505 8.673a12.512 12.512 0 012.504 7.512l-.026 12.532z"
                fill={Colors.WHITE_FFFFFF}
                fillRule="nonzero"
            />
        </svg>
    );
};

Donate.defaultProps = {
    height: "120px",
    width: "120px",
};

Donate.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
};

export default Donate;
