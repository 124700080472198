import styled from "styled-components";

import { minMedia } from "container/global.styles";

const Frame = styled.div`
    margin: 20px 0;

    ${minMedia.desktop`
        background-color: ${(props) => props.backgroundColor};
        margin: 5px 0;
    `};
`;

const Container = styled.div`
    text-align: left;
    ${(props) => (props.withColor ? "padding" : "margin")}: 25px 0 25px;

    ${minMedia.desktop`
        text-align: center;
        ${(props) => (props.withColor ? "padding" : "margin")}: 70px 0 70px;
    `};
`;

const TextContainer = styled.div`
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    ${minMedia.desktop`
        margin-bottom: 30px;
    `};
`;

const List = styled.div`
    margin: 0 auto;
    max-width: 998px;
`;

const EntryContainer = styled.div`
    margin-top: 30px;
`;

const ImageWrapper = styled.div`
    border-radius: 50%;
    height: 70px;
    width: 70px;
    background-color: red;
    margin-right: 30px;
    content: "";

    ${minMedia.desktop`
         height: 100px;
         width: 100px;
    `};
`;

const TextWrapper = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
        margin-top: 10px;
    }
`;

export default {
    Frame,
    Container,
    TextContainer,
    List,
    EntryContainer,
    ImageWrapper,
    TextWrapper,
};
