import { createContext } from "react";

export const MapContext = createContext({
    mapRef: null,
    zoom: null,
    center: null,
    setCenter: () => {},
    activeLocation: null,
    setActiveLocation: () => {},
});

export default MapContext;
