import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Placeholder = styled.div`
    background-color: ${Colors.BLUE_063773};
    height: 50px;
    min-height: 50px;
    width: 100%;

    ${minMedia.desktop`
        height: 100px;
        min-height: 100px;
    `}
`;

const Container = styled.div`
    background-color: ${Colors.BLUE_063773};
    height: 50px;
    position: fixed;
    width: 100%;
    z-index: ${(props) => props.theme.zIndex.menu};

    ${minMedia.desktop`
        height: 100px;
    `};
`;

const Bar = styled.div`
    ${minMedia.desktop`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    `};
`;

const Row = styled.div`
    align-items: center;
    background-color: ${Colors.BLUE_063773};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    position: relative;
    width: 100%;
    z-index: ${(props) => props.theme.zIndex.menu + 1};

    ${minMedia.desktop`
        flex: 0 0 0;
        height: 100px;
        position: initial;
    `};
`;

const LogoWrapper = styled.a`
    color: ${Colors.WHITE_FFFFFF};
    cursor: pointer;
    display: block;
    height: 30px;
    text-decoration: none;
    width: 133px;

    svg {
        height: inherit;
        width: inherit;
    }

    &:hover {
        opacity: 0.8;
    }

    ${minMedia.desktop`
        height: 45px;
        width: 200px;
    `};
`;

const NavIconWrapper = styled.button`
    background-color: transparent;
    border: none;
    display: block;
    color: ${Colors.WHITE_FFFFFF};
    cursor: pointer;
    height: 21px;
    padding: 0;
    outline: none;
    text-decoration: none;
    width: 26px;

    ${minMedia.desktop`
        display: none;
    `};
`;

const MenuContainer = styled.div`
    background-color: ${Colors.BLUE_063773};
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    list-style-type: none;
    padding: 10px 0;
    position: absolute;
    right: 0;
    transition: all 0.4s;
    z-index: ${(props) => props.theme.zIndex.menu};

    ${minMedia.desktop`
        align-items: center;
        flex-direction: row;
        position: initial;
    `}
`;

const MenuItem = styled.li`
    margin: 10px 30px 10px 30px;
    padding: 0;

    &:hover {
        opacity: 0.8;
    }

    a {
        cursor: pointer;
        display: block;
        text-decoration: none;

        p {
            color: ${Colors.WHITE_FFFFFF};
            font-size: 20px;
            font-weight: ${(props) => (props.active ? props.theme.fontWeight.bold : props.theme.fontWeight.regular)};
            margin: 0;
            padding: 0;
        }
    }

    ${minMedia.desktop`
        margin: 10px 0 10px 30px;
    `}
`;

const Overlay = styled.p`
    background-color: white;
    bottom: 0;
    left: 0;
    margin: 0;
    opacity: 0.7;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${(props) => props.theme.zIndex.menu - 1};
`;

export default {
    Placeholder,
    Container,
    Bar,
    Row,
    LogoWrapper,
    NavIconWrapper,
    MenuContainer,
    MenuItem,
    Overlay,
};
