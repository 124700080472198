import React from "react";
import PropTypes from "prop-types";

import { ContentRow } from "components/content-row/content-row";

import { StatusEnum } from "enumeration/status.enum";

import { EnumHelper } from "helper/enum-helper";

import { LeftQuotationMark } from "icons/left-quotation-mark";
import { RightQuotationMark } from "icons/right-quotation-mark";

import Styles from "./quote.styles";

export const Quote = ({ status, quote, caption, backgroundColor }) => {
    if (status === StatusEnum.DRAFT) return null;

    return (
        <ContentRow backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
            <Styles.Column>
                <Styles.Quote>
                    <Styles.LeftQuotationMarkWrapper>
                        <LeftQuotationMark height="100px" width="111px" />
                    </Styles.LeftQuotationMarkWrapper>

                    <Styles.Text>
                        &nbsp;
                        {quote}
                        &nbsp;
                    </Styles.Text>

                    <Styles.RightQuotationMarkWrapper>
                        <RightQuotationMark height="100px" width="111px" />
                    </Styles.RightQuotationMarkWrapper>
                </Styles.Quote>

                <Styles.Caption>{caption}</Styles.Caption>
            </Styles.Column>
        </ContentRow>
    );
};

Quote.defaultProps = {
    status: null,
};

Quote.propTypes = {
    status: PropTypes.string,
    quote: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
};

export default Quote;
