import React from "react";
import PropTypes from "prop-types";

export const Refresh = ({ height, width, ...rest }) => {
    return (
        <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fill="currentColor"
                d="M6.35 6.35A7.963 7.963 0 0 1 12 4c4.42 0 7.99 3.58 7.99 8s-3.57 8-7.99 8c-3.73 0-6.84-2.55-7.73-6h2.08A6.002 6.002 0 0 0 12 18c3.31 0 6-2.69 6-6s-2.69-6-6-6c-1.66 0-3.14.69-4.22 1.78L11 11H4V4l2.35 2.35Z"
            />
        </svg>
    );
};

Refresh.defaultProps = {
    height: "20px",
    width: "20px",
};

Refresh.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
};

export default Refresh;
