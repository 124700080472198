import React from "react";
import PropTypes from "prop-types";

import { SelectorEnum } from "enumeration/selector.enum";

import Styles from "./error-message.styles";

export const ErrorMessage = ({ children }) => {
    return (
        <Styles.Container className={SelectorEnum.ERROR}>
            <Styles.Text>{children}</Styles.Text>
        </Styles.Container>
    );
};

ErrorMessage.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default ErrorMessage;
