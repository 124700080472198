import React, { useContext, useRef, useState } from "react";
import ReactDOM from "react-dom";
import NextLink from "next/link";
import PropTypes from "prop-types";

import { ContentRow } from "components/content-row/content-row";

import { Colors } from "container/global.colors";

import { DeviceOutputContext } from "context/device-output.context";

import { Check24Hilft } from "icons/check24-hilft";
import { NavigationBurger } from "icons/navigation-burger";

import { Link } from "utils/link";
import { Markdown } from "utils/markdown";

import Styles from "./nav.styles";

export const Nav = ({ global: { donateLinkUrl, donateLinkTitle }, categories, category: currentCategory }) => {
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef(null);
    const { isApp } = useContext(DeviceOutputContext);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    categories.sort((a, b) => {
        if (a.order > b.order) {
            return -1;
        }
        if (b.order > a.order) {
            return 1;
        }
        return 0;
    });

    if (isApp !== false) return null;

    return (
        <>
            <Styles.Placeholder>
                <Styles.Container>
                    <ContentRow backgroundColor={Colors.BLUE_063773}>
                        <Styles.Bar>
                            <Styles.Row>
                                <NextLink href="/">
                                    <Styles.LogoWrapper title="Check24Hilft">
                                        <Check24Hilft height="45px" width="200px" />
                                    </Styles.LogoWrapper>
                                </NextLink>

                                <Styles.NavIconWrapper type="button" onClick={toggleMenu}>
                                    <NavigationBurger height={21} width={26} isActive={showMenu} />
                                </Styles.NavIconWrapper>
                            </Styles.Row>

                            <Styles.MenuContainer
                                ref={menuRef}
                                style={{
                                    bottom: `-${showMenu && menuRef.current ? menuRef.current.scrollHeight : 0}px`,
                                }}
                            >
                                {categories.map((category) => {
                                    if (!category.inNavigation) return;

                                    // eslint-disable-next-line consistent-return
                                    return (
                                        <Styles.MenuItem
                                            key={category.id}
                                            active={currentCategory && category.id === currentCategory.id}
                                        >
                                            <NextLink as={`/category/${category.slug}`} href="/category/[slug]">
                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                                <a onClick={() => setShowMenu(false)}>
                                                    <Markdown>{category.displayName}</Markdown>
                                                </a>
                                            </NextLink>
                                        </Styles.MenuItem>
                                    );
                                })}

                                {donateLinkUrl && donateLinkTitle && (
                                    <Styles.MenuItem>
                                        <Link href={donateLinkUrl} target="_blank" onClick={() => setShowMenu(false)}>
                                            <p>{donateLinkTitle}</p>
                                        </Link>
                                    </Styles.MenuItem>
                                )}
                            </Styles.MenuContainer>
                        </Styles.Bar>
                    </ContentRow>
                </Styles.Container>
            </Styles.Placeholder>

            {showMenu && ReactDOM.createPortal(<Styles.Overlay onClick={toggleMenu} />, document.querySelector("body"))}
        </>
    );
};

Nav.defaultProps = {
    category: null,
};

Nav.propTypes = {
    global: PropTypes.shape({
        donateLinkUrl: PropTypes.string,
        donateLinkTitle: PropTypes.string,
    }).isRequired,
    categories: PropTypes.any.isRequired,
    category: PropTypes.any,
};

export const PopupNav = () => {
    return (
        <>
            <Styles.Placeholder>
                <Styles.Container>
                    <ContentRow backgroundColor={Colors.BLUE_063773}>
                        <Styles.Bar>
                            <Styles.Row>
                                <Link href="/" title="zur Startseite" target="_blank">
                                    <Styles.LogoWrapper title="Check24Hilft">
                                        <Check24Hilft height="45px" width="200px" />
                                    </Styles.LogoWrapper>
                                </Link>
                            </Styles.Row>
                        </Styles.Bar>
                    </ContentRow>
                </Styles.Container>
            </Styles.Placeholder>
        </>
    );
};

export default Nav;
