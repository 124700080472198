import React, { Component } from "react";
import PropTypes from "prop-types";

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
        };
    }

    componentDidCatch(error, errorInfo) {
        // eslint-disable-next-line no-console
        console.error(error, errorInfo);
        this.setState({
            error: true,
        });
    }

    render() {
        const { fallbackComponent, children } = this.props;
        const { error } = this.state;

        return (error && fallbackComponent && <>{fallbackComponent}</>) || <>{children}</>;
    }
}

ErrorBoundary.defaultProps = {
    fallbackComponent: null,
};

ErrorBoundary.propTypes = {
    fallbackComponent: PropTypes.node,
    children: PropTypes.any.isRequired,
};

export default ErrorBoundary;
