import React from "react";
import PropTypes from "prop-types";

export const Chevron = ({ height, width, ...rest }) => {
    return (
        <svg height={height} width={width} viewBox="0 0 25 15" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path fill="currentColor" d="M12.5 10.784L22.95 0 25 2.12 12.5 15 0 2.12 2.05 0 12.5 10.784z" />
        </svg>
    );
};

Chevron.defaultProps = {
    width: "25px",
    height: "15px",
};

Chevron.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
};

export default Chevron;
