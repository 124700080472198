import { Colors } from "container/global.colors";

export class EnumHelper {
    static mapBackgroundColor = (value) => {
        switch (value) {
            case "white":
                return Colors.WHITE_FFFFFF;
            case "offWhite":
                return Colors.GREY_EFEFEF;
            case "check24":
                return Colors.BLUE_063773;
            case "blue":
                return Colors.BLUE_DFEAF7;
            case "darkerGreen":
                return Colors.GREEN_92C0B7;
            case "green":
                return Colors.GREEN_E2F1EE;
            case "yellow":
                return Colors.YELLOW_FFECB9;
            case "red":
                return Colors.RED_FADBD2;
            default:
                return `#${value}`;
        }
    };

    static mapTextColor = (value) => {
        switch (value) {
            case "white":
                return Colors.WHITE_FFFFFF;
            case "grey":
                return Colors.GREY_575757;
            case "blue":
                return Colors.BLUE_005EA8;
            case "green":
                return Colors.GREEN_14CCB7;
            default:
                return `#${value}`;
        }
    };
}

export default EnumHelper;
