import React, { useContext } from "react";

import { MapContext } from "context/map.context";

import { NumberHelper } from "helper/number-helper";

import { Close } from "icons/close";
import { Trees } from "icons/trees";

import Styles from "./info-card.styles";

export const InfoCard = () => {
    const { mapRef, center, activeLocation, setActiveLocation } = useContext(MapContext);

    const handleClose = () => {
        setActiveLocation(null);
        mapRef.current.setCenter({ lat: center.lat, lng: center.lng });
    };

    return (
        activeLocation && (
            <Styles.Container>
                <Styles.Close onClick={handleClose}>
                    <Close height="20px" width="20px" />
                </Styles.Close>

                <Styles.ProjectName>{activeLocation.name}</Styles.ProjectName>

                <Styles.List>
                    {activeLocation.area && (
                        <Styles.ListItem>
                            <Styles.Label>Gebiet:</Styles.Label>

                            <Styles.Value>{NumberHelper.formatAmount(activeLocation.area, 2)} ha</Styles.Value>
                        </Styles.ListItem>
                    )}

                    <Styles.ListItem>
                        <Styles.Label>Anzahl der Bäume:</Styles.Label>

                        <Styles.Value>{NumberHelper.formatAmount(activeLocation.target, 0)}</Styles.Value>
                    </Styles.ListItem>

                    {activeLocation.timeperiod && (
                        <Styles.ListItem>
                            <Styles.Label>Pflanzung:</Styles.Label>

                            <Styles.Value>{activeLocation.timeperiod}</Styles.Value>
                        </Styles.ListItem>
                    )}

                    <Styles.ListItem>
                        <Styles.Label>Baumarten:</Styles.Label>

                        <Styles.Value>{activeLocation.species}</Styles.Value>
                    </Styles.ListItem>
                </Styles.List>

                <Styles.DescriptionContainer>
                    <Styles.DescriptionLabel>Projektbeschreibung:</Styles.DescriptionLabel>

                    <Styles.DescriptionValue>{activeLocation.description}</Styles.DescriptionValue>
                </Styles.DescriptionContainer>

                {activeLocation.planted && (
                    <Styles.Row>
                        <Styles.IconWrapper>
                            <Trees height="121px" width="153px" />
                        </Styles.IconWrapper>

                        <Styles.Column>
                            <Styles.CounterLabel>Bereits gepflanzte Bäume:</Styles.CounterLabel>

                            <Styles.CounterValue>
                                {NumberHelper.formatAmount(activeLocation.planted, 0)}
                            </Styles.CounterValue>
                        </Styles.Column>
                    </Styles.Row>
                )}
            </Styles.Container>
        )
    );
};

InfoCard.propTypes = {};

export default InfoCard;
