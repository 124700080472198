import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Button } from "components/button/button";
import { ContentRow } from "components/content-row/content-row";
import { Pillar } from "components/pillar/pillar";
import { PillarShape } from "components/pillar/pillar.shape";

import { DeviceOutputContext } from "context/device-output.context";
import { MediaContext } from "context/media.context";

import { StatusEnum } from "enumeration/status.enum";

import { EnumHelper } from "helper/enum-helper";
import { LinkHelper } from "helper/link-helper";

import { Markdown } from "utils/markdown";

import Styles from "./three-pillars.styles";

export const ThreePillars = ({
    status,
    text,
    textAlignMobile,
    textAlignDesktop,
    pillars,
    backgroundColor,
    linkUrl,
    linkTitle,
    externalLink,
    primaryButton,
    withHeart,
}) => {
    const { isApp } = useContext(DeviceOutputContext);
    const media = useContext(MediaContext);

    if (status === StatusEnum.DRAFT) return null;

    const pillarList = pillars.map((pillar, i) => {
        return LinkHelper.renderComponentLink(Pillar, { key: i, isApp, media, ...pillar });
    });

    return (
        <ContentRow backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
            <Styles.Container withColor={backgroundColor !== "white"}>
                {text && (
                    <Styles.TextContainer textAlignMobile={textAlignMobile} textAlignDesktop={textAlignDesktop}>
                        <Markdown>{text}</Markdown>
                    </Styles.TextContainer>
                )}

                <Styles.PillarList>{pillarList}</Styles.PillarList>

                {linkUrl && linkTitle && (
                    <Styles.BtnWrapper>
                        <Button
                            linkTitle={linkTitle}
                            linkUrl={linkUrl}
                            externalLink={externalLink}
                            primary={primaryButton}
                            withHeart={withHeart}
                        />
                    </Styles.BtnWrapper>
                )}
            </Styles.Container>
        </ContentRow>
    );
};

ThreePillars.defaultProps = {
    status: null,
    text: null,
    textAlignMobile: "left",
    textAlignDesktop: "center",
    linkTitle: null,
    linkUrl: null,
    externalLink: false,
    primaryButton: false,
    withHeart: false,
};

ThreePillars.propTypes = {
    status: PropTypes.string,
    text: PropTypes.string,
    textAlignMobile: PropTypes.string,
    textAlignDesktop: PropTypes.string,
    pillars: PropTypes.arrayOf(PropTypes.shape(PillarShape)).isRequired,
    linkTitle: PropTypes.string,
    linkUrl: PropTypes.string,
    externalLink: PropTypes.bool,
    primaryButton: PropTypes.bool,
    withHeart: PropTypes.bool,
    backgroundColor: PropTypes.string.isRequired,
};

export default ThreePillars;
