import styled from "styled-components";

import { Colors } from "container/global.colors";

const ArrowWrapper = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${Colors.BLUE_0A5A7A};
    cursor: pointer;
    display: flex;
    outline: 0;
    padding: 0;

    :hover:enabled {
        color: ${Colors.BLUE_0A5A7A};
    }

    :disabled {
        color: ${Colors.GREY_B4B4B4};
        cursor: default;
    }
`;

const LeftArrowWrapper = styled(ArrowWrapper)`
    padding-right: 33px;
`;

const RightArrowWrapper = styled(ArrowWrapper)`
    padding-left: 33px;
`;

const CustomArrowWrapper = styled.div``;
const LeftCustomArrowWrapper = styled(CustomArrowWrapper)``;
const RightCustomArrowWrapper = styled(CustomArrowWrapper)``;

export default {
    ArrowWrapper,
    LeftArrowWrapper,
    RightArrowWrapper,
    CustomArrowWrapper,
    LeftCustomArrowWrapper,
    RightCustomArrowWrapper,
};
