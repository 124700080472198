import React from "react";

import { Image } from "components/image/image";

import { EnumHelper } from "helper/enum-helper";

import { Markdown } from "utils/markdown";

import { TileShape } from "./tile.shape";
import Styles from "./tile.styles";

export const Tile = ({ title, date, description, flag, image, link, backgroundColor }) => {
    return (
        <Styles.Container backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
            <Styles.ImageWrapper>
                {flag && <Styles.Flag>{flag}</Styles.Flag>}

                {image && image.url && <Image image={image} />}
            </Styles.ImageWrapper>

            <Styles.Content>
                <Styles.TextContainer>
                    <Styles.SubTitle>
                        <Markdown>{title}</Markdown>
                    </Styles.SubTitle>

                    {date && <Styles.Date>{date}</Styles.Date>}

                    <Styles.Paragraph>
                        <Markdown>{description}</Markdown>
                    </Styles.Paragraph>
                </Styles.TextContainer>

                {link && <Styles.Link>{link}</Styles.Link>}
            </Styles.Content>
        </Styles.Container>
    );
};

Tile.defaultProps = {
    date: null,
    flag: null,
    link: null,
};

Tile.propTypes = TileShape;

export default Tile;
