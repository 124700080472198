export class StringHelper {
    static isNullOrEmpty(value) {
        return value === null || typeof value !== "string" || !value.length || value.trim() === "";
    }

    static isNotNullOrEmpty(value) {
        return typeof value === "string" && value.trim() !== "";
    }
}

export default StringHelper;
