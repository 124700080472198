import React from "react";
import PropTypes from "prop-types";

import { Colors } from "container/global.colors";

import Styles from "./content-row.styles";

export const ContentRow = ({ backgroundColor, children, ...rest }) => {
    return (
        <Styles.Frame backgroundColor={backgroundColor} {...rest}>
            <Styles.Container>{children}</Styles.Container>
        </Styles.Frame>
    );
};

ContentRow.defaultProps = {
    backgroundColor: Colors.WHITE_FFFFFF,
};

ContentRow.propTypes = {
    backgroundColor: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default ContentRow;
