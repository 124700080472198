import React, { forwardRef, useContext } from "react";
import PropTypes from "prop-types";

import { ContentRow } from "components/content-row/content-row";
import { Image } from "components/image/image";
import { ImageShape } from "components/image/image.shape";

import { DeviceOutputContext } from "context/device-output.context";
import { MediaContext } from "context/media.context";

import { StatusEnum } from "enumeration/status.enum";

import { EnumHelper } from "helper/enum-helper";
import { LinkHelper } from "helper/link-helper";

import { Markdown } from "utils/markdown";

import Styles from "./field.styles";

const FieldComponent = forwardRef(
    (
        {
            image,
            flag,
            text,
            rtl,
            minHeightDesktop,
            textWidthDesktop,
            backgroundColor,
            imageBackgroundColor,
            imageFit,
            imagePosition,
            linkTitle,
            parentIsLink,
            ...props
        },
        ref
    ) => {
        const media = useContext(MediaContext);

        return (
            <Styles.Div ref={ref} {...props}>
                <Styles.Frame backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
                    <ContentRow backgroundColor="transparent">
                        <Styles.Container rtl={rtl} minHeightDesktop={minHeightDesktop} width={textWidthDesktop}>
                            {(rtl && media.isDesktop && (
                                <>
                                    <Styles.Content backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
                                        <Styles.TextContainer>
                                            <Markdown parentIsLink={parentIsLink}>{text}</Markdown>
                                        </Styles.TextContainer>

                                        {!!linkTitle && <Styles.Link>{linkTitle}</Styles.Link>}
                                    </Styles.Content>

                                    <Styles.ImageWrapper
                                        minHeightDesktop={minHeightDesktop}
                                        width={100 - textWidthDesktop}
                                        rtl
                                        backgroundColor={EnumHelper.mapBackgroundColor(
                                            imageBackgroundColor || backgroundColor
                                        )}
                                        objectFit={imageFit}
                                        objectPosition={imagePosition}
                                    >
                                        {image && image.url && <Image image={image} />}
                                    </Styles.ImageWrapper>
                                </>
                            )) || (
                                <>
                                    <Styles.ImageWrapper
                                        minHeightDesktop={minHeightDesktop}
                                        width={100 - textWidthDesktop}
                                        backgroundColor={EnumHelper.mapBackgroundColor(
                                            imageBackgroundColor || backgroundColor
                                        )}
                                        objectFit={imageFit}
                                        objectPosition={imagePosition}
                                    >
                                        {image && image.url && <Image image={image} />}

                                        {flag && (
                                            <Styles.FlagWrapper>
                                                <Styles.Flag>
                                                    <Markdown parentIsLink={parentIsLink}>{flag}</Markdown>
                                                </Styles.Flag>
                                            </Styles.FlagWrapper>
                                        )}
                                    </Styles.ImageWrapper>

                                    <Styles.Content backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
                                        <Styles.TextContainer>
                                            <Markdown parentIsLink={parentIsLink}>{text}</Markdown>
                                        </Styles.TextContainer>

                                        {!!linkTitle && <Styles.Link>{linkTitle}</Styles.Link>}
                                    </Styles.Content>
                                </>
                            )}
                        </Styles.Container>
                    </ContentRow>
                </Styles.Frame>
            </Styles.Div>
        );
    }
);

FieldComponent.displayName = "FieldComponent";

FieldComponent.defaultProps = {
    flag: null,
    rtl: false,
    imageBackgroundColor: "white",
    linkTitle: null,
    parentIsLink: false,
};

FieldComponent.propTypes = {
    image: PropTypes.shape(ImageShape).isRequired,
    flag: PropTypes.string,
    text: PropTypes.string.isRequired,
    textWidthDesktop: PropTypes.number.isRequired,
    rtl: PropTypes.bool,
    minHeightDesktop: PropTypes.number.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    imageBackgroundColor: PropTypes.string,
    imageFit: PropTypes.string.isRequired,
    imagePosition: PropTypes.number.isRequired,
    linkTitle: PropTypes.string,
    parentIsLink: PropTypes.bool,
};

export const Field = ({
    status,
    image,
    flag,
    text,
    link,
    rtl,
    minHeightDesktop,
    textWidthDesktop,
    backgroundColor,
    imageBackgroundColor,
    imageFit,
    imagePosition,
}) => {
    const { isApp } = useContext(DeviceOutputContext);
    const media = useContext(MediaContext);

    if (status === StatusEnum.DRAFT) return null;

    return LinkHelper.renderComponentLink(FieldComponent, {
        isApp,
        media,
        image,
        flag,
        text,
        rtl,
        minHeightDesktop,
        textWidthDesktop,
        backgroundColor,
        imageBackgroundColor,
        imageFit,
        imagePosition,
        linkUrl: link?.url,
        externalLink: link?.external,
        linkTitle: link?.title,
    });
};

Field.defaultProps = {
    status: null,
    flag: null,
    textWidthDesktop: 35,
    link: null,
    rtl: false,
    imageBackgroundColor: "white",
    imageFit: "cover",
    imagePosition: "center center",
};

Field.propTypes = {
    status: PropTypes.string,
    image: PropTypes.shape(ImageShape).isRequired,
    flag: PropTypes.string,
    text: PropTypes.string.isRequired,
    textWidthDesktop: PropTypes.number,
    link: PropTypes.any,
    rtl: PropTypes.bool,
    minHeightDesktop: PropTypes.number.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    imageBackgroundColor: PropTypes.string,
    imageFit: PropTypes.string,
    imagePosition: PropTypes.number,
};

export default Field;
