import React from "react";
import PropTypes from "prop-types";

import { Colors } from "container/global.colors";

export const Facebook = ({ height, width, ...rest }) => {
    return (
        <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...rest}>
            <path
                fill="currentColor"
                d="M50 0C22.4 0 0 22.4 0 50s22.4 50 50 50 50-22.4 50-50S77.6 0 50 0zm12.9 39.9l-.7 9h-9.3v30h-11v-30h-6v-9h6v-5.7c0-2.6 0-6.6 1.9-9.1 2-2.6 4.7-4.4 9.4-4.4 7.7 0 10.9 1.1 10.9 1.1l-1.5 9s-2.7-.7-5-.7c-2.4 0-4.6.9-4.6 3.2v6.6h9.9z"
            />
        </svg>
    );
};

Facebook.defaultProps = {
    color: Colors.WHITE_FFFFFF,
    height: "20px",
    width: "20px",
};

Facebook.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default Facebook;
