import React, { useContext } from "react";
import { PieChart } from "react-minimal-pie-chart";
import PropTypes from "prop-types";

import { ContentRow } from "components/content-row/content-row";

import { Colors } from "container/global.colors";

import { GlobalContext } from "context/global.context";

import { StatusEnum } from "enumeration/status.enum";

import { EnumHelper } from "helper/enum-helper";
import { NumberHelper } from "helper/number-helper";

import { Markdown } from "utils/markdown";

import Styles from "./stats.styles";

export const Stats = ({ status, text, textAlignMobile, textAlignDesktop, backgroundColor }) => {
    const { donationAmountUserValue, donationAmountC24Value, employeeEngagementValue, fundingProgrammeValue } =
        useContext(GlobalContext);
    const totalValue =
        donationAmountUserValue + donationAmountC24Value + fundingProgrammeValue + employeeEngagementValue;

    if (status === StatusEnum.DRAFT) return null;

    return (
        <ContentRow backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
            <Styles.Container withColor={backgroundColor !== "white"}>
                {text && (
                    <Styles.TextContainer textAlignMobile={textAlignMobile} textAlignDesktop={textAlignDesktop}>
                        <Markdown>{text}</Markdown>
                    </Styles.TextContainer>
                )}

                <Styles.ChartContainer>
                    <Styles.PieChartContainer>
                        <Styles.PieChartCenter>
                            <Styles.Counter>{NumberHelper.formatTotal(totalValue)} Mio €</Styles.Counter>

                            <Styles.Caption>insgesamt gespendet</Styles.Caption>
                        </Styles.PieChartCenter>

                        <PieChart
                            startAngle={-90}
                            data={[
                                {
                                    title: "One",
                                    value: donationAmountUserValue,
                                    color: Colors.RED_FADBD2,
                                },
                                {
                                    title: "Two",
                                    value: donationAmountC24Value,
                                    color: Colors.BLUE_063773,
                                },
                                {
                                    title: "Three",
                                    value: fundingProgrammeValue,
                                    color: Colors.BLUE_459ABB,
                                },
                                {
                                    title: "Four",
                                    value: employeeEngagementValue,
                                    color: Colors.BLUE_DFEAF7,
                                },
                            ]}
                        />
                    </Styles.PieChartContainer>

                    <Styles.Legend>
                        <Styles.LegendTitle>Von Kunden gespendet:</Styles.LegendTitle>

                        <Styles.LegendEntry>
                            <Styles.LegendIcon color={Colors.RED_FADBD2} />

                            <Styles.LegendAmount>
                                {NumberHelper.formatCurrencyAmount(donationAmountUserValue)}
                            </Styles.LegendAmount>

                            <Styles.LegendText>Punkteprogramm</Styles.LegendText>
                        </Styles.LegendEntry>

                        <Styles.LegendTitle>Von CHECK24 gespendet:</Styles.LegendTitle>

                        <Styles.LegendEntry>
                            <Styles.LegendIcon color={Colors.BLUE_063773} />

                            <Styles.LegendAmount>
                                {NumberHelper.formatCurrencyAmount(donationAmountC24Value)}
                            </Styles.LegendAmount>

                            <Styles.LegendText>Punkteprogramm</Styles.LegendText>
                        </Styles.LegendEntry>

                        <Styles.LegendEntry>
                            <Styles.LegendIcon color={Colors.BLUE_459ABB} />

                            <Styles.LegendAmount>
                                {NumberHelper.formatCurrencyAmount(fundingProgrammeValue)}
                            </Styles.LegendAmount>

                            <Styles.LegendText>Regionale Förderung</Styles.LegendText>
                        </Styles.LegendEntry>

                        <Styles.LegendEntry>
                            <Styles.LegendIcon color={Colors.BLUE_DFEAF7} />

                            <Styles.LegendAmount>
                                {NumberHelper.formatCurrencyAmount(employeeEngagementValue)}
                            </Styles.LegendAmount>

                            <Styles.LegendText>Mitarbeiterengagement</Styles.LegendText>
                        </Styles.LegendEntry>
                    </Styles.Legend>
                </Styles.ChartContainer>
            </Styles.Container>
        </ContentRow>
    );
};

Stats.defaultProps = {
    status: null,
    text: null,
    textAlignMobile: "left",
    textAlignDesktop: "center",
    backgroundColor: "white",
};

Stats.propTypes = {
    status: PropTypes.string,
    text: PropTypes.string,
    textAlignMobile: PropTypes.string,
    textAlignDesktop: PropTypes.string,
    backgroundColor: PropTypes.string,
};

export default Stats;
