import React from "react";
import { PieChart as ReactPieChart } from "react-minimal-pie-chart";
import PropTypes from "prop-types";

import { ContentRow } from "components/content-row/content-row";

import { StatusEnum } from "enumeration/status.enum";

import { EnumHelper } from "helper/enum-helper";
import { NumberHelper } from "helper/number-helper";

import { Markdown } from "utils/markdown";

import Styles from "./pie-chart.styles";

export const PieChart = ({
    status,
    text,
    textAlignMobile,
    textAlignDesktop,
    backgroundColor,
    caption,
    unit,
    slices,
    source,
}) => {
    if (status === StatusEnum.DRAFT) return null;

    let totalValue = 0;

    slices.forEach((slice) => {
        totalValue += slice.value;
    });

    return (
        <ContentRow backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
            <Styles.Container withColor={backgroundColor !== "white"}>
                {text && (
                    <Styles.TextContainer textAlignMobile={textAlignMobile} textAlignDesktop={textAlignDesktop}>
                        <Markdown>{text}</Markdown>
                    </Styles.TextContainer>
                )}

                <Styles.ChartContainer>
                    <Styles.PieChartContainer>
                        <Styles.PieChartCenter>
                            <Styles.Counter>
                                {NumberHelper.formatTotal(totalValue)} Mio {unit}
                            </Styles.Counter>

                            {caption && <Styles.Caption>{caption}</Styles.Caption>}
                        </Styles.PieChartCenter>

                        <ReactPieChart startAngle={-90} data={slices} />
                    </Styles.PieChartContainer>

                    <Styles.Legend>
                        {slices.map((slice) => {
                            return (
                                <Styles.LegendEntry key={slice.color}>
                                    <Styles.LegendIcon color={slice.color} />

                                    <Styles.LegendAmount>
                                        {NumberHelper.formatAmount(slice.value, 0)} {unit}
                                    </Styles.LegendAmount>

                                    <Styles.LegendText>{slice.text}</Styles.LegendText>
                                </Styles.LegendEntry>
                            );
                        })}
                    </Styles.Legend>
                </Styles.ChartContainer>

                {source && (
                    <Styles.SourceContainer textAlignMobile="center" textAlignDesktop="center">
                        <Markdown>{source}</Markdown>
                    </Styles.SourceContainer>
                )}
            </Styles.Container>
        </ContentRow>
    );
};

PieChart.defaultProps = {
    status: null,
    text: null,
    textAlignMobile: "left",
    textAlignDesktop: "center",
    backgroundColor: "white",
    caption: null,
    unit: null,
    slices: null,
    source: null,
};

PieChart.propTypes = {
    status: PropTypes.string,
    text: PropTypes.string,
    textAlignMobile: PropTypes.string,
    textAlignDesktop: PropTypes.string,
    backgroundColor: PropTypes.string,
    caption: PropTypes.string,
    unit: PropTypes.string,
    slices: PropTypes.any,
    source: PropTypes.string,
};

export default PieChart;
