import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Column = styled.div`
    padding: 45px 0 20px;
    text-align: center;
    width: 100%;

    ${minMedia.desktop`
        padding: 100px 0 80px;
    `}
`;

const Quote = styled.span`
    font-size: 30px;
    line-height: 1;
    margin-bottom: 15px;

    ${minMedia.desktop`
        font-size: 70px;
        line-height: 85px;
        margin-bottom: 40px;
    `}
`;

const LeftQuotationMarkWrapper = styled.div`
    color: ${Colors.BLUE_7EB6DE};
    display: inline-block;
    height: 30px;
    position: relative;
    width: 39px;

    svg {
        bottom: 10px;
        height: 35px;
        left: 0;
        position: absolute;
        width: 39px;
    }

    ${minMedia.desktop`
        height: 70px;
        width: 111px;

        svg {
            bottom: 20px;
            height: 100px;
            width: 111px;
        }
    `};
`;

const RightQuotationMarkWrapper = styled.div`
    color: ${Colors.BLUE_7EB6DE};
    display: inline-block;
    height: 30px;
    position: relative;
    width: 39px;

    svg {
        height: 35px;
        left: 0;
        position: absolute;
        top: 20px;
        width: 39px;
    }

    ${minMedia.desktop`
        height: 70px;
        width: 111px;

        svg {
            height: 100px;
            top: 43px;
            width: 111px;
        }
    `};
`;

const Text = styled.span`
    color: ${Colors.PURPLE_406591};
    font-size: 30px;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    margin: 0;

    ${minMedia.desktop`
        font-size: 70px;
    `}
`;

const Caption = styled.p`
    color: ${Colors.GREY_575757};
    font-size: 16px;
    margin: 25px 0 0;

    ${minMedia.desktop`
        font-size: 20px;
        margin: 60px 0 0 !important;
    `};
`;

export default {
    Column,
    Quote,
    LeftQuotationMarkWrapper,
    RightQuotationMarkWrapper,
    Text,
    Caption,
};
