import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Frame = styled.div`
    margin: 20px 0;

    ${minMedia.desktop`
        background-color: ${(props) => props.backgroundColor};
        margin: 5px 0;
    `};
`;

const Container = styled.div`
    text-align: left;
    ${(props) => (props.withColor ? "padding" : "margin")}: 25px 0 25px;

    ${minMedia.desktop`
        text-align: center;
        ${(props) => (props.withColor ? "padding" : "margin")}: 70px 0 70px;
    `};
`;

const TextContainer = styled.div`
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    ${minMedia.desktop`
        margin-bottom: 30px;
    `};
`;

const Column = styled.div`
    background-color: ${Colors.GREEN_E2F1EE};
    margin: 0 auto;
    max-width: 1280px;
    padding: 10px 0 25px;

    ${minMedia.desktop`
        padding: 0 0 70px;
    `};
`;

const SubTitle = styled.h3`
    text-align: left;
    margin: 30px 0 0 !important;

    &:first-child {
        margin-top: 0 !important;
    }
`;

const CtaContainer = styled.div`
    margin: 40px 0 0;
`;

const Hint = styled.span`
    color: ${Colors.GREY_333333};
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    margin: 20px 0 0;
    text-align: center;
    width: 100%;

    ${minMedia.desktop`
        width: 350px;
    `};
`;

const List = styled.div`
    margin: 0 auto;
    max-width: 998px;
`;

const EntryContainer = styled.div`
    margin-top: 30px;
`;

const ImageWrapper = styled.div`
    border-radius: 50%;
    height: 70px;
    width: 70px;
    background-color: red;
    margin-right: 30px;
    content: "";

    ${minMedia.desktop`
         height: 100px;
         width: 100px;
    `};
`;

const TextWrapper = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
        margin-top: 10px;
    }
`;

const ServerErrorWrapper = styled.div`
    margin-top: 10px;
`;

const Info = styled.span`
    color: ${Colors.GREY_333333};
    font-size: 12px !important;
    line-height: 22px;
    text-align: left;
    white-space: nowrap;
`;

export default {
    Frame,
    Container,
    TextContainer,
    Column,
    SubTitle,
    CtaContainer,
    Hint,
    List,
    EntryContainer,
    ImageWrapper,
    TextWrapper,
    ServerErrorWrapper,
    Info,
};
