export class MimeTypeEnum {
    static JPG = "image/jpg";

    static JPEG = "image/jpeg";

    static PNG = "image/png";

    static PDF = "application/pdf";

    static SVG = "image/svg+xml";
}

export default MimeTypeEnum;
