import React from "react";
import PropTypes from "prop-types";

export const File = ({ height, width, ...rest }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 76.5 101.6" {...rest}>
            <path
                fill="currentColor"
                d="M73.2 20.1L56.8 3.8C55.1 2 52.7 1 50.2 1H10.4C5.2 1 1 5.2 1 10.4v81.2c0 5.2 4.2 9.4 9.4 9.4h56.2c5.2 0 9.4-4.2 9.4-9.4V26.8c0-2.5-1-4.9-2.8-6.7zM65.9 26H51V11.2L65.9 26zM10.4 91.6V10.4h31.2v20.3c0 2.6 2.1 4.7 4.7 4.7h20.3v56.2H10.4z"
            />
        </svg>
    );
};

File.defaultProps = {
    color: null,
    height: "21px",
    width: "20px",
};

File.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default File;
