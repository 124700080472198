import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Container = styled.div`
    ${(props) => (props.withColor ? "padding" : "margin")}: 25px 0 25px;

    ${minMedia.desktop`
        text-align: center;
        ${(props) => (props.withColor ? "padding" : "margin")}: 70px 0 70px;
   `};
`;

const TextContainer = styled.div`
    text-align: ${(props) => props.textAlignMobile ?? "left"};
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    ${minMedia.desktop`
        margin-bottom: 30px;
        text-align: ${(props) => props.textAlignDesktop ?? "center"};
    `};
`;

const ChartContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;

    ${minMedia.desktop`
        flex-direction: row;
        margin-top: 60px;
    `};
`;

const PieChartContainer = styled.div`
    height: 180px;
    position: relative;
    width: 180px;

    ${minMedia.desktop`
        height: 400px;
        width: 400px;
    `};
`;

const PieChartCenter = styled.div`
    align-items: center;
    background-color: ${Colors.WHITE_FFFFFF};
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    height: 70%;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
`;

const Counter = styled.div`
    color: ${Colors.GREEN_14CCB7};
    font-size: 20px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    text-align: center;

    ${minMedia.desktop`
        font-size: 48px;
    `};
`;

const Caption = styled.div`
    color: ${Colors.GREY_575757};
    font-size: 16px;
    text-align: center;

    ${minMedia.desktop`
        font-size: 20px;
    `};
`;

const Legend = styled.div`
    margin-top: 30px;

    ${minMedia.desktop`
        margin-top: 0;
        margin-left: 100px;
        width: 440px;
    `};
`;

const LegendTitle = styled.h3`
    color: ${Colors.GREY_575757};
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    margin: 0;
    text-align: left;
    margin-top: 25px;

    &:first-child {
        margin-top: 0;
    }

    ${minMedia.desktop`
        font-size: 20px;
    `};
`;

const LegendEntry = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 15px;
`;

const LegendIcon = styled.div`
    background-color: ${(props) => props.color};
    border-radius: 50%;
    flex: 0 0 auto;
    height: 25px;
    width: 25px;
    margin-right: 18px;
`;

const LegendAmount = styled.div`
    color: ${Colors.GREY_575757};
    flex: 0 0 109px;
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    margin-right: 18px;
    min-width: 109px;
    text-align: right;

    ${minMedia.desktop`
        flex: 0 0 127px;
        font-size: 20px;
    `};
`;

const LegendText = styled.div`
    color: ${Colors.GREY_575757};
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    hyphens: auto;

    ${minMedia.desktop`
        font-size: 20px;
    `};
`;

export default {
    Container,
    TextContainer,
    ChartContainer,
    PieChartContainer,
    PieChartCenter,
    Counter,
    Caption,
    Legend,
    LegendTitle,
    LegendEntry,
    LegendIcon,
    LegendAmount,
    LegendText,
};
