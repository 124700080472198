import React from "react";
import PropTypes from "prop-types";

import { Footer, PopupFooter } from "sections/footer/footer";
import { Nav, PopupNav } from "sections/nav/nav";

import Styles from "styles/shared.styles";

export const Layout = ({ global, categories, category, children }) => (
    <Styles.Container lang="de">
        <Nav global={global} categories={categories} category={category} />

        {children}

        <Footer global={global} categories={categories} />
    </Styles.Container>
);

Layout.defaultProps = {
    category: null,
};

Layout.propTypes = {
    global: PropTypes.any.isRequired,
    categories: PropTypes.any.isRequired,
    category: PropTypes.any,
    children: PropTypes.any.isRequired,
};

export const PopupLayout = ({ global, categories, category, children }) => (
    <Styles.Container lang="de">
        <PopupNav global={global} categories={categories} category={category} />

        {children}

        <PopupFooter global={global} categories={categories} />
    </Styles.Container>
);

PopupLayout.defaultProps = {
    category: null,
};

PopupLayout.propTypes = {
    global: PropTypes.any.isRequired,
    categories: PropTypes.any.isRequired,
    category: PropTypes.any,
    children: PropTypes.any.isRequired,
};

export default Layout;
