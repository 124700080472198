import styled, { css } from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

import { Chevron } from "icons/chevron";

const TileSection = styled.div`
    margin: 15px 0;
    padding: 0 10px;

    & + & {
        border-top: 1px solid ${Colors.GREY_DCDCDC};
        padding-top: 15px;
    }

    ${minMedia.desktop`
        margin: 20px 0;
        padding: 0 20px;

         & + & {
            padding-top: 20px;
         }
    `}
`;

const TitleBar = styled.div`
    cursor: pointer;
    padding-right: 37px;
    position: relative;
`;

const Title = styled.div`
    margin-top: 0 !important;
    text-align: left;
`;

const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 22px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;

    ${minMedia.desktop`
       height: 27px;
    `};
`;

const Icon = styled(Chevron)`
    color: ${Colors.BLUE_005EA8};
    transition: all 0.4s;

    ${(props) =>
        props.isopen === "true" &&
        css`
            transform: rotate(-180deg);
        `}
`;

const Content = styled.div`
    height: 0;
    overflow: hidden;
    text-align: left;
    transition: height 0.4s;
`;

const TextContainer = styled.div`
    text-align: left;
    padding-top: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    ${minMedia.desktop`
       padding-top: 30px;
    `};
`;

export default {
    TileSection,
    TitleBar,
    Title,
    IconWrapper,
    Icon,
    Content,
    TextContainer,
};
