import React from "react";
import NextLink from "next/link";
import PropTypes from "prop-types";

import { ContentRow } from "components/content-row/content-row";
import { Image } from "components/image/image";
import { ImageShape } from "components/image/image.shape";

import { StatusEnum } from "enumeration/status.enum";

import { EnumHelper } from "helper/enum-helper";

import { Link } from "utils/link";
import { Markdown } from "utils/markdown";

import Styles from "./partner-field.styles";

export const PartnerField = ({
    status,
    image,
    title,
    description,
    link,
    rtl,
    backgroundColor,
    imageBackgroundColor
}) => {
    if (status === StatusEnum.DRAFT) return null;

    const minHeightDesktop = 300;
    const textWidthDesktop = 44;
    const imageFit = "contain";
    const imagePosition = "center center";

    const renderField = () => (
        <Styles.Frame backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
            <ContentRow backgroundColor="transparent">
                <Styles.Container rtl={rtl} minHeightDesktop={minHeightDesktop} width={textWidthDesktop}>
                    <Styles.ImageWrapper
                        minHeightDesktop={minHeightDesktop}
                        width={100 - textWidthDesktop}
                        backgroundColor={EnumHelper.mapBackgroundColor(imageBackgroundColor || backgroundColor)}
                        objectFit={imageFit}
                        objectPosition={imagePosition}
                    >
                        {image && image.url && <Image image={image} />}
                    </Styles.ImageWrapper>

                    <Styles.Content backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
                        <Styles.TextContainer>
                            <Markdown>{title}</Markdown>

                            <Styles.DescriptionContainer>
                                <Markdown>{description}</Markdown>
                            </Styles.DescriptionContainer>

                            <Styles.MobileDescriptionContainer>
                                <Markdown>{description}</Markdown>
                            </Styles.MobileDescriptionContainer>
                        </Styles.TextContainer>

                        {!!link && <Styles.Link>{link.title}</Styles.Link>}
                    </Styles.Content>
                </Styles.Container>

                <Styles.Container>
                    <Styles.TabletDescriptionContainer>
                        <Markdown>{description}</Markdown>
                    </Styles.TabletDescriptionContainer>
                </Styles.Container>
            </ContentRow>
        </Styles.Frame>
    );

    if (link) {
        if (link.external) {
            return (
                <Link href={link.url} target="_blank">
                    {renderField()}
                </Link>
            );
        }

        return (
            <NextLink href={link.url}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>{renderField()}</a>
            </NextLink>
        );
    }

    return <>{renderField()}</>;
};

PartnerField.defaultProps = {
    status: null,
    link: null,
    imageBackgroundColor: "yellow"
};

PartnerField.propTypes = {
    status: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.shape(ImageShape).isRequired,
    link: PropTypes.any,
    rtl: PropTypes.bool.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    imageBackgroundColor: PropTypes.string,
};

export default PartnerField;
