import React from "react";
import PropTypes from "prop-types";

import Styles from "./item.styles";

export const CarouselItem = ({
    aspectRatio,
    children,
    clickable,
    currentSlideIndex,
    index,
    isDragging,
    offset,
    onMouseDown,
    onTouchStart,
    width,
}) => {
    return (
        <Styles.Item
            style={{
                height: `${(aspectRatio && `${aspectRatio * width}px`) || "inherit"}`,
                maxWidth: `${width}px`,
                minWidth: `${width}px`,
                paddingLeft: `${offset / 2}px`,
                paddingRight: `${offset / 2}px`,
                pointerEvents: isDragging ? "none" : null,
                width: `${width}px`,
            }}
            active={index === currentSlideIndex}
            clickable={clickable}
            onMouseDown={(e) => onMouseDown(e, index)}
            onTouchStart={(e) => onTouchStart(e, index)}
        >
            {children}
        </Styles.Item>
    );
};

CarouselItem.defaultProps = {
    aspectRatio: null,
};

CarouselItem.propTypes = {
    aspectRatio: PropTypes.number,
    children: PropTypes.element.isRequired,
    clickable: PropTypes.bool.isRequired,
    currentSlideIndex: PropTypes.number.isRequired,
    offset: PropTypes.number.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    onTouchStart: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isDragging: PropTypes.bool.isRequired,
    width: PropTypes.number.isRequired,
};

export default CarouselItem;
