import React from "react";
import PropTypes from "prop-types";

import Styles from "./dots.styles";

export const Dots = ({ number, thumbnails, value, onChange }) => {
    const calculateButtonValue = () => {
        const numberOfSlides = number || thumbnails.length;

        return value >= 0 ? value : value + numberOfSlides * Math.ceil(Math.abs(value / numberOfSlides));
    };

    const onClick = (index) => () => {
        const numberOfSlides = number || thumbnails.length;
        const moduloItem = calculateButtonValue() % numberOfSlides;

        return onChange(value - (moduloItem - index));
    };

    const renderCarouselDots = () => {
        if (thumbnails) {
            const dotsLength = Number.isNaN(number) ? thumbnails.length : number;

            return thumbnails.slice(0, dotsLength).map((thumbnail, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Styles.ListItem key={index}>
                    <Styles.Thumbnail selected={index === calculateButtonValue() % dotsLength} onClick={onClick(index)}>
                        {thumbnail}
                    </Styles.Thumbnail>
                </Styles.ListItem>
            ));
        }

        const dots = [];

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < number; i++) {
            dots.push(
                <Styles.ListItem key={i}>
                    <Styles.Dot selected={i === calculateButtonValue() % number} onClick={onClick(i)}>
                        {i + 1}
                    </Styles.Dot>
                </Styles.ListItem>
            );
        }

        return dots;
    };

    return <Styles.List>{renderCarouselDots()}</Styles.List>;
};

Dots.defaultProps = {
    thumbnails: null,
};

Dots.propTypes = {
    number: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    thumbnails: PropTypes.arrayOf(PropTypes.node),
    value: PropTypes.number.isRequired,
};

export default Dots;
