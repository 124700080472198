/**
 * modified, based on https://github.com/brainhubeu/react-carousel
 */
import React, { useCallback, useEffect, useRef, useState } from "react";
import concat from "lodash/concat";
import isNil from "lodash/isNil";
import throttle from "lodash/throttle";
import times from "lodash/times";
import PropTypes from "prop-types";

import Styles from "./carousel.styles";
import { ArrowLeft, ArrowRight } from "./component/arrows";
import { Dots } from "./component/dots";
import { CarouselItem } from "./component/item";

export const Carousel = ({ children, ...props }) => {
    const config = {
        resizeEventListenerThrottle: 300, // event listener onResize will not be triggered more frequently than once per given number of miliseconds
        clickDragThreshold: 10,
        numberOfInfiniteClones: 2, // number of clones (for each side) of slides that should be created for infinite carousel
    };

    const {
        addArrowClickHandler,
        arrows,
        arrowLeft,
        arrowLeftDisabled,
        arrowRight,
        arrowRightDisabled,
        aspectRatio,
        dots,
        infinite,
        observeNewImages,
        onChange,
        overflow,
        slides,
        thumbnails,
        value,
    } = props;

    const [carouselWidth, setCarouselWidth] = useState(0);
    const [currentValue, setCurrentValue] = useState(value || 0);
    const [clicked, setClicked] = useState(null);
    const [dragOffset, setDragOffset] = useState(0);
    const [dragStart, setDragStart] = useState(null);
    const [elementScrollStart, setElementScrollStart] = useState(null);
    const [infiniteTransitionFrom, setInfiniteTransitionFrom] = useState(infinite ? 0 : null); // indicates what slide we are transitioning from (in case of infinite carousel), contains number value or null
    const [moving, setMoving] = useState(false);
    const [numberOfClonesLeft, setNumberOfClonesLeft] = useState(config.numberOfInfiniteClones);
    const [numberOfClonesRight, setNumberOfClonesRight] = useState(config.numberOfInfiniteClones);
    const [scrollStart, setScrollStart] = useState(null);
    const [trackHeight, setTrackHeight] = useState(0);
    const [transitionEnabled, setTransitionEnabled] = useState(false);

    const arrowLeftNode = useRef(null);
    const arrowRightNode = useRef(null);
    const node = useRef(null);
    const trackRef = useRef(null);

    const getChildren = useCallback(() => {
        if (!children) {
            if (slides) {
                return slides;
            }
            return [];
        }
        if (Array.isArray(children)) {
            return children;
        }
        return [children];
    }, [children, slides]);

    const getNumberOfChildren = useCallback(() => {
        return getChildren()?.length || 0;
    }, [getChildren]);

    /**
     * Handler setting the carouselWidth value in state (used to set proper width of track and slides)
     * throttled to improve performance
     * @type {Function}
     */
    const onResize = throttle(() => {
        if (!node.current) {
            return;
        }

        const arrowLeftWidth = arrowLeftNode.current && arrowLeftNode.current?.offsetWidth;
        const arrowRightWidth = arrowRightNode.current && arrowRightNode.current?.offsetWidth;
        const width = node.current?.offsetWidth - (arrowLeftWidth || 0) - (arrowRightWidth || 0);

        setCarouselWidth(width);
    }, config.resizeEventListenerThrottle);

    /**
     * Calculates width of a single slide in a carousel
     * @return {number} width of a slide in px
     */
    const getCarouselElementWidth = () => {
        return props.itemWidth || (carouselWidth - props.offset) / props.slidesPerPage;
    };

    const getNeededAdditionalClones = useCallback(() => {
        return Math.ceil((currentValue - infiniteTransitionFrom) / getNumberOfChildren());
    }, [currentValue, getNumberOfChildren, infiniteTransitionFrom]);

    const getAdditionalClonesLeft = useCallback(() => {
        const additionalClones = getNeededAdditionalClones();
        return additionalClones < 0 ? additionalClones * -1 : 0;
    }, [getNeededAdditionalClones]);

    const getAdditionalClonesRight = useCallback(() => {
        const additionalClones = getNeededAdditionalClones();
        return additionalClones > 0 ? additionalClones : 0;
    }, [getNeededAdditionalClones]);

    const getAdditionalClonesOffset = () => {
        return getNumberOfChildren() * getCarouselElementWidth() * getAdditionalClonesLeft() * -1;
    };

    const getClonesLeft = useCallback(() => {
        return config.numberOfInfiniteClones + getAdditionalClonesLeft();
    }, [config.numberOfInfiniteClones, getAdditionalClonesLeft]);

    const getClonesRight = useCallback(() => {
        return config.numberOfInfiniteClones + getAdditionalClonesRight();
    }, [config.numberOfInfiniteClones, getAdditionalClonesRight]);

    const getTargetMod = (customValue = null) => {
        const index = isNil(customValue) ? currentValue : customValue;
        const length = getNumberOfChildren();
        let targetSlide;

        if (index >= 0) {
            targetSlide = index % length;
        } else {
            targetSlide = (length - Math.abs(index % length)) % length;
        }

        return targetSlide;
    };

    const getTargetSlide = () => {
        if (!isNil(infiniteTransitionFrom)) {
            const mod = getTargetMod(infiniteTransitionFrom);

            return mod + (currentValue - infiniteTransitionFrom);
        }
        return getTargetMod();
    };

    /**
     * Clamps number between 0 and last slide index.
     * @param {number} clampValue to be clamped
     * @return {number} new value
     */
    const clamp = (clampValue) => {
        const maxValue = getNumberOfChildren() - props.slidesPerPage;
        if (clampValue > maxValue) {
            return maxValue;
        }
        if (clampValue < 0) {
            return 0;
        }
        return clampValue;
    };

    /**
     * Returns the current slide index (from either props or internal state)
     * @return {number} index
     */
    const getCurrentSlideIndex = () => {
        if (props.infinite) {
            return getTargetSlide();
        }
        return clamp(currentValue);
    };

    const getActiveSlideIndex = () => {
        return infinite ? getCurrentSlideIndex() + numberOfClonesLeft * getNumberOfChildren() : getCurrentSlideIndex();
    };

    const getNodeTop = () => {
        if (node.current) {
            return node.current.getBoundingClientRect().top;
        }

        return 0;
    };

    /**
     * Clamps a provided value and triggers onChange
     * @param {number} newValue desired index to change current value to
     */
    const changeSlide = (newValue) => {
        const tmpValue = infinite ? newValue : clamp(newValue);
        setTransitionEnabled(true);
        setCurrentValue(tmpValue);
    };

    /**
     * Checks what slide index is the nearest to the current position (to calculate the result of dragging the slider)
     * @return {number} index
     */
    const getNearestSlideIndex = () => {
        let slideIndexOffset = 0;

        if (Math.abs(dragOffset) > Math.abs(getNodeTop() - elementScrollStart)) {
            if (props.keepDirectionWhenDragging) {
                if (dragOffset > 0) {
                    slideIndexOffset = Math.ceil(dragOffset / getCarouselElementWidth());
                } else {
                    slideIndexOffset = Math.floor(dragOffset / getCarouselElementWidth());
                }
            } else {
                slideIndexOffset = Math.round(dragOffset / getCarouselElementWidth());
            }
        }
        return currentValue - slideIndexOffset;
    };

    /**
     * Function handling beginning of mouse drag by setting index of clicked item and coordinates of click in the state
     * @param {event} e event
     * @param {number} index of the element drag started on
     */
    const onMouseDown = (e, index) => {
        e?.preventDefault();
        e?.stopPropagation();
        const { pageX, clientY } = e;
        setClicked(index);
        setDragStart(pageX);
        setScrollStart(clientY);
        setElementScrollStart(getNodeTop());
        setMoving(true);
    };

    /**
     * Function handling mouse move if drag has started. Sets dragOffset in the state.
     * @param {event} e event
     */
    const onMouseMove = (e) => {
        const { pageX, clientY } = e;

        if (moving) {
            const tmpDragOffset = pageX - dragStart;
            const tmpScrollOffset = clientY - scrollStart;

            if (Math.abs(tmpDragOffset) > Math.abs(tmpScrollOffset)) {
                setDragOffset(tmpDragOffset);
            }
        }
    };

    /**
     * Function handling beginning of touch drag by setting index of touched item and coordinates of touch in the state
     * @param {event} e event
     * @param {number} index of the element drag started on
     */
    const onTouchStart = (e, index) => {
        const { changedTouches } = e;
        setClicked(index);
        setDragStart(changedTouches[0].pageX);
        setScrollStart(changedTouches[0].clientY);
        setElementScrollStart(getNodeTop());
        setMoving(true);
    };

    /**
     * Function handling touch move if drag has started. Sets dragOffset in the state.
     * @param {event} e event
     */
    const onTouchMove = (e) => {
        if (Math.abs(dragOffset) > props.minDraggableOffset) {
            e?.stopPropagation();
        }
        const { changedTouches } = e;

        if (moving) {
            const tmpDragOffset = changedTouches[0].pageX - dragStart;
            const tmpScrollOffset = changedTouches[0].clientY - scrollStart;

            if (Math.abs(tmpDragOffset) > Math.abs(tmpScrollOffset)) {
                setDragOffset(tmpDragOffset);
            }
        }
    };

    /**
     * Function handling end of touch or mouse drag. If drag was long it changes current slide to the nearest one,
     * if drag was short (or it was just a click) it changes slide to the clicked (or touched) one.
     * It resets clicked index, dragOffset and dragStart values in state.
     * @param {event} e event
     */
    const onMouseUpTouchEnd = (e) => {
        if (moving) {
            if (props.draggable) {
                if (Math.abs(dragOffset) > config.clickDragThreshold) {
                    if (e?.cancelable) {
                        e?.preventDefault();
                    }
                    changeSlide(getNearestSlideIndex());
                } else if (props.clickToChange) {
                    if (e?.cancelable) {
                        e?.preventDefault();
                    }
                    changeSlide(props.infinite ? currentValue + clicked - getActiveSlideIndex() : clicked);
                }
            }
            setClicked(null);
            setDragOffset(0);
            setDragStart(null);
            setScrollStart(null);
        }
        setMoving(false);
    };

    /**
     * Handler setting transitionEnabled value in state to false after transition animation ends
     */
    const onTransitionEnd = () => {
        setTransitionEnabled(false);
        setInfiniteTransitionFrom(props.infinite ? currentValue : null);
    };

    const prevSlide = () => changeSlide(currentValue - props.slidesPerScroll);

    const nextSlide = () => changeSlide(currentValue + props.slidesPerScroll);

    /**
     * TODO: solve problem with 35px offset
     * Calculates offset in pixels to be applied to Track element in order to show current slide correctly (centered or aligned to the left)
     * @return {number} offset in px
     */
    const getTransformOffset = () => {
        const elementWidth = getCarouselElementWidth();
        const additionalOffset = props.centered ? carouselWidth / 2 - elementWidth / 2 + props.offset / 2 : 0;
        const offset = props.draggable ? dragOffset : 0;
        const additionalClonesOffset = getAdditionalClonesOffset();

        return (
            offset -
            getActiveSlideIndex() * elementWidth +
            additionalOffset -
            additionalClonesOffset +
            props.offset / 2 -
            30
        );
    };

    useEffect(() => {
        if (typeof document !== "object" || typeof window !== "object") {
            return;
        }

        // adding listener to remove transition when animation finished
        if (trackRef.current) {
            setTimeout(() => {
                if (trackRef.current) setTrackHeight(trackRef.current.getBoundingClientRect().height);
            }, 1);
        }

        onResize();
        window.addEventListener("resize", onResize); // setting size of a carousel in state
        window.addEventListener("load", onResize); // setting size of a carousel in state based on styling

        // eslint-disable-next-line consistent-return
        return () => {
            window.removeEventListener("resize", onResize);
            window.removeEventListener("load", onResize);
        };
    }, [onResize]);

    useEffect(() => {
        const clonesLeft = getClonesLeft();
        const clonesRight = getClonesRight();

        if (numberOfClonesLeft !== clonesLeft || numberOfClonesRight !== clonesRight) {
            if (observeNewImages) observeNewImages();
        }

        if (onChange) onChange(currentValue);
        setNumberOfClonesLeft(clonesLeft);
        setNumberOfClonesRight(clonesRight);
    }, [
        currentValue,
        getClonesLeft,
        getClonesRight,
        numberOfClonesLeft,
        numberOfClonesRight,
        observeNewImages,
        onChange,
    ]);

    const renderCarouselItems = () => {
        const transformOffset = getTransformOffset();
        const items = getChildren();
        const trackLengthMultiplier = 1 + (props.infinite ? numberOfClonesLeft + numberOfClonesRight : 0);
        const trackWidth = carouselWidth * items?.length * trackLengthMultiplier;
        const speed = props.animationSpeed;
        const isDraggable = props.draggable && items && items?.length > 1;

        const trackStyles = {
            marginLeft: `${getAdditionalClonesOffset()}px`,
            width: `${trackWidth}px`,
            left: `${transformOffset}px`,
            transitionDuration: transitionEnabled ? `${speed}ms, ${speed}ms` : null,
            overflow: props.overflow,
        };

        let carouselItems = items;

        if (props.infinite) {
            const clonesLeft = times(numberOfClonesLeft, () => items);
            const clonesRight = times(numberOfClonesRight, () => items);
            carouselItems = concat(...clonesLeft, items, ...clonesRight);
        }

        return (
            <Styles.TrackContainer
                style={{
                    height: trackHeight,
                    opacity: (trackHeight > 0 && 1) || 0,
                    overflow: props.overflow,
                }}
            >
                <Styles.Track
                    style={trackStyles}
                    ref={trackRef}
                    isDraggable={isDraggable}
                    onMouseLeave={onMouseUpTouchEnd}
                    onMouseDown={onMouseDown}
                    onMouseMove={onMouseMove}
                    onMouseUp={onMouseUpTouchEnd}
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onMouseUpTouchEnd}
                    onTransitionEnd={onTransitionEnd}
                >
                    {carouselItems?.map((carouselItem, index) => (
                        <CarouselItem
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={index}
                            aspectRatio={aspectRatio}
                            clickable={props.clickToChange}
                            currentSlideIndex={getActiveSlideIndex()}
                            index={index}
                            isDragging={Math.abs(dragOffset) > props.minDraggableOffset}
                            offset={index !== carouselItems?.length ? props.offset : 0}
                            onMouseDown={onMouseDown}
                            onTouchStart={onTouchStart}
                            width={getCarouselElementWidth() - props.offset}
                        >
                            {carouselItem}
                        </CarouselItem>
                    ))}
                </Styles.Track>
            </Styles.TrackContainer>
        );
    };

    return (
        <Styles.Container>
            <Styles.Bar style={{ overflow }} ref={node}>
                <ArrowLeft
                    addArrowClickHandler={addArrowClickHandler}
                    arrows={arrows}
                    arrowLeft={arrowLeft}
                    arrowLeftDisabled={arrowLeftDisabled}
                    disabled={currentValue <= 0 && !infinite}
                    prevSlide={prevSlide}
                    ref={arrowLeftNode}
                />

                {renderCarouselItems()}

                <ArrowRight
                    addArrowClickHandler={addArrowClickHandler}
                    arrows={arrows}
                    arrowRight={arrowRight}
                    arrowRightDisabled={arrowRightDisabled}
                    disabled={currentValue === getNumberOfChildren() - props.slidesPerPage && !infinite}
                    nextSlide={nextSlide}
                    ref={arrowRightNode}
                />
            </Styles.Bar>

            {(dots && (
                <Dots
                    onChange={changeSlide}
                    number={getNumberOfChildren()}
                    thumbnails={thumbnails}
                    value={currentValue}
                />
            )) ||
                null}
        </Styles.Container>
    );
};

Carousel.defaultProps = {
    addArrowClickHandler: false,
    animationSpeed: 500,
    arrowLeft: null,
    arrowLeftDisabled: null,
    arrowRight: null,
    arrowRightDisabled: null,
    arrows: true,
    aspectRatio: null,
    centered: false,
    children: null,
    clickToChange: false,
    dots: false,
    draggable: true,
    infinite: true,
    itemWidth: null,
    keepDirectionWhenDragging: true,
    minDraggableOffset: 10,
    onChange: null,
    overflow: "hidden",
    observeNewImages: null,
    offset: 0,
    slidesPerPage: 1,
    slidesPerScroll: 1,
    slides: null,
    thumbnails: null,
    value: 0,
};

Carousel.propTypes = {
    addArrowClickHandler: PropTypes.bool,
    animationSpeed: PropTypes.number,
    arrowLeft: PropTypes.element,
    arrowLeftDisabled: PropTypes.element,
    arrowRight: PropTypes.element,
    arrowRightDisabled: PropTypes.element,
    arrows: PropTypes.bool,
    aspectRatio: PropTypes.number,
    centered: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    clickToChange: PropTypes.bool,
    dots: PropTypes.bool,
    draggable: PropTypes.bool,
    infinite: PropTypes.bool,
    itemWidth: PropTypes.number,
    keepDirectionWhenDragging: PropTypes.bool,
    minDraggableOffset: PropTypes.number,
    onChange: PropTypes.func,
    observeNewImages: PropTypes.func,
    offset: PropTypes.number,
    overflow: PropTypes.string,
    slides: PropTypes.arrayOf(PropTypes.node),
    slidesPerPage: PropTypes.number,
    slidesPerScroll: PropTypes.number,
    thumbnails: PropTypes.arrayOf(PropTypes.node),
    value: PropTypes.number,
};

export default Carousel;
