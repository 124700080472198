import { PropTypes } from "prop-types";

import { ImageShape } from "../image/image.shape";

export const SlideShape = {
    content: PropTypes.any.isRequired,
    image: PropTypes.shape(ImageShape).isRequired,
    aspectRatio: PropTypes.number,
    imagePadding: PropTypes.number,
    flag: PropTypes.string,
    textAlign: PropTypes.string,
    linkTitle: PropTypes.string,
    backgroundColor: PropTypes.string.isRequired,
    parentIsLink: PropTypes.bool,
};

export default SlideShape;
