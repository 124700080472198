import React from "react";
import PropTypes from "prop-types";

import Styles from "./markdown-image.styles";

export const MarkdownImage = ({ alt, src, title }) => {
    return (
        <Styles.ImageWrapper>
            <img alt={alt} src={src} title={title} />
        </Styles.ImageWrapper>
    );
};

MarkdownImage.defaultProps = {
    alt: null,
    src: null,
    title: null,
};

MarkdownImage.propTypes = {
    alt: PropTypes.string,
    src: PropTypes.string,
    title: PropTypes.string,
};

export default MarkdownImage;
