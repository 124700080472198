import React from "react";
import PropTypes from "prop-types";

import { Colors } from "container/global.colors";

export const Twitter = ({ height, width, ...rest }) => {
    return (
        <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...rest}>
            <path
                fill="currentColor"
                d="M50 0C22.4 0 0 22.4 0 50s22.4 50 50 50 50-22.4 50-50S77.6 0 50 0zm27.6 38.9v1.7c0 17.5-13.3 37.6-37.6 37.6-7.5 0-14.4-2.2-20.3-5.9 1 .1 2.1.2 3.2.2 6.2 0 11.9-2.1 16.4-5.7-5.8-.1-10.7-3.9-12.4-9.2.8.2 1.6.2 2.5.2 1.2 0 2.4-.2 3.5-.5-6.1-1.2-10.6-6.6-10.6-13v-.2c1.8 1 3.8 1.6 6 1.7-3.6-2.4-5.9-6.4-5.9-11 0-2.4.7-4.7 1.8-6.7 6.5 8 16.3 13.3 27.3 13.8-.2-1-.3-2-.3-3 0-7.3 5.9-13.2 13.2-13.2 3.8 0 7.2 1.6 9.7 4.2 3-.6 5.8-1.7 8.4-3.2-1 3.1-3.1 5.7-5.8 7.3 2.7-.3 5.2-1 7.6-2.1-1.8 2.8-4.1 5.2-6.7 7z"
            />
        </svg>
    );
};

Twitter.defaultProps = {
    color: Colors.WHITE_FFFFFF,
    height: "20px",
    width: "20px",
};

Twitter.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default Twitter;
