import { FileTypeEnum } from "enumeration/file-type.enum";
import { MimeTypeEnum } from "enumeration/mime-type.enum";

export class FileUploadHelper {
    static acquireDataUrl = (fileData) => {
        return `data:${fileData.type};base64,${fileData.base64}`;
    };

    static dataUrlToBase64 = (dataUrl) => {
        return dataUrl.split(";base64,")[1];
    };

    static mapFileTypeToMimeType = (fileType) => {
        switch (fileType) {
            case FileTypeEnum.JPG:
                return MimeTypeEnum.JPG;
            case FileTypeEnum.JPEG:
                return MimeTypeEnum.JPEG;
            case FileTypeEnum.PNG:
                return MimeTypeEnum.PNG;
            case FileTypeEnum.PDF:
                return MimeTypeEnum.PDF;
            case FileTypeEnum.SVG:
                return MimeTypeEnum.SVG;
            default:
                return "";
        }
    };

    static mapMimeTypeToFileType = (fileType) => {
        switch (fileType) {
            case MimeTypeEnum.JPG:
                return FileTypeEnum.JPG;
            case MimeTypeEnum.JPEG:
                return FileTypeEnum.JPEG;
            case MimeTypeEnum.PNG:
                return FileTypeEnum.PNG;
            case MimeTypeEnum.PDF:
                return FileTypeEnum.PDF;
            case MimeTypeEnum.SVG:
                return FileTypeEnum.SVG;
            default:
                return "";
        }
    };

    static acquireFileExtension = (fileType) => {
        switch (fileType) {
            case FileTypeEnum.JPG:
                return ".jpg";
            case FileTypeEnum.JPEG:
                return ".jpeg";
            case FileTypeEnum.PNG:
                return ".png";
            case FileTypeEnum.PDF:
                return ".pdf";
            case FileTypeEnum.SVG:
                return ".svg";
            default:
                return "";
        }
    };
}

export default FileUploadHelper;
