import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Container = styled.div`
    background-color: ${(props) => props.backgroundColor};
    margin: 0;
    text-align: center;
    width: 100%;

    ${minMedia.desktop`
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0 25px;
        text-align: center;
        width: 30%;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    `};
`;

const ImageWrapper = styled.div`
    color: ${Colors.RED_FADBD2};
    display: flex;
    flex: ${(props) => (props.flex ? props.flex : "0 0 auto")};
    flex-direction: row;
    justify-content: center;
    margin: 20px auto 20px;
    width: 100%;
    height: ${(props) => (props.imageMobileDesktop ? `${props.imageMobileDesktop}px` : "120px")};

    ${minMedia.desktop`
        height: ${(props) => (props.imageHeightDesktop ? `${props.imageHeightDesktop}px` : "180px")};
    `};

    svg {
        height: inherit;
        width: inherit;
    }

    img {
        height: 100%;
    }
`;

const Content = styled.div`
    box-sizing: border-box;
    flex: 1 1 auto;
    margin: ${(props) => (props.sameColor ? "20px 0 0" : "20px 0")};
    padding: 0 15px;
    width: 100%;

    ${minMedia.desktop`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: ${(props) => (props.sameColor ? "25px 0 0" : "25px 0")};
        padding: 0 35px;
    `};
`;

const TextContainer = styled.div`
    hyphens: auto;
`;

const Title = styled.div`
    color: ${(props) => props.color || Colors.GREEN_14CCB7};
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    margin: 0;
    min-height: 66px;
    text-align: center;

    ${minMedia.desktop`
        font-size: 24px;
    `};
`;

const Paragraph = styled.div`
    color: ${Colors.GREY_575757};
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 16px 0 0;
    overflow: hidden;

    &:first-child {
        margin-top: 0;
    }

    p a {
        color: ${Colors.BLUE_005EA8} !important;
        font-weight: ${(props) => props.theme.fontWeight.regular} !important;
    }

    a p {
        span.linkPlaceholder {
            color: ${Colors.BLUE_005EA8} !important;
            font-weight: ${(props) => props.theme.fontWeight.regular} !important;
        }
    }

    ${minMedia.desktop`
        font-size: 20px;
        line-height: 27px;
        margin: 20px 0 0;
    `}
`;

const Link = styled.div`
    color: ${Colors.BLUE_005EA8};
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 16px 0 0;
    text-decoration: none;

    ${minMedia.desktop`
        font-size: 20px;
        line-height: 27px;
        margin: 20px 0 0;
    `}
`;

export default {
    Container,
    ImageWrapper,
    Content,
    TextContainer,
    Title,
    Paragraph,
    Link,
};
