import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Container = styled.div`
    background-color: ${Colors.WHITE_FFFFFF};
    border: none;
    border-radius: 3px 3px 0 0;
    box-shadow: ${(props) =>
        (props.hasError && `inset 0 -2px 0 0 ${Colors.RED_C82D2D}`) ||
        (props.hasFocus && `inset 0 -2px 0 0 ${Colors.BLUE_005EA8}`) ||
        `inset 0 -1px 0 0 ${Colors.GREY_B4B4B4}`};
    box-sizing: border-box;
    color: ${Colors.GREY_333333};
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    padding-right: 10px;
    opacity ${(props) => (props.disabled && "0.5") || "1"};
    min-height: 52px;
`;

const InputRow = styled.div`
    margin: 20px 0 0;

    ${minMedia.desktop`
        display: grid;
        grid-template-columns: 2fr 5fr;
    `}
`;

const DesktopLabel = styled.div`
    color: ${Colors.GREY_575757};
    display: none;
    font-size: 20px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 12px 0 0;
    text-align: left;

    ${minMedia.desktop`
            display: block;
    `};
`;

const MobileLabel = styled.div`
    color: ${(props) =>
        (props.hasError && Colors.RED_C82D2D) || (props.hasFocus && Colors.BLUE_005EA8) || Colors.GREY_333333};
    flex: 0 0 auto;
    font-size: 16px;
    line-height: 16px;
    margin: 0 0 7px;

    ${minMedia.desktop`
            display: none;
    `};
`;

const Row = styled.div`
    align-items: ${(props) => (props.hasLabel ? "flex-start" : "center")};
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin-bottom: ${(props) => (props.hasLabel ? "8px" : "0")};
    width: 100%;

    ${minMedia.desktop`
        align-items: center;
        margin-bottom: 0;
    `};
`;

const Column = styled.div`
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
    margin: 10px 10px 0;

    ${minMedia.desktop`
         margin: 0 15px 0;
    `}
`;

const Input = styled.input`
    -moz-appearance: textfield;
    align-content: center;
    box-sizing: border-box;
    border: none;
    color: ${Colors.GREY_333333};
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 18px;
    letter-spacing: 0;
    line-height: 21px;
    margin: 0;
    padding: 0;
    width: 100%;

    &::placeholder {
        color: ${Colors.GREY_999999};
    }

    &:focus {
        outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 26px ${Colors.WHITE_FFFFFF} inset !important;
    }

    &:read-only {
        cursor: inherit;
    }
`;

const Delete = styled.div`
    align-items: center;
    box-sizing: border-box;
    color: ${Colors.GREY_B4B4B4};
    display: flex;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 21px;
    margin-left: 15px;
    width: auto;

    ${minMedia.desktop`
        margin-left: 20px;
        cursor: pointer;
    `};
`;

const Icon = styled.div`
    align-items: center;
    box-sizing: border-box;
    color: ${Colors.GREY_B4B4B4};
    display: flex;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 21px;
    margin-left: 15px;
    width: auto;

    ${minMedia.desktop`
        margin-left: 20px;
    `};
`;

export default {
    Container,
    InputRow,
    DesktopLabel,
    MobileLabel,
    Row,
    Column,
    Input,
    Delete,
    Icon,
};
