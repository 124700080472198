import React from "react";

import { Colors } from "container/global.colors";

import { EnumHelper } from "helper/enum-helper";

import { Markdown } from "utils/markdown";

import { Image } from "../image/image";
import { PillarShape } from "./pillar.shape";
import Styles from "./pillar.styles";

export const Pillar = ({
    title,
    description,
    image,
    titleColor,
    linkUrl,
    linkTitle,
    backgroundColor,
    sameColor,
    parentIsLink,
    imageHeightDesktop,
    imageMobileDesktop,
    ...rest
}) => {

    return (
        <Styles.Container backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)} {...rest}>
            {image &&
            <Styles.ImageWrapper imageHeightDesktop={imageHeightDesktop} imageMobileDesktop={imageMobileDesktop} flex={"auto"}>
                {image.url && <Image image={image} />}
            </Styles.ImageWrapper>}

            <Styles.Content sameColor={sameColor}>
                <Styles.TextContainer>
                    {title && <Styles.Title color={EnumHelper.mapTextColor(titleColor)}>{title}</Styles.Title>}

                    <Styles.Paragraph>
                        <Markdown parentIsLink={parentIsLink}>{description}</Markdown>
                    </Styles.Paragraph>
                </Styles.TextContainer>

                {linkUrl && linkTitle && <Styles.Link>{linkTitle}</Styles.Link>}
            </Styles.Content>
        </Styles.Container>
    );
};

Pillar.defaultProps = {
    title: null,
    titleColor: Colors.GREEN_14CCB7,
    linkUrl: null,
    linkTitle: null,
    parentIsLink: false,
};

Pillar.propTypes = PillarShape;

export default Pillar;
