import styled, { css } from "styled-components";

import { Colors } from "container/global.colors";

const List = styled.ul`
    display: flex;
    font-size: 0;
    justify-content: center;
    line-height: 0;
    list-style: none;
    margin: 0;
    padding: 8px 0 0 0;
`;

const ListItem = styled.li`
    flex: 0 0 auto;
    height: 30px;
    margin: 0 2px;
    position: relative;
    width: 30px;
`;

const Dot = styled.div`
    background-color: ${Colors.GREY_B4B4B4};
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 12px;
    left: 50%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.4s;
    width: 12px;

    ${(props) =>
        props.selected &&
        css`
            border: none;
            background-color: ${Colors.BLUE_005EA8};
            height: 14px;
            transition: all 0.4s;
            width: 14px;
        `}
`;

const Thumbnail = styled.div`
    border: none;
    cursor: pointer;
    opacity: 0.5;
    outline: 0;
    padding: 10px;

    ${(props) =>
        props.selected &&
        css`
            opacity: 1 !important;
        `}
    :hover {
        opacity: 1;
    }
`;

export default {
    List,
    ListItem,
    Dot,
    Thumbnail,
};
