import React from "react";
import PropTypes from "prop-types";

export const Trees = ({ height, width, ...rest }) => {
    return (
        <svg height={height} width={width} viewBox="0 0 105 83" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g fill="currentColor">
                <path d="M100.373 45.538c-.71-.719-.9-1.668-.498-2.474a9.928 9.928 0 0 0 .942-6.039c-.323-2.101-1.63-3.86-3.242-5.248-1.077-.928-1.42-2.356-.943-3.839.635-1.976.416-5.329-1.563-8.017-1.243-1.69-3.637-3.705-7.97-3.705-3.729 0-6.78 1.478-8.59 4.162-1.229 1.821-1.75 4.099-1.485 6.098a13.746 13.746 0 0 1 1.515 3.12c.654 1.96.896 4.069.735 6.23.353-.429.704-.812.962-1.089 2.19-2.358 2.818-5.586 1.641-8.426-.254-.612-.23-2.034.613-3.285.905-1.343 2.5-2.052 4.61-2.052 1.864 0 3.243.591 4.1 1.755 1.008 1.37 1.062 3.156.867 3.765-.957 2.978-.318 6.059 1.708 8.242.77.831 2.372 2.557 2.372 3.867 0 .807-.19 1.597-.566 2.35-1.315 2.634-.777 5.743 1.37 7.917v.001c1.034 1.048 2.267 2.781 2.267 5.085 0 4.866-5.478 8.824-12.212 8.824-3.12 0-5.963-.858-8.124-2.254-.79 1.022-1.887 2.21-3.366 3.413 2.126 1.559 4.743 2.697 7.65 3.236v14.402c0 .234 1.72.424 3.84.424 2.122 0 3.841-.19 3.841-.424V67.173c7.53-1.396 13.162-6.786 13.162-13.219.001-3.095-1.256-6.005-3.636-8.416Z" />
                <path d="M77.43 46.822c-.82-2.453-2.233-4.443-4.143-5.863 1.596-3.386 1.97-6.976.974-9.955-1.064-3.187-3.52-5.307-6.685-5.919a10.963 10.963 0 0 0-.55-3.173c-1.028-3.075-3.43-5.199-6.5-5.872-.753-4.102-3.083-9.25-8.34-9.25-5.246 0-7.63 5.151-8.428 9.246-3.08.667-5.492 2.793-6.522 5.876a10.93 10.93 0 0 0-.55 3.174c-3.164.612-5.62 2.732-6.685 5.919-.995 2.978-.62 6.57.974 9.955-1.91 1.421-3.323 3.41-4.143 5.863-1.17 3.502-.486 7.376 1.877 10.627 2.183 3.003 7.552 7.838 19.581 8.711v15.395c0 .246 1.72.445 3.841.445 2.121 0 3.841-.199 3.841-.445V66.164c12.036-.86 17.401-5.691 19.58-8.689 2.37-3.255 3.052-7.138 1.877-10.653Zm-5.76 7.868c-3.17 4.362-10.28 6.862-19.507 6.862-9.253 0-16.385-2.51-19.567-6.888-1.473-2.027-1.904-4.279-1.214-6.344.678-2.029 1.987-3.523 3.787-4.319l2.554-1.13-1.515-2.332c-1.769-2.724-2.404-5.803-1.658-8.038.801-2.398 2.762-2.841 4.1-2.841.151 0 .294.006.426.015l3.034.208-.506-2.977c-.218-1.283-.156-2.491.181-3.497.824-2.467 2.917-2.838 4.091-2.84l2.194-.008.19-2.17c.005-.069.741-6.844 3.927-6.844 3.161 0 3.81 6.748 3.815 6.811l.157 2.209 2.23.003c1.174.002 3.266.373 4.091 2.841.337 1.005.4 2.214.181 3.495l-.508 2.978 3.034-.208c1.317-.085 3.636.159 4.527 2.826.746 2.235.111 5.316-1.658 8.038l-1.515 2.332 2.554 1.13c1.8.796 3.11 2.289 3.786 4.317v.001c.695 2.077.265 4.34-1.21 6.37Z" />
                <path d="m26.132 61.41-7.138-1.296-12.577 2.149 6.345-10.501-4.15-2.541 9.896-39.986 2.711 8.656-1.297 2.207 5.254 17.272c-.435-2.698-.262-5.351.548-7.776.325-.972.755-1.88 1.263-2.726l-1.874-6.162.612-1.043a3.098 3.098 0 0 0 .285-2.496L21.32 2.191C20.897.837 19.648-.007 18.226 0a3.106 3.106 0 0 0-2.954 2.372L3.736 48.985a3.464 3.464 0 0 0 1.568 3.798l.915.56-5.71 9.45c-.374.619-.583 1.346-.485 2.062a3.145 3.145 0 0 0 3.656 2.701l11.663-1.992v15.99c0 .246 1.72.445 3.841.445 2.121 0 3.841-.199 3.841-.445V65.682l11.63 2.11c.192.036.385.052.576.052.061 0 .12-.015.181-.019-4.554-1.891-7.482-4.365-9.28-6.415Z" />
            </g>
        </svg>
    );
};

Trees.defaultProps = {
    height: "83px",
    width: "105px",
};

Trees.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
};

export default Trees;
