import styled from "styled-components";

import { minMedia } from "container/global.styles";

const Container = styled.div`
    margin: 0;
    padding: 1px;

    ${minMedia.desktop`
        text-align: center;
    `};
`;

const Column = styled.div`
    margin: 25px 0;
    width: 100%;

    ${minMedia.desktop`
        margin: 70px 0 50px;
        text-align: center;
    `};
`;

const BtnWrapper = styled.div`
    margin: 25px 0;

    ${minMedia.desktop`
        margin: 0 auto 60px;
        width: 350px;
    `};
`;

export default {
    Container,
    Column,
    BtnWrapper,
};
