import styled, { css } from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Container = styled.div`
    background-color: ${(props) => props.theme.backgroundColor};
    display: flex;
    flex-direction: column;
    font-family: ${(props) => props.theme.fontFamily};
    font-weight: ${(props) => props.theme.fontWeight.regular};
    height: 100%;
    justify-content: flex-start;
    width: 100%;

    h1 {
        color: ${Colors.GREY_575757};
        font-size: 30px;
        font-weight: ${(props) => props.theme.fontWeight.regular};
        margin: 20px 0;
        text-decoration: none solid rgb(87, 87, 87);

        &:first-child {
            margin-top: 0;
        }

        ${minMedia.desktop`
              font-size: 50px;
              margin: 40px 0;
          `};
    }

    h2 {
        color: ${Colors.GREY_575757};
        font-size: 24px;
        font-weight: ${(props) => props.theme.fontWeight.regular};
        margin: 20px 0;
        text-decoration: none solid rgb(87, 87, 87);

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        ${minMedia.desktop`
            font-size: 40px;
            margin: 30px 0;
        `};
    }

    h3 {
        color: ${Colors.GREY_575757};
        font-size: 16px;
        font-weight: ${(props) => props.theme.fontWeight.bold};
        margin: 20px 0 0;
        text-decoration: none solid rgb(87, 87, 87);

        &:first-child {
            margin-top: 0;
        }

        ${minMedia.desktop`
            font-size: 20px;
            margin: 30px 0 0;
        `};
    }

    h4 {
        color: ${Colors.GREY_575757};
        font-size: 16px;
        font-weight: ${(props) => props.theme.fontWeight.regular};
        margin: 20px 0 0;
        text-decoration: none solid rgb(87, 87, 87);

        &:first-child {
            margin-top: 0;
        }

        ${minMedia.desktop`
            font-size: 20px;
            margin: 30px 0 0;
        `};
    }

    p {
        color: ${Colors.GREY_575757};
        font-size: 16px;
        font-weight: ${(props) => props.theme.fontWeight.regular};
        margin: 20px 0 0;

        &:first-child {
            margin-top: 0;
        }

        ${minMedia.desktop`
            font-size: 20px;
        `};
    }

    ul {
        color: ${Colors.GREY_575757};
        font-size: 16px;
        font-weight: ${(props) => props.theme.fontWeight.regular};
        padding-inline-start: 30px;

        ${minMedia.desktop`
            font-size: 20px;
        `};
    }
`;

const Content = styled.div`
    flex: 1 1 auto;
`;

const SubTitle = styled.h2`
    color: ${Colors.GREY_575757};
    font-size: 24px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 40px 0;
    text-decoration: none solid rgb(87, 87, 87);

    ${minMedia.desktop`
        font-size: 40px;
    `};
`;

const Paragraph = styled.p`
    color: ${Colors.GREY_575757};
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.regular};

    ${minMedia.desktop`
        font-size: 20px;
    `};
`;

const clickableTextMixin = css`
    background-color: transparent;
    color: ${Colors.BLUE_005EA8};
    cursor: pointer;
    font-size: 16px;
    font-family: ${(props) => props.theme.fontFamily};
    font-weight: ${(props) => props.theme.fontWeight.regular};
    line-height: 20px;
    margin: 0;
    text-decoration: none;

    ${minMedia.desktop`
        font-size: 18px;
        line-height: 22px;
    `};
`;

const TextLink = styled.a`
    ${clickableTextMixin};
`;

const TextButton = styled.button`
    ${clickableTextMixin};
    border: none;
    outline: none;
    padding: 0;
`;

export default {
    Container,
    Content,
    SubTitle,
    Paragraph,
    TextLink,
    TextButton,
};
