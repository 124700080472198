import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Div = styled.div``;

const Frame = styled.div`
    margin: 20px 0;

    ${minMedia.desktop`
        background-color: ${(props) => props.backgroundColor};
        margin: 5px 0;
    `};
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;

    ${minMedia.desktop`
        align-items: center;
        display: table-row;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: ${(props) =>
            props.rtl ? `${props.width}% ${100 - props.width}%` : `${100 - props.width}% ${props.width}%`};
        min-height: ${(props) => props.minHeightDesktop}px;
    `};
`;

const ImageWrapper = styled.div`
    background-color: ${(props) => props.backgroundColor};
    display: flex;
    flex-direction: column;
    height: ${(props) => (props.objectFit === "contain" ? "initial" : "250px")};
    justify-content: center;
    min-height: 200px;
    position: relative;
    width: 100%;

    img {
        position: ${(props) => (props.objectFit === "contain" ? "relative" : "absolute")};
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        object-fit: ${(props) => props.objectFit};
        object-position: center ${(props) => props.objectPosition}%;
    }

    ${minMedia.desktop`
        align-self: stretch;
        height: 100%;
        min-height: ${(props) => props.minHeightDesktop}px;
        transform: translateX(${(props) => (props.rtl ? "30px" : "-30px")});
    `}
`;

const FlagWrapper = styled.div`
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    ${minMedia.desktop`
        height: 550px;
    `};
`;

const Flag = styled.div`
    background-color: transparent;
    max-width: 100%;
    padding: 12px 15px;
    z-index: 10;

    h2 {
        background-color: ${Colors.BLUE_063773};
        box-decoration-break: clone;
        box-shadow: -8px 0 0 8px ${Colors.BLUE_063773}, 8px 0 0 8px ${Colors.BLUE_063773},
            0 0 0 8px ${Colors.BLUE_063773};
        color: ${Colors.WHITE_FFFFFF} !important;
        display: inline;
        font-size: 16px !important;
        font-weight: ${(props) => props.theme.fontWeight.bold} !important;
        hyphens: auto;
        line-height: 49px;
        margin: 0;
        overflow: hidden;
        padding: 0;
    }

    ${minMedia.desktop`
        padding: 20px;

        h2 {
            box-shadow: -12px 0 0 10px ${Colors.BLUE_063773}, 12px 0 0 10px ${Colors.BLUE_063773}, 0 0 0 10px ${Colors.BLUE_063773};
            font-size: 30px !important;
            line-height: 122px;
        }
    `};
`;

const Content = styled.div`
    background-color: ${(props) => props.backgroundColor};
    box-sizing: border-box;
    padding: 20px 15px;
    width: 100%;

    ${minMedia.desktop`
        display: table-cell;
        flex-direction: column;
        flex: 1 1 660px;
        justify-content: center;
        margin: 0;
        padding: 70px 50px;
    `}
`;

const TextContainer = styled.div``;

const Link = styled.div`
    color: ${Colors.BLUE_005EA8};
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 16px 0 0;
    text-decoration: none;

    ${minMedia.desktop`
        font-size: 20px;
        line-height: 27px;
        margin: 20px 0 0;
    `}
`;

export default {
    Div,
    Frame,
    Container,
    ImageWrapper,
    FlagWrapper,
    Flag,
    Content,
    TextContainer,
    Link,
};
