import styled from "styled-components";

const Container = styled.div`
    width: 100%;
`;

const Bar = styled.div`
    align-items: center;
    display: flex;
    position: relative;
`;

const TrackContainer = styled.div`
    position: relative;
    width: 100%;
`;

const Track = styled.ul`
    display: flex;
    list-style: none;
    margin: 0;
    padding: 30px;
    position: absolute;
    transition: left;
`;

export default {
    Container,
    Bar,
    TrackContainer,
    Track,
};
