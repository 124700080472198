import React from "react";
import PropTypes from "prop-types";
import { ContentRow } from "components/content-row/content-row";
import Style from "./youtube-video.styles";
import { EnumHelper } from "helper/enum-helper";

export const YoutubeVideo = ({
    youtubeId,
    heightDesktop,
    widthDesktop,
    heightMobile,
    widthMobile,
    backgroundColor,
}) => {
    return (
        <ContentRow backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
            <Style.Container withColor={backgroundColor !== "white"}>
                <Style.VideoWrapper>
                    <Style.IframeWrapper heightDesktop={heightDesktop} widthDesktop={widthDesktop}
                                         heightMobile={heightMobile} widthMobile={widthMobile}>
                        <iframe src={`https://www.youtube.com/embed/${youtubeId}`}
                                height="100%"
                                width="100%"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen />
                    </Style.IframeWrapper>
                </Style.VideoWrapper>
            </Style.Container>
        </ContentRow>
    );
};

YoutubeVideo.propTypes = {
    youtubeId: PropTypes.string,
    heightDesktop: PropTypes.number,
    widthDesktop: PropTypes.number,
    heightMobile: PropTypes.number,
    widthMobile: PropTypes.number,
    backgroundColor: PropTypes.string,
};

export default YoutubeVideo;
