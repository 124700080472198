import { PropTypes } from "prop-types";

import { ImageShape } from "../image/image.shape";

export const TileShape = {
    title: PropTypes.string.isRequired,
    date: PropTypes.string,
    description: PropTypes.string.isRequired,
    flag: PropTypes.string,
    image: PropTypes.shape(ImageShape).isRequired,
    link: PropTypes.any,
    backgroundColor: PropTypes.string.isRequired,
};

export default TileShape;
