import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Container = styled.div`
    background-color: ${Colors.WHITE_FFFFFF};
    box-sizing: border-box;
    color: ${Colors.GREY_575757};
    display: flex;
    flex-direction: column;
    font-size: 16px;
    hyphens: auto;
    line-height: 20px;
    overflow-y: auto;
    padding: 15px;
    position: relative;
    text-align: left;
    width: 100%;

    ${minMedia.desktop`
        font-size: 20px;
        line-height: 24px;
        min-width: 500px;
        padding: 30px;
        width: 500px;
    `};
`;

const Close = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${Colors.GREY_575757};
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    outline: none;
    padding: 0;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 40px;

    ${minMedia.desktop`
        right: 10px;
        top: 10px;
    `};
`;

const ProjectName = styled.div`
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 30px;
    padding-right: 30px;

    ${minMedia.desktop`
        flex: 0 0 auto;
        padding-right: 35px;
    `};
`;

const List = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 30px;

    ${minMedia.desktop`
        flex: 0 0 auto;
    `};
`;

const ListItem = styled.div`
    column-gap: 20px;
    display: grid;
    grid-template-columns: 2fr 3fr;
    margin-top: 7px;

    &:first-child {
        margin-top: 0;
    }
`;

const Label = styled.div``;

const Value = styled.div``;

const DescriptionContainer = styled.div`
    margin-bottom: 30px;

    ${minMedia.desktop`
        flex: 1 0 auto;
    `};
`;

const DescriptionLabel = styled.div`
    margin-bottom: 7px;
`;

const DescriptionValue = styled.div``;

const Row = styled.div`
    column-gap: 20px;
    display: grid;
    grid-template-columns: 2fr 3fr;

    ${minMedia.desktop`
        flex: 0 0 auto;
    `};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

const IconWrapper = styled.div`
    color: ${Colors.GREEN_92C0B7};
    height: 121px;
    width: 153px;
`;

const CounterLabel = styled.div`
    margin-bottom: 7px;
`;

const CounterValue = styled.div`
    font-size: 86px;
    font-weight: ${(props) => props.theme.fontWeight.semiBold};
    line-height: 68px;
`;

export default {
    Container,
    Close,
    ProjectName,
    List,
    ListItem,
    Label,
    Value,
    DescriptionContainer,
    DescriptionLabel,
    DescriptionValue,
    Row,
    Column,
    IconWrapper,
    CounterLabel,
    CounterValue,
};
