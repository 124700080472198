import styled from "styled-components";

import { Colors } from "container/global.colors";

const Container = styled.div`
    margin: 40px 0 0;
`;

const Label = styled.label`
    box-sizing: border-box;
    display: block;
    margin: 15px 0 0;

    &:first-child {
        margin-top: 20px;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    opacity ${(props) => (props.disabled && "0.5") || "1"};
    position: relative;
`;

const Column = styled.div`
    box-sizing: border-box;
    padding: 0 0 0 45px;
    width: 100%;
`;

const TextField = styled.div`
    align-content: center;
    border: none;
    box-sizing: border-box;
    color: ${Colors.GREY_333333};
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 18px;
    hyphens: auto;
    letter-spacing: 0;
    line-height: 22px;
    vertical-align: middle;
    width: 100%;

    ul {
        margin-bottom: 0;
    }

    li {
        font-size: 18px;
        hyphens: auto;
        letter-spacing: 0;
        line-height: 22px;
        margin-top: 5px;
    }

    &::placeholder {
        color: ${Colors.GREY_999999} !important;
    }

    &:focus {
        outline: none;
    }
`;

const CheckBox = styled.div`
    align-content: center;
    box-sizing: border-box;
    color: ${(props) =>
        (props.hasError && Colors.RED_C82D2D) || (props.isChecked && Colors.BLUE_005EA8) || Colors.GREY_B4B4B4};
    padding: 0 0 0 15px;
    position: absolute;
    vertical-align: middle;
    width: 30px;

    svg {
        left: 0;
        position: absolute;
        top: 0;
    }
`;

const Input = styled.input`
    opacity: 0;
    position: absolute;
`;

export default {
    Container,
    Label,
    Row,
    Column,
    TextField,
    CheckBox,
    Input,
};
