import React, { useContext } from "react";
import PropTypes from "prop-types";

import AccordionComponent from "components/accordion/accordion";
import { ContentRow } from "components/content-row/content-row";

import { MediaContext } from "context/media.context";

import { StatusEnum } from "enumeration/status.enum";

import { EnumHelper } from "helper/enum-helper";

import { Markdown } from "utils/markdown";

import Styles from "./accordion.styles";

export const Accordion = ({ status, content, backgroundColor, panels }) => {
    const media = useContext(MediaContext);

    if (status === StatusEnum.DRAFT) return null;

    const renderAccordionPanels = () => {
        return panels.map((panel, key) => {
            return (
                <AccordionComponent
                    // eslint-disable-next-line react/no-array-index-key
                    key={key}
                    title={panel.title}
                    content={panel.content}
                    isExpanded={panel.isExpanded}
                />
            );
        });
    };

    return (
        <>
            <ContentRow backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
                <Styles.Container withColor={backgroundColor !== "white"}>
                    <Styles.TextContainer>{content && <Markdown>{content}</Markdown>}</Styles.TextContainer>
                </Styles.Container>
            </ContentRow>

            {!media.isDesktop && <Styles.Tile>{renderAccordionPanels()}</Styles.Tile>}

            {media.isDesktop && (
                <ContentRow backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
                    <Styles.TileContainer withColor={backgroundColor !== "white"}>
                        <Styles.Tile>{renderAccordionPanels()}</Styles.Tile>
                    </Styles.TileContainer>
                </ContentRow>
            )}
        </>
    );
};

Accordion.defaultProps = {
    status: null,
    content: null,
    panels: [],
};

Accordion.propTypes = {
    status: PropTypes.string,
    content: PropTypes.any,
    backgroundColor: PropTypes.string.isRequired,
    panels: PropTypes.array,
};

export default Accordion;
