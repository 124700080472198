import React, { useContext } from "react";
import NextLink from "next/link";
import PropTypes from "prop-types";

import { DeviceOutputContext } from "context/device-output.context";

export const Link = ({ href, target, title, children, ...rest }) => {
    const { isApp } = useContext(DeviceOutputContext);

    const trimmedHref = href?.trim();

    const linkTitle = title ?? (children instanceof String ? children : null);
    const linkTarget = isApp === false ? target : null;

    if (isApp === false) {
        if (linkTarget === "popup") {
            const onClickPopup = () => {
                window.open(trimmedHref, "popup", "width=585, height=500");
            };

            return (
                <a href={trimmedHref} title={linkTitle} target="popup" onClick={onClickPopup} {...rest}>
                    {children}
                </a>
            );
        }

        if (linkTarget === "_blank") {
            return (
                <a href={trimmedHref} title={linkTitle} target="_blank" rel="nofollow noreferrer noopener" {...rest}>
                    {children}
                </a>
            );
        }
    }

    if (linkTarget === "_self") {
        return (
            <NextLink href={trimmedHref}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a {...rest}>{children}</a>
            </NextLink>
        );
    }

    return (
        <a href={trimmedHref} title={linkTitle} target={linkTarget ?? null} {...rest}>
            {children}
        </a>
    );
};

Link.defaultProps = {
    target: null,
    title: null,
};

Link.propTypes = {
    href: PropTypes.string.isRequired,
    target: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.any.isRequired,
};

export default Link;
