import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Container = styled.div`
    ${(props) => (props.withColor ? "padding" : "margin")}: 25px 0 25px;

    ${minMedia.desktop`
        text-align: center;
        ${(props) => (props.withColor ? "padding" : "margin")}: 70px 0 70px;
   `};
`;

const TextContainer = styled.div`
    text-align: ${(props) => props.textAlignMobile ?? "left"};
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    ${minMedia.desktop`
        margin-bottom: 30px;
        text-align: ${(props) => props.textAlignDesktop ?? "center"};
    `};
`;

const TileContainer = styled.div`
    ${(props) => (props.withColor ? "padding" : "margin")}: 0 0 70px;
`;

const Tile = styled.div`
    background-color: ${Colors.WHITE_FFFFFF};
    border-radius: 3px;
    box-sizing: border-box;
    color: ${Colors.BLUE_005EA8};
    display: table;
    font-size: 16px;
    max-width: 1600px;
    outline: none;
    text-decoration: none;
    width: 100%;

    &:first-child {
        margin-top: 0;
    }

    ${minMedia.desktop`
        margin: 15px auto 0;
    `};
`;

export default {
    Container,
    TextContainer,
    TileContainer,
    Tile,
};
