import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";

import { Checkbox } from "components/checkbox/checkbox";
import { ContentRow } from "components/content-row/content-row";
import { ErrorMessage } from "components/error-message/error-message";
import { File } from "components/file/file";
import { FileShape } from "components/file/file.shape";
import { FileUpload } from "components/file-upload/file-upload";
import { Form } from "components/form/form";
import { FormButton } from "components/form-button/form-button";
import { Input } from "components/input/input";
import { Modal } from "components/modal/modal";
import { Textarea } from "components/textarea/textarea";

import { Colors } from "container/global.colors";

import { FormDataContext } from "context/form-data.context";

import { FileTypeEnum } from "enumeration/file-type.enum";
import { StatusEnum } from "enumeration/status.enum";

import { usePostApiRequest } from "hook/use-post-api-request";

import ModalContentStyles from "styles/modal-content.styles";

import { Markdown } from "utils/markdown";

import Styles from "./email-form.styles";
import { FieldNameEnum } from "./enumeration/field-name.enum";

export const EmailForm = ({ status, text, file, fileTitle, backgroundColor }) => {
    const [submitting, setSubmitting] = useState(false);
    const [hasSuccess, setHasSuccess] = useState(false);
    const [hasServerError, setHasServerError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { files } = useContext(FormDataContext);
    const fileUploadFormRef = useRef(null);

    const defaultValues = {
        [FieldNameEnum.CLUB]: "",
        [FieldNameEnum.ADDRESS]: "",
        [FieldNameEnum.PHONE]: "",
        [FieldNameEnum.EMAIL]: "",
        [FieldNameEnum.CONTACT]: "",
        [FieldNameEnum.FUNCTION_OF_CONTACT]: "",
        [FieldNameEnum.CLUB_DESCRIPTION]: "",
        [FieldNameEnum.CLUB_LOGO]: [],
        [`${FieldNameEnum.CLUB_LOGO}_count`]: 0,
        [FieldNameEnum.CERTIFICATE_OF_EXEMPTION]: "",
        [`${FieldNameEnum.CERTIFICATE_OF_EXEMPTION}_count`]: 0,
        [FieldNameEnum.SPORTS_ELIGIBILITY]: "",
        [`${FieldNameEnum.SPORTS_ELIGIBILITY}_count`]: 0,
        [FieldNameEnum.PROJECT]: "",
        [FieldNameEnum.PROJECT_IMAGES]: "",
        [`${FieldNameEnum.PROJECT_IMAGES}_count`]: 0,
        [FieldNameEnum.OTHER_DOCUMENTS]: "",
        [`${FieldNameEnum.OTHER_DOCUMENTS}_count`]: 0,
        [FieldNameEnum.GET_TO_KNOW]: "",
        [FieldNameEnum.ACCOUNT_HOLDER]: "",
        [FieldNameEnum.BANK]: "",
        [FieldNameEnum.IBAN]: "",
        [FieldNameEnum.BIC]: "",
        [FieldNameEnum.CONFIRM_CONDITIONS]: false,
        [FieldNameEnum.CONFIRM_COPYRIGHT]: false,
    };

    const validationSchema = yup.object({
        [FieldNameEnum.CLUB]: yup
            .string()
            .max(1000, "Bitte geben Sie maximal 1000 Zeichen ein.")
            .required("Bitte ausfüllen"),
        [FieldNameEnum.ADDRESS]: yup
            .string()
            .max(1000, "Bitte geben Sie maximal 1000 Zeichen ein.")
            .required("Bitte ausfüllen"),
        [FieldNameEnum.PHONE]: yup
            .string()
            .max(1000, "Bitte geben Sie maximal 1000 Zeichen ein.")
            .required("Bitte ausfüllen"),
        [FieldNameEnum.EMAIL]: yup
            .string()
            .email("Bitte geben Sie eine gültige E-Mail Adresse an")
            .required("Bitte ausfüllen"),
        [FieldNameEnum.CONTACT]: yup
            .string()
            .max(1000, "Bitte geben Sie maximal 1000 Zeichen ein.")
            .required("Bitte ausfüllen"),
        [FieldNameEnum.FUNCTION_OF_CONTACT]: yup
            .string()
            .max(1000, "Bitte geben Sie maximal 1000 Zeichen ein.")
            .required("Bitte ausfüllen"),
        [FieldNameEnum.CLUB_DESCRIPTION]: yup
            .string()
            .max(1000, "Bitte geben Sie maximal 1000 Zeichen ein.")
            .required("Bitte ausfüllen"),
        [`${FieldNameEnum.CLUB_LOGO}_count`]: yup
            .number()
            .min(1, "Bitte laden Sie mindestens 1 Dokument hoch.")
            .required(),
        [`${FieldNameEnum.CERTIFICATE_OF_EXEMPTION}_count`]: yup
            .number()
            .min(1, "Bitte laden Sie mindestens 1 Dokument hoch.")
            .required(),
        [`${FieldNameEnum.SPORTS_ELIGIBILITY}_count`]: yup.number().required(),
        [FieldNameEnum.PROJECT]: yup
            .string()
            .max(2500, "Bitte geben Sie maximal 2500 Zeichen ein.")
            .required("Bitte ausfüllen"),
        [`${FieldNameEnum.PROJECT_IMAGES}_count`]: yup
            .number()
            .min(1, "Bitte laden Sie mindestens 1 Dokument hoch.")
            .required(),
        [`${FieldNameEnum.OTHER_DOCUMENTS}_count`]: yup
            .number()
            .min(1, "Bitte laden Sie mindestens 1 Dokument hoch.")
            .required(),
        [FieldNameEnum.GET_TO_KNOW]: yup
            .string()
            .max(1000, "Bitte geben Sie maximal 1000 Zeichen ein.")
            .required("Bitte ausfüllen"),
        [FieldNameEnum.ACCOUNT_HOLDER]: yup
            .string()
            .max(1000, "Bitte geben Sie maximal 1000 Zeichen ein.")
            .required("Bitte ausfüllen"),
        [FieldNameEnum.BANK]: yup
            .string()
            .max(1000, "Bitte geben Sie maximal 1000 Zeichen ein.")
            .required("Bitte ausfüllen"),
        [FieldNameEnum.IBAN]: yup
            .string()
            .max(1000, "Bitte geben Sie maximal 1000 Zeichen ein.")
            .required("Bitte ausfüllen"),
        [FieldNameEnum.BIC]: yup
            .string()
            .max(1000, "Bitte geben Sie maximal 1000 Zeichen ein.")
            .required("Bitte ausfüllen"),
        [FieldNameEnum.CONFIRM_CONDITIONS]: yup
            .boolean()
            .required("Ihre Zustimmung ist erforderlich")
            .oneOf([true], "Ihre Zustimmung ist erforderlich"),
        [FieldNameEnum.CONFIRM_COPYRIGHT]: yup
            .boolean()
            .required("Ihre Zustimmung ist erforderlich")
            .oneOf([true], "Ihre Zustimmung ist erforderlich"),
    });

    const [, post] = usePostApiRequest("/contacts");

    if (status === StatusEnum.DRAFT) return null;

    const onSubmitFileUpload = () => {
        const formData = new FormData();
        const formElements = fileUploadFormRef.current.elements;
        const data = {};

        Object.values(files).forEach((fileList) => {
            Object.values(fileList).forEach((fileItem) => {
                formData.append(`files.${fileItem.fieldName}`, fileItem.file, fileItem.fileName);
            });
        });

        formElements.forEach((element) => {
            if (!["submit", "file", "button", "hidden"].includes(element.type)) {
                if (["checkbox"].includes(element.type)) {
                    data[element.name] = element.value === "on";
                } else {
                    data[element.name] = element.value;
                }
            }
        });

        formData.append("data", JSON.stringify(data));

        if (submitting || hasSuccess) return;

        setSubmitting(true);
        setHasServerError(false);
        setTimeout(() => {
            post(formData)
                .then(() => {
                    setHasSuccess(true);
                    setShowModal(true);
                })
                .catch(() => {
                    setHasServerError(true);
                    setSubmitting(false);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }, 100);
    };

    const onClickDataProtection = () => {
        window.open("https://www.check24.de/popup/datenschutz/", "popup", "width=585, height=500");
    };

    return (
        <>
            <Styles.Frame>
                <ContentRow backgroundColor={Colors.GREEN_E2F1EE}>
                    <Styles.Container withColor={backgroundColor !== "white"}>
                        {text && (
                            <Styles.TextContainer>
                                <Markdown>{text}</Markdown>

                                {file && fileTitle && <File file={file} fileTitle={fileTitle} />}
                            </Styles.TextContainer>
                        )}
                    </Styles.Container>

                    <Form
                        ref={fileUploadFormRef}
                        defaultValues={defaultValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmitFileUpload}
                    >
                        {({ ...formikProps }) => {
                            return (
                                <>
                                    <Styles.Column>
                                        <Styles.SubTitle>Kontaktdaten*</Styles.SubTitle>

                                        <Input name={FieldNameEnum.CLUB} label="Verein:" {...formikProps} />

                                        <Input name={FieldNameEnum.ADDRESS} label="Adresse:" {...formikProps} />

                                        <Input name={FieldNameEnum.PHONE} label="Telefonnummer:" {...formikProps} />

                                        <Input name={FieldNameEnum.EMAIL} label="E-Mail:" {...formikProps} />

                                        <Input name={FieldNameEnum.CONTACT} label="Ansprechpartner:" {...formikProps} />

                                        <Input
                                            name={FieldNameEnum.FUNCTION_OF_CONTACT}
                                            label="Funktion:"
                                            {...formikProps}
                                        />

                                        <Styles.SubTitle>Der Verein*</Styles.SubTitle>

                                        <Textarea
                                            name={FieldNameEnum.CLUB_DESCRIPTION}
                                            label="Vereinsbeschreibung:"
                                            placeholder="Kurze Beschreibung des Aufgabenbereiches und Ziele des Vereins"
                                            maxLength={1000}
                                            {...formikProps}
                                        />

                                        <FileUpload
                                            name={FieldNameEnum.CLUB_LOGO}
                                            label="Vereinslogo:"
                                            validFileMaxSize={2}
                                            validFileTypes={[
                                                FileTypeEnum.JPEG,
                                                FileTypeEnum.JPG,
                                                FileTypeEnum.PNG,
                                                FileTypeEnum.SVG,
                                            ]}
                                            maxNumberOfFilesPerUpload={1}
                                            {...formikProps}
                                        />

                                        <FileUpload
                                            name={FieldNameEnum.CERTIFICATE_OF_EXEMPTION}
                                            label="Freistellungsbescheid:"
                                            validFileMaxSize={2}
                                            validFileTypes={[
                                                FileTypeEnum.JPEG,
                                                FileTypeEnum.JPG,
                                                FileTypeEnum.PNG,
                                                FileTypeEnum.PDF,
                                            ]}
                                            maxNumberOfFilesPerUpload={1}
                                            {...formikProps}
                                        />

                                        <FileUpload
                                            name={FieldNameEnum.SPORTS_ELIGIBILITY}
                                            label="Sportförderfähigkeit:"
                                            validFileMaxSize={2}
                                            validFileTypes={[
                                                FileTypeEnum.JPEG,
                                                FileTypeEnum.JPG,
                                                FileTypeEnum.PNG,
                                                FileTypeEnum.PDF,
                                            ]}
                                            maxNumberOfFilesPerUpload={1}
                                            {...formikProps}
                                        />

                                        <Textarea
                                            name={FieldNameEnum.PROJECT}
                                            label="Projekt:"
                                            placeholder="Beschreibung des speziellen Projektes zur Unterstützung von Kindern und Jugendlichen. Zum Beispiel den Kauf von Sportmaterialien oder Sportkleidung, Durchführung einer speziellen Aktivität oder Förderung eines laufenden Projektes."
                                            maxLength={2500}
                                            {...formikProps}
                                        />

                                        <FileUpload
                                            name={FieldNameEnum.PROJECT_IMAGES}
                                            label="Projektfotos:"
                                            validFileMaxSize={2}
                                            validFileTypes={[FileTypeEnum.JPEG, FileTypeEnum.JPG, FileTypeEnum.PNG]}
                                            maxNumberOfFilesPerUpload={5}
                                            {...formikProps}
                                        />

                                        <FileUpload
                                            name={FieldNameEnum.OTHER_DOCUMENTS}
                                            label="Sonstige Unterlagen:"
                                            validFileMaxSize={2}
                                            validFileTypes={[
                                                FileTypeEnum.JPEG,
                                                FileTypeEnum.JPG,
                                                FileTypeEnum.PNG,
                                                FileTypeEnum.PDF,
                                            ]}
                                            maxNumberOfFilesPerUpload={5}
                                            {...formikProps}
                                        />

                                        <Textarea
                                            name={FieldNameEnum.GET_TO_KNOW}
                                            label="Wie haben Sie von uns erfahren?"
                                            maxLength={1000}
                                            {...formikProps}
                                        />

                                        <Styles.SubTitle>Konto*:</Styles.SubTitle>

                                        <Input
                                            name={FieldNameEnum.ACCOUNT_HOLDER}
                                            label="Kontoinhaber:"
                                            maxLength={1000}
                                            {...formikProps}
                                        />

                                        <Input name={FieldNameEnum.BANK} label="Name der Bank:" {...formikProps} />

                                        <Input name={FieldNameEnum.IBAN} label="IBAN:" {...formikProps} />

                                        <Input name={FieldNameEnum.BIC} label="BIC:" {...formikProps} />

                                        <Checkbox
                                            name={FieldNameEnum.CONFIRM_CONDITIONS}
                                            label={
                                                <span>
                                                    CHECK24 verarbeitet die personenbezogenen Daten (Name, Funktion und
                                                    Kontaktdaten) der hinterlegten Ansprechpersonen ausschließlich zur
                                                    Prüfung der Teilnahmebedingungen und Teilnahmevereinbarung sowie zur
                                                    internen Projektdurchführung. Eine Weitergabe der Daten an Dritte
                                                    erfolgt nur dann, wenn dies zur Durchführung der Spendeninitiative
                                                    erforderlich ist. Die Daten werden gelöscht, wenn sie für den Zweck
                                                    ihrer Erhebung nicht mehr erforderlich sind, soweit keine
                                                    gesetzlichen Aufbewahrungsfristen, die zu einer längeren Speicherung
                                                    verpflichten, entgegenstehen. Im Übrigen gilt die
                                                    Datenschutzerklärung von CHECK24, abrufbar unter{" "}
                                                    <a
                                                        href="https://www.check24.de/popup/datenschutz/"
                                                        target="popup"
                                                        title="Datenschutz"
                                                        onClick={onClickDataProtection}
                                                    >
                                                        https://www.check24.de/popup/datenschutz/
                                                    </a>
                                                    .
                                                </span>
                                            }
                                            {...formikProps}
                                        />

                                        <Checkbox
                                            name={FieldNameEnum.CONFIRM_COPYRIGHT}
                                            label={
                                                <span>
                                                    Hiermit bestätige ich, dass die Rechte an den eingesendeten Texten
                                                    und Fotomotiven vollumfänglich vorliegen. Im Rahmen einer
                                                    Berichterstattung der Spendeninitiative &bdquo;CHECK24 <i>hilft</i>{" "}
                                                    Berliner Vereinen&ldquo; kann das eingereichte Bild- und
                                                    Textmaterial von CHECK24 verwendet werden. Weitere Details hierzu
                                                    finden Sie in unseren Teilnahmebedingungen.
                                                </span>
                                            }
                                            {...formikProps}
                                        />

                                        <Styles.CtaContainer>
                                            <FormButton
                                                btnText="jetzt senden"
                                                disabled={hasSuccess}
                                                isSpinning={submitting}
                                            />

                                            <Styles.Hint>Mit * gekennzeichnete Felder sind Pflichtfelder</Styles.Hint>
                                        </Styles.CtaContainer>

                                        <Styles.ServerErrorWrapper>
                                            {hasServerError && (
                                                <ErrorMessage>
                                                    Hier ist etwas schief gelaufen. Bitte versuchen Sie es erneut.
                                                </ErrorMessage>
                                            )}
                                        </Styles.ServerErrorWrapper>
                                    </Styles.Column>
                                </>
                            );
                        }}
                    </Form>
                </ContentRow>
            </Styles.Frame>

            {showModal && (
                <Modal width="825px" onClose={() => setShowModal(false)}>
                    <ModalContentStyles.Container>
                        <h2>Vielen Dank für die Teilnahme!</h2>

                        <p>
                            Unser Gremium prüft Ihre Unterlagen. Im Oktober werden wir die Gewinnervereine ermitteln.
                            Wir drücken Ihnen und Ihrem Verein die Daumen.
                        </p>

                        <p>
                            Für Fragen stehen wir Ihnen gerne unter{" "}
                            <a
                                href="mailto:check24hilft@check24.de?subject=Bewerbung%20CHECK24%20hilft%20Berliner%20Vereinen"
                                title="check24hilft@check24.de"
                            >
                                check24hilft@check24.de
                            </a>{" "}
                            zur Verfügung.
                        </p>
                    </ModalContentStyles.Container>
                </Modal>
            )}

            <Styles.Frame>
                <ContentRow backgroundColor={Colors.YELLOW_FFECB9}>
                    <Styles.Container withColor>
                        <Styles.TextContainer>
                            <h2>Wer entscheidet?</h2>

                            <p>Über die Vergabe der Preisgelder entscheidet ein neutrales Gremium aus:</p>

                            <Styles.List>
                                <Styles.EntryContainer>
                                    <Styles.TextWrapper>
                                        <h3>Politik:</h3>

                                        <p>
                                            Steffen Sambill (Stellv. Fraktionsvorsitzender, Vorsitzender Ausschuss
                                            Schule, Mitglied Ausschuss Sport)
                                        </p>
                                    </Styles.TextWrapper>
                                </Styles.EntryContainer>

                                <Styles.EntryContainer>
                                    <Styles.TextWrapper>
                                        <h3>Sport:</h3>

                                        <p>
                                            David Kozlowski (Leiter Stabsstelle Sportinfrastruktur / Umwelt
                                            Landessportbund Berlin) <br />
                                            Frank Wendler (Vorsitzender des Bezirkssportbundes Treptow-Köpenick)
                                        </p>
                                    </Styles.TextWrapper>
                                </Styles.EntryContainer>

                                <Styles.EntryContainer>
                                    <Styles.TextWrapper>
                                        <h3>CHECK24 Unternehmen:</h3>

                                        <p>Dagmar Ginzel (Chief Communications Officer)</p>
                                    </Styles.TextWrapper>
                                </Styles.EntryContainer>

                                <Styles.EntryContainer>
                                    <Styles.TextWrapper>
                                        <h3>sowie eine Kinderjury</h3>
                                    </Styles.TextWrapper>
                                </Styles.EntryContainer>
                            </Styles.List>
                        </Styles.TextContainer>
                    </Styles.Container>
                </ContentRow>
            </Styles.Frame>

            <ContentRow>
                <Styles.Container />
            </ContentRow>
        </>
    );
};

EmailForm.defaultProps = {
    status: null,
    file: null,
    fileTitle: null,
    linkTitle: null,
    linkUrl: null,
    externalLink: false,
    primaryButton: false,
    withHeart: false,
};

EmailForm.propTypes = {
    status: PropTypes.string,
    text: PropTypes.string.isRequired,
    file: PropTypes.shape(FileShape),
    fileTitle: PropTypes.string,
    backgroundColor: PropTypes.string.isRequired,
    linkTitle: PropTypes.string,
    linkUrl: PropTypes.string,
    externalLink: PropTypes.bool,
    primaryButton: PropTypes.bool,
    withHeart: PropTypes.bool,
};

export default EmailForm;
