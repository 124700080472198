import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { Markdown } from "utils/markdown";

import Styles from "./accordion.styles";

export const Accordion = ({ title, content, isExpanded }) => {
    const [isOpen, setIsOpen] = useState(isExpanded);
    const [contentHeight, setContentHeight] = useState(0);
    const textContainerRef = useRef(null);

    const toggleAccordionExpansion = () => {
        setIsOpen(!isOpen);
    };

    const updateContentHeight = () => {
        if (textContainerRef.current) {
            setContentHeight(textContainerRef.current.getBoundingClientRect().height);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            updateContentHeight();
        }, 500);

        window.addEventListener("resize", updateContentHeight);

        return () => {
            window.removeEventListener("resize", updateContentHeight);
        };
    }, [textContainerRef]);

    return (
        <Styles.TileSection>
            <Styles.TitleBar onClick={toggleAccordionExpansion}>
                <Styles.IconWrapper>
                    <Styles.Icon isopen={isOpen.toString()} />
                </Styles.IconWrapper>

                <Styles.Title>
                    <Markdown>{title}</Markdown>
                </Styles.Title>
            </Styles.TitleBar>

            <Styles.Content style={{ height: `${isOpen ? contentHeight : 0}px` }}>
                <Styles.TextContainer ref={textContainerRef}>
                    {content && <Markdown>{content}</Markdown>}
                </Styles.TextContainer>
            </Styles.Content>
        </Styles.TileSection>
    );
};

Accordion.defaultProps = {
    title: null,
    content: null,
    isExpanded: false,
};

Accordion.propTypes = {
    title: PropTypes.string,
    content: PropTypes.any,
    isExpanded: PropTypes.bool,
};

export default Accordion;
