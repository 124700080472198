import React, { useEffect, useState } from "react";
import { readString } from "react-papaparse";
import PropTypes from "prop-types";
import useSWR from "swr";

import { ContentRow } from "components/content-row/content-row";
import { Map as MapComponent } from "components/map/map";

import { StatusEnum } from "enumeration/status.enum";

import { EnumHelper } from "helper/enum-helper";

import { getStrapiURL } from "lib/api";

import Styles from "sections/accordion/accordion.styles";

import { Markdown } from "utils/markdown";

export const Map = ({ status, content, backgroundColor, locations, children }) => {
    const [locationsJson, setLocationsJson] = useState(null);

    const fetcher = (url) => fetch(getStrapiURL(url)).then((res) => res.text());
    const { data, error } = useSWR(locations.url, fetcher);

    useEffect(() => {
        if (data && !error) {
            setLocationsJson(
                readString(data, {
                    header: true,
                })
            );
        }
    }, [data, error]);

    if (status === StatusEnum.DRAFT) return null;

    return (
        <ContentRow backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
            <Styles.Container withColor={backgroundColor !== "white"}>
                <Styles.TextContainer>{content && <Markdown>{content}</Markdown>}</Styles.TextContainer>

                <MapComponent locationsJson={locationsJson}>{children}</MapComponent>
            </Styles.Container>
        </ContentRow>
    );
};

Map.defaultProps = {
    status: null,
    content: null,
    children: null,
};

Map.propTypes = {
    status: PropTypes.string,
    content: PropTypes.any,
    backgroundColor: PropTypes.string.isRequired,
    locations: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default Map;
