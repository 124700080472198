import React, { useContext } from "react";
import compareDesc from "date-fns/compareDesc";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import NextLink from "next/link";
import PropTypes from "prop-types";

import { ContentRow } from "components/content-row/content-row";
import { Image } from "components/image/image";

import { MediaContext } from "context/media.context";

import { StatusEnum } from "enumeration/status.enum";

import { EnumHelper } from "helper/enum-helper";

import { Markdown } from "utils/markdown";

import Styles from "./project-field.styles";

export const ProjectField = ({
    status,
    articles,
    text,
    textAlignMobile,
    textAlignDesktop,
    rtl,
    minHeightDesktop,
    textWidthDesktop,
    backgroundColor,
    imageBackgroundColor,
    imageFit,
    category,
}) => {
    const media = useContext(MediaContext);

    if (status === StatusEnum.DRAFT) return null;

    const article = [...articles]
        .filter((item) => item.category.slug === category.slug)
        .sort((a, b) => compareDesc(parseISO(a.publishedAt), parseISO(b.publishedAt)))
        .shift();

    const { teaser, publishedAt } = article;
    const { title, description, image, imagePosition } = teaser;

    return (
        <>
            {text && (
                <ContentRow>
                    <Styles.StandardTextContainer textAlignMobile={textAlignMobile} textAlignDesktop={textAlignDesktop}>
                        <Markdown>{text}</Markdown>
                    </Styles.StandardTextContainer>
                </ContentRow>
            )}

            <NextLink as={`/article/${article.slug}`} href="/article/[slug]" key={`article__${article.slug}`}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>
                    <Styles.Frame backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
                        <ContentRow backgroundColor="transparent">
                            <Styles.Container rtl={rtl} minHeightDesktop={minHeightDesktop} width={textWidthDesktop}>
                                {(rtl && media.isDesktop && (
                                    <>
                                        <Styles.Content
                                            backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}
                                        >
                                            <Styles.TextContainer>
                                                <Styles.SubTitle>
                                                    <Markdown>{title}</Markdown>
                                                </Styles.SubTitle>

                                                {publishedAt && (
                                                    <Styles.Date>
                                                        {format(parseISO(publishedAt), "dd.MM.yyyy")}
                                                    </Styles.Date>
                                                )}

                                                <Styles.Paragraph>
                                                    <Markdown>{description}</Markdown>
                                                </Styles.Paragraph>
                                            </Styles.TextContainer>

                                            <Styles.Link>mehr erfahren</Styles.Link>
                                        </Styles.Content>

                                        <Styles.ImageWrapper
                                            minHeightDesktop={minHeightDesktop}
                                            width={100 - textWidthDesktop}
                                            rtl
                                            backgroundColor={EnumHelper.mapBackgroundColor(
                                                imageBackgroundColor || backgroundColor
                                            )}
                                            objectFit={imageFit}
                                            objectPosition={imagePosition}
                                        >
                                            {image && image.url && <Image image={image} />}
                                        </Styles.ImageWrapper>
                                    </>
                                )) || (
                                    <>
                                        <Styles.ImageWrapper
                                            minHeightDesktop={minHeightDesktop}
                                            width={100 - textWidthDesktop}
                                            backgroundColor={EnumHelper.mapBackgroundColor(
                                                imageBackgroundColor || backgroundColor
                                            )}
                                            objectFit={imageFit}
                                            objectPosition={imagePosition}
                                        >
                                            {image && image.url && <Image image={image} />}
                                        </Styles.ImageWrapper>

                                        <Styles.Content
                                            backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}
                                        >
                                            <Styles.TextContainer>
                                                <Styles.SubTitle>
                                                    <Markdown>{title}</Markdown>
                                                </Styles.SubTitle>

                                                {publishedAt && (
                                                    <Styles.Date>
                                                        {format(parseISO(publishedAt), "dd.MM.yyyy")}
                                                    </Styles.Date>
                                                )}

                                                <Styles.Paragraph>
                                                    <Markdown>{description}</Markdown>
                                                </Styles.Paragraph>
                                            </Styles.TextContainer>

                                            <Styles.Link>mehr erfahren</Styles.Link>
                                        </Styles.Content>
                                    </>
                                )}
                            </Styles.Container>
                        </ContentRow>
                    </Styles.Frame>
                </a>
            </NextLink>
        </>
    );
};

ProjectField.defaultProps = {
    status: null,
    textAlignMobile: "left",
    textAlignDesktop: "center",
    // link: null,
    imageBackgroundColor: "white",
    imageFit: "cover",
    // imagePosition: "center center",
};

ProjectField.propTypes = {
    status: PropTypes.string,
    articles: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired,
    textAlignMobile: PropTypes.string,
    textAlignDesktop: PropTypes.string,
    // link: PropTypes.any,
    rtl: PropTypes.bool.isRequired,
    minHeightDesktop: PropTypes.number.isRequired,
    textWidthDesktop: PropTypes.number.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    imageBackgroundColor: PropTypes.string,
    imageFit: PropTypes.string,
    // imagePosition: PropTypes.string,
    category: PropTypes.any.isRequired,
};

export default ProjectField;
