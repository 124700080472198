import React from "react";
import PropTypes from "prop-types";

import { Colors } from "container/global.colors";

export const LeftQuotationMark = ({ height, width, ...rest }) => {
    return (
        <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 34" {...rest}>
            <path
                d="M34.125 14.571H29.25V12.75c0-2.345 1.912-4.25 4.266-4.25h1.218a3.04 3.04 0 003.047-3.036V3.036A3.04 3.04 0 0034.734 0h-1.218c-7.054 0-12.797 5.722-12.797 12.75v16.393c0 2.679 2.186 4.857 4.875 4.857h8.531C36.814 34 39 31.822 39 29.143v-9.714c0-2.68-2.186-4.858-4.875-4.858zm2.437 14.572a2.43 2.43 0 01-2.437 2.428h-8.531a2.43 2.43 0 01-2.438-2.428V12.75c0-5.7 4.64-10.321 10.36-10.321h1.218a.61.61 0 01.61.607v2.428a.61.61 0 01-.61.607h-1.218c-3.702 0-6.703 2.99-6.703 6.679V17h7.312a2.43 2.43 0 012.438 2.429zM13.406 14.57H8.531V12.75c0-2.345 1.912-4.25 4.266-4.25h1.219a3.04 3.04 0 003.046-3.036V3.036A3.04 3.04 0 0014.016 0h-1.22C5.744 0 0 5.722 0 12.75v16.393C0 31.822 2.186 34 4.875 34h8.531c2.69 0 4.875-2.178 4.875-4.857v-9.714c0-2.68-2.186-4.858-4.875-4.858zm2.438 14.572a2.43 2.43 0 01-2.438 2.428H4.875a2.43 2.43 0 01-2.438-2.428V12.75c0-5.7 4.64-10.321 10.36-10.321h1.219a.61.61 0 01.609.607v2.428a.61.61 0 01-.61.607h-1.218c-3.702 0-6.703 2.99-6.703 6.679V17h7.312a2.43 2.43 0 012.438 2.429z"
                fill="currentColor"
            />
        </svg>
    );
};

LeftQuotationMark.defaultProps = {
    color: Colors.BLUE_7EB6DE,
    height: "20px",
    width: "20px",
};

LeftQuotationMark.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default LeftQuotationMark;
