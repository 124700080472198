import React from "react";
import PropTypes from "prop-types";

import { getStrapiMedia } from "lib/media";

import { ImageShape } from "./image.shape";

export const Image = ({ image, style }) => {
    const imageUrl = getStrapiMedia(image);

    // eslint-disable-next-line @next/next/no-img-element
    return <img src={imageUrl} alt={image.alternativeText || image.name} style={style} />;
};

Image.defaultProps = {
    style: null,
};

Image.propTypes = {
    image: PropTypes.shape(ImageShape).isRequired,
    style: PropTypes.any,
};

export default Image;
