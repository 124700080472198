import React from "react";
import PropTypes from "prop-types";

import { Colors } from "container/global.colors";

export const RightQuotationMark = ({ height, width, ...rest }) => {
    return (
        <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111 100" {...rest}>
            <path
                fill="currentColor"
                d="M13.875 57.143H27.75V62.5c0 6.897-5.442 12.5-12.14 12.5h-3.47c-4.79 0-8.671 3.996-8.671 8.929v7.142c0 4.933 3.88 8.929 8.672 8.929h3.468c20.076 0 36.422-16.83 36.422-37.5V14.286C52.031 6.406 45.81 0 38.156 0H13.875C6.222 0 0 6.406 0 14.286v28.571c0 7.88 6.222 14.286 13.875 14.286zM6.937 14.286c0-3.951 3.1-7.143 6.938-7.143h24.281c3.838 0 6.938 3.192 6.938 7.143V62.5c0 16.763-13.203 30.357-29.485 30.357h-3.468c-.954 0-1.735-.803-1.735-1.786V83.93c0-.983.78-1.786 1.735-1.786h3.468c10.537 0 19.079-8.795 19.079-19.643V50H13.875c-3.837 0-6.937-3.192-6.937-7.143zm65.907 42.857h13.875V62.5c0 6.897-5.442 12.5-12.14 12.5h-3.47c-4.79 0-8.671 3.996-8.671 8.929v7.142c0 4.933 3.88 8.929 8.671 8.929h3.47C94.653 100 111 83.17 111 62.5V14.286C111 6.406 104.778 0 97.125 0H72.844C65.19 0 58.969 6.406 58.969 14.286v28.571c0 7.88 6.222 14.286 13.875 14.286zm-6.938-42.857c0-3.951 3.1-7.143 6.938-7.143h24.281c3.837 0 6.937 3.192 6.937 7.143V62.5c0 16.763-13.202 30.357-29.484 30.357H71.11c-.954 0-1.734-.803-1.734-1.786V83.93c0-.983.78-1.786 1.734-1.786h3.47c10.535 0 19.077-8.795 19.077-19.643V50H72.844c-3.838 0-6.938-3.192-6.938-7.143z"
            />
        </svg>
    );
};

RightQuotationMark.defaultProps = {
    color: Colors.BLUE_7EB6DE,
    height: "34px",
    width: "39px",
};

RightQuotationMark.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default RightQuotationMark;
