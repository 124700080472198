import React, { Fragment, useContext, useState } from "react";
import { compareDesc, format, parseISO } from "date-fns";
import NextLink from "next/link";
import PropTypes from "prop-types";

import { Carousel } from "components/carousel/carousel";
import { ContentRow } from "components/content-row/content-row";
import { Tile } from "components/tile/tile";

import { MediaContext } from "context/media.context";

import { StatusEnum } from "enumeration/status.enum";

import { EnumHelper } from "helper/enum-helper";

import { Markdown } from "utils/markdown";

import Styles from "./news.styles";

const TileList = ({ tiles, slidesPerPageMobile }) => {
    const [numberOfTiles, setNumberOfTiles] = useState(slidesPerPageMobile);

    const showMore = () => {
        setNumberOfTiles(numberOfTiles + 3);
    };

    return (
        <>
            {tiles.slice(0, numberOfTiles).map((Component, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={i}>{Component}</Fragment>
            ))}

            {tiles.length > numberOfTiles && (
                <Styles.More type="button" onClick={showMore}>
                    mehr anzeigen
                </Styles.More>
            )}
        </>
    );
};

TileList.propTypes = {
    tiles: PropTypes.array.isRequired,
    slidesPerPageMobile: PropTypes.number.isRequired,
};

export const News = ({
                         status,
                         articles,
                         text,
                         slidesPerPageMobile,
                         slidesPerPageDesktop,
                         backgroundColor,
                         category,
                         tileBackgroundColor
                     }) => {
    const OFFSET = 10;

    const media = useContext(MediaContext);

    if (status === StatusEnum.DRAFT) return null;

    articles.sort((a, b) => compareDesc(parseISO(a.publishedAt), parseISO(b.publishedAt)));

    if (category) {
        articles = articles.filter(article => category.id === article.category.id)
    }

    const tiles = articles.map((article) => {
        const { teaser, publishedAt } = article;
        if (!teaser) return;
        const { title, description, image, flag, backgroundColor } = teaser;

        if (!tileBackgroundColor) {
            tileBackgroundColor = backgroundColor
        }

        // eslint-disable-next-line consistent-return
        return (
            <NextLink as={`/article/${article.slug}`} href="/article/[slug]" key={`article__${article.slug}`}>
                <Styles.TileWrapper>
                    <Tile
                        title={title}
                        date={format(parseISO(publishedAt), "dd.MM.yyyy")}
                        description={description}
                        image={image}
                        flag={flag}
                        link="mehr erfahren"
                        backgroundColor={tileBackgroundColor}
                    />
                </Styles.TileWrapper>
            </NextLink>
        );
    });

    const needsSliding = tiles.length > slidesPerPageDesktop;

    return (
        <ContentRow backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
            <Styles.Container withColor={backgroundColor !== "white"}>
                <Styles.TextContainer>{text && <Markdown>{text}</Markdown>}</Styles.TextContainer>

                {media.isDesktop && (
                    <Styles.CarouselContainer>
                        <Carousel
                            slidesPerPage={slidesPerPageDesktop > tiles.length ? tiles.length : slidesPerPageDesktop}
                            arrows={needsSliding}
                            draggable={needsSliding}
                            infinite={needsSliding}
                            offset={OFFSET}
                        >
                            {tiles}
                        </Carousel>
                    </Styles.CarouselContainer>
                )}

                {/* eslint-disable-next-line react/no-array-index-key */}
                {!media.isDesktop && <TileList tiles={tiles} slidesPerPageMobile={slidesPerPageMobile}/>}
            </Styles.Container>
        </ContentRow>
    );
};

News.defaultProps = {
    status: null,
};

News.propTypes = {
    status: PropTypes.string,
    articles: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired,
    slidesPerPageMobile: PropTypes.number.isRequired,
    slidesPerPageDesktop: PropTypes.number.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    category: PropTypes.object,
    tileBackgroundColor: PropTypes.string,
};

export default News;
