import styled, { css } from "styled-components";

const Item = styled.li`
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 15px;

    ${(props) =>
        props.clickable &&
        css`
            cursor: pointer;
        `}

    ${(props) => props.active && css``}
`;

export default {
    Item,
};
