import React from "react";
import PropTypes from "prop-types";

import { Colors } from "container/global.colors";

export const Youtube = ({ height, width, ...rest }) => {
    return (
        <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...rest}>
            <path
                fill="currentColor"
                d="M46.6 66.9c-.2.3-.5.5-.8.7s-.5.3-.7.3-.4-.1-.5-.2c-.1-.2-.2-.4-.2-.6v-9.7h-2.5V68c0 .8.1 1.3.4 1.7.3.4.8.6 1.3.6s.9-.1 1.4-.4 1-.6 1.4-1.2v1.4h2.5V57.5h-2.5v9.4h.2zM32.1 55.3h3v14.6h2.8V55.3h3v-2.5h-8.8zM55.8 57.1c-.4 0-.8.1-1.2.3s-.8.5-1.1.9v-5.5H51v17.1h2.5v-1c.3.4.7.7 1.1.9.4.2.8.3 1.3.3.8 0 1.3-.2 1.7-.7s.6-1.2.6-2.1v-7c0-1-.2-1.8-.6-2.3-.4-.6-1-.9-1.8-.9zm-.1 9.8c0 .4-.1.7-.2.9-.1.2-.4.3-.7.3-.2 0-.4-.1-.6-.1-.2-.1-.4-.3-.6-.5v-7.8c.2-.2.4-.3.5-.4.2-.1.4-.1.5-.1.3 0 .6.1.8.3s.3.5.3 1v6.4zM49.6 43.2c.4 0 .7-.1.9-.3s.3-.5.3-.9v-7.6c0-.3-.1-.6-.3-.7-.2-.2-.6-.3-.9-.3s-.6.1-.8.3-.3.4-.3.7V42c0 .4.1.7.3.9.1.2.4.3.8.3z"
            />

            <path
                fill="currentColor"
                d="M50 0C22.4 0 0 22.4 0 50s22.4 50 50 50 50-22.4 50-50S77.6 0 50 0zm6 31.3h2.8v10.6c0 .3.1.6.2.7s.3.2.6.2c.2 0 .5-.1.8-.3s.6-.5.9-.8V31.2h2.8v13.9h-2.8v-1.5c-.5.6-1.1 1-1.6 1.3-.6.3-1.1.4-1.7.4s-1.1-.2-1.5-.6c-.3-.4-.5-1-.5-1.9V31.3zm-10.5 3.2c0-1.1.4-1.9 1.1-2.6.8-.6 1.8-1 3.1-1 1.2 0 2.1.3 2.9 1 .7.7 1.1 1.5 1.1 2.6v7.2c0 1.2-.4 2.1-1.1 2.8-.7.7-1.8 1-3 1s-2.2-.4-3-1.1c-.7-.7-1.1-1.6-1.1-2.8v-7.1zm-7.7-8.1l2.1 7.5h.2l2-7.5h3.2l-3.7 11v7.8h-3.2v-7.4l-3.8-11.4h3.2zm39.8 39.3c0 4.3-3.6 7.9-7.9 7.9H30.2c-4.3 0-7.9-3.5-7.9-7.9v-8.1c0-4.3 3.6-7.9 7.9-7.9h39.5c4.3 0 7.9 3.6 7.9 7.9v8.1z"
            />

            <path
                fill="currentColor"
                d="M67 63.9v-3.2c0-1.2-.3-2.1-.9-2.7s-1.5-1-2.7-1c-1.1 0-2.1.4-2.7 1-.7.7-1.1 1.6-1.1 2.7v5.6c0 1.2.3 2.2 1 2.9.6.7 1.5 1 2.7 1s2.2-.3 2.8-1c.6-.6 1-1.6 1-2.9v-.6h-2.6v.6c0 .7-.1 1.2-.2 1.4-.2.2-.4.3-.9.3-.4 0-.7-.1-.9-.4s-.2-.7-.2-1.4v-2.3H67zm-4.8-3.3c0-.5.1-.9.2-1.1.2-.2.5-.4.9-.4s.7.1.8.4c.2.2.2.6.2 1.1v1.3h-2.2l.1-1.3z"
            />
        </svg>
    );
};

Youtube.defaultProps = {
    color: Colors.WHITE_FFFFFF,
    height: "20px",
    width: "20px",
};

Youtube.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default Youtube;
