import React from "react";
import PropTypes from "prop-types";

import { Button } from "components/button/button";
import { ContentRow } from "components/content-row/content-row";

import { StatusEnum } from "enumeration/status.enum";

import { EnumHelper } from "helper/enum-helper";

import { Markdown } from "utils/markdown";

import Styles from "./standard-text.styles";

export const StandardText = ({
    status,
    text,
    textAlignMobile,
    textAlignDesktop,
    backgroundColor,
    linkUrl,
    linkTitle,
    externalLink,
    primaryButton,
    withHeart,
}) => {
    if (status === StatusEnum.DRAFT) return null;

    return (
        <ContentRow backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
            <Styles.Container withColor={backgroundColor !== "white"}>
                {text && (
                    <Styles.TextContainer textAlignMobile={textAlignMobile} textAlignDesktop={textAlignDesktop}>
                        <Markdown>{text}</Markdown>
                    </Styles.TextContainer>
                )}

                {linkUrl && linkTitle && (
                    <Styles.BtnWrapper>
                        <Button
                            linkTitle={linkTitle}
                            linkUrl={linkUrl}
                            externalLink={externalLink}
                            primary={primaryButton}
                            withHeart={withHeart}
                        />
                    </Styles.BtnWrapper>
                )}
            </Styles.Container>
        </ContentRow>
    );
};

StandardText.defaultProps = {
    status: null,
    linkTitle: null,
    linkUrl: null,
    externalLink: false,
    primaryButton: false,
    withHeart: false,
};

StandardText.propTypes = {
    status: PropTypes.string,
    text: PropTypes.string.isRequired,
    textAlignMobile: PropTypes.string.isRequired,
    textAlignDesktop: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    linkTitle: PropTypes.string,
    linkUrl: PropTypes.string,
    externalLink: PropTypes.bool,
    primaryButton: PropTypes.bool,
    withHeart: PropTypes.bool,
};

export default StandardText;
