import React from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";

import { Colors } from "container/global.colors";

const inTop = (height) => keyframes`
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(${(height * 3) / 7}px) rotate(0deg);
    }
    100% {
        transform: translateY(${(height * 3) / 7}px) rotate(135deg);
    }
`;

const outTop = (height) => keyframes`
    50% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(${(height * 3) / 7}px) rotate(0deg);
    }
    100% {
        transform: translateY(${(height * 3) / 7}px) rotate(135deg);
    }
`;

const inMiddle = keyframes`
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(45deg);
    }
`;

const outMiddle = keyframes`
    50% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(45deg);
    }
`;

const inBottom = (height) => keyframes`
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(-${(height * 3) / 7}px) rotate(0deg);
    }
    100% {
        transform: translateY(-${(height * 3) / 7}px) rotate(135deg);
    }
`;

const outBottom = (height) => keyframes`
    50% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(-${(height * 3) / 7}px) rotate(0deg);
    }
    100% {
        transform: translateY(-${(height * 3) / 7}px) rotate(135deg);
    }
`;

const Top = styled.div`
    animation: ${(props) => outTop(props.height)} 0.4s backwards;
    animation-direction: reverse;
    background-color: ${(props) => props.color};
    border-radius: ${(props) => props.height / 14}px;
    content: "";
    height: ${(props) => props.height / 7}px;
    width: 100%;
`;

const Middle = styled.div`
    animation: ${outMiddle} 0.4s backwards;
    animation-direction: reverse;
    background-color: ${(props) => props.color};
    border-radius: ${(props) => props.height / 14}px;
    content: "";
    height: ${(props) => props.height / 7}px;
    margin: ${(props) => (props.height * 2) / 7}px 0;
    width: 100%;
`;

const Bottom = styled.div`
    animation: ${(props) => outBottom(props.height)} 0.4s backwards;
    animation-direction: reverse;
    background-color: ${(props) => props.color};
    border-radius: ${(props) => props.height / 14}px;
    content: "";
    height: ${(props) => props.height / 7}px;
    width: 100%;
`;

const NavTrigger = styled.div`
    cursor: pointer;
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;

    ${(props) =>
        props.isActive &&
        css`
            ${Top} {
                animation: ${inTop(props.height)} 0.4s forwards;
            }

            ${Middle} {
                animation: ${inMiddle} 0.4s forwards;
            }

            ${Bottom} {
                animation: ${inBottom(props.height)} 0.4s forwards;
            }
        `}
`;

export const NavigationBurger = ({ height, width, color, isActive, ...rest }) => {
    return (
        <NavTrigger height={height} width={width} isActive={isActive} {...rest}>
            <Top height={height} color={color} />

            <Middle height={height} color={color} />

            <Bottom height={height} color={color} />
        </NavTrigger>
    );
};

NavigationBurger.defaultProps = {
    color: Colors.WHITE_FFFFFF,
    height: 25,
    isActive: false,
    width: 35,
};

NavigationBurger.propTypes = {
    color: PropTypes.string,
    height: PropTypes.number,
    isActive: PropTypes.bool,
    width: PropTypes.number,
};

export default NavigationBurger;
