import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Button } from "components/button/button";
import { ContentRow } from "components/content-row/content-row";

import { GlobalContext } from "context/global.context";

import { StatusEnum } from "enumeration/status.enum";

import { EnumHelper } from "helper/enum-helper";
import { NumberHelper } from "helper/number-helper";

import { Donate } from "icons/donate";

import { Markdown } from "utils/markdown";

import Styles from "./thanks.styles";

export const Thanks = ({
    status,
    text,
    textAlignMobile,
    textAlignDesktop,
    caption,
    backgroundColor,
    linkUrl,
    linkTitle,
    externalLink,
    primaryButton,
    withHeart,
}) => {
    const { donationAmountUserValue, donationAmountC24Value, employeeEngagementValue, fundingProgrammeValue } =
        useContext(GlobalContext);
    const totalValue =
        donationAmountUserValue + donationAmountC24Value + fundingProgrammeValue + employeeEngagementValue;

    if (status === StatusEnum.DRAFT) return null;

    return (
        <ContentRow backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
            <Styles.Container withColor={backgroundColor !== "white"}>
                {text && (
                    <Styles.TextContainer textAlignMobile={textAlignMobile} textAlignDesktop={textAlignDesktop}>
                        <Markdown>{text}</Markdown>
                    </Styles.TextContainer>
                )}

                <Styles.CounterContainer>
                    <Styles.IconWrapper>
                        <Donate height="130px" width="130px" />
                    </Styles.IconWrapper>

                    <Styles.Counter>{NumberHelper.formatCurrencyAmount(totalValue)}</Styles.Counter>

                    <Styles.Caption>{caption}</Styles.Caption>
                </Styles.CounterContainer>

                {linkUrl && linkTitle && (
                    <Styles.BtnWrapper>
                        <Button
                            linkTitle={linkTitle}
                            linkUrl={linkUrl}
                            externalLink={externalLink}
                            primary={primaryButton}
                            withHeart={withHeart}
                        />
                    </Styles.BtnWrapper>
                )}
            </Styles.Container>
        </ContentRow>
    );
};

Thanks.defaultProps = {
    status: null,
    linkUrl: null,
    linkTitle: null,
    externalLink: false,
    primaryButton: false,
    withHeart: false,
};

Thanks.propTypes = {
    status: PropTypes.string,
    text: PropTypes.string.isRequired,
    textAlignMobile: PropTypes.string.isRequired,
    textAlignDesktop: PropTypes.string.isRequired,
    linkUrl: PropTypes.string,
    linkTitle: PropTypes.string,
    externalLink: PropTypes.bool,
    primaryButton: PropTypes.bool,
    withHeart: PropTypes.bool,
    caption: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
};

export default Thanks;
