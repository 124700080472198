import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Container = styled.div`
    background-color: ${Colors.WHITE_FFFFFF};
    border: none;
    border-radius: 3px 3px 0 0;
    box-shadow: ${(props) =>
        (props.hasError && `inset 0 -2px 0 0 ${Colors.RED_C82D2D}`) ||
        (props.hasFocus && `inset 0 -2px 0 0 ${Colors.BLUE_005EA8}`) ||
        `inset 0 -1px 0 0 ${Colors.GREY_B4B4B4}`};
    box-sizing: border-box;
    color: ${Colors.GREY_333333};
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    opacity ${(props) => (props.disabled && "0.5") || "1"};
    min-height: 52px;
`;

const InputRow = styled.div`
    margin: 20px 0 0;

    ${minMedia.desktop`
        display: grid;
        grid-template-columns: 2fr 5fr;
    `}
`;

const DesktopLabel = styled.div`
    color: ${Colors.GREY_575757};
    display: none;
    font-size: 20px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 12px 0 0;
    text-align: left;

    ${minMedia.desktop`
            display: block;
    `};
`;

const MobileLabel = styled.div`
    color: ${(props) =>
        (props.hasError && Colors.RED_C82D2D) || (props.hasFocus && Colors.BLUE_005EA8) || Colors.GREY_333333};
    flex: 0 0 auto;
    font-size: 16px;
    line-height: 16px;
    margin: 0 0 7px;

    ${minMedia.desktop`
            display: none;
    `};
`;

const Column = styled.div`
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
    margin: 10px 10px 0;

    ${minMedia.desktop`
         margin: 0 15px 0;
    `}
`;

const Row = styled.div`
    align-items: ${(props) => (props.hasLabel ? "flex-start" : "center")};
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${(props) => (props.hasLabel ? "8px" : "0")};
    width: 100%;

    ${minMedia.desktop`
        align-items: center;
        margin-bottom: 0;
    `};
`;

const TextLink = styled.div`
    color: ${Colors.BLUE_005EA8};
    column-gap: 7px;
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 20px;
`;

const Info = styled.span`
    color: ${Colors.GREY_575757};
    font-size: 14px;
    line-height: 22px;

    ${minMedia.desktop`
        font-size: 16px;
        line-height: 20px;
    `}
`;

const Input = styled.input`
    -moz-appearance: textfield;
    align-content: center;
    box-sizing: border-box;
    border: none;
    color: ${Colors.GREY_333333};
    display: none;
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 18px;
    letter-spacing: 0;
    line-height: 21px;
    margin: 0;
    padding: 0;
    width: 100%;

    &::placeholder {
        color: ${Colors.GREY_999999};
    }

    &:focus {
        outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 26px ${Colors.WHITE_FFFFFF} inset !important;
    }

    &:read-only {
        cursor: inherit;
    }
`;

const Icon = styled.div`
    align-items: center;
    box-sizing: border-box;
    color: ${Colors.GREY_B4B4B4};
    display: flex;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 21px;
    margin-left: 15px;
    width: auto;

    ${minMedia.desktop`
        margin-left: 20px;
    `};
`;

const Tile = styled.div`
    background-color: ${Colors.WHITE_FFFFFF};
    border-radius: 3px;
    box-sizing: border-box;
    color: ${Colors.BLUE_005EA8};
    display: table;
    font-size: 16px;
    margin: 15px 0 0;
    outline: none;
    text-decoration: none;
    width: 100%;

    &:first-child {
        margin-top: 0;
    }
`;

const TileSection = styled.div`
    margin: 15px 0;
    padding: 0 10px;

    & + & {
        border-top: 1px solid ${Colors.GREY_B4B4B4};
        padding-top: 15px;
    }

    ${minMedia.desktop`
        padding: 0 15px;
    `}
`;

const UploadedFile = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const FileIcon = styled.div`
    color: ${Colors.GREY_575757};
    height: 25px;
    width: 24px;
    margin-right: 10px;
`;

const Text = styled.div`
    color: ${Colors.GREY_575757};
    flex: 1 1 auto;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 2px;
    overflow-wrap: break-word;
    word-break: break-word;
`;

const Delete = styled.button`
    background-color: transparent;
    border: none;
    color: ${Colors.GREY_B4B4B4};
    cursor: pointer;
    height: 20px;
    outline: none;
    margin: 2px 0 0 10px;
    padding: 0;
    text-decoration: none;
    width: 20px;

    &:hover {
        text-decoration: underline;
    }
`;

export default {
    Container,
    InputRow,
    DesktopLabel,
    MobileLabel,
    Row,
    Column,
    TextLink,
    Info,
    Input,
    Delete,
    Icon,
    Tile,
    TileSection,
    UploadedFile,
    FileIcon,
    Text,
};
