import styled from "styled-components";

import { minMedia } from "container/global.styles";

import SharedStyles from "styles/shared.styles";

const Container = styled.div`
    margin: 35px 0 0;

    ${minMedia.desktop`
        margin: 70px 0 0;
        text-align: center;
    `};
`;

const CarouselContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const More = styled(SharedStyles.TextButton)`
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
    width: 100%;
`;

export default {
    Container,
    CarouselContainer,
    More,
};
