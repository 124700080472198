import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import PropTypes from "prop-types";

import { ImageShape } from "components/image/image.shape";
import { SplashOverlay } from "components/splash-overlay/splash-overlay";

import { AdSplashEnum } from "enumeration/ad-splash.enum";
import { CookieKeyEnum } from "enumeration/cookie-key.enum";
import { StatusEnum } from "enumeration/status.enum";

export const Splash = ({ status, desktopImage, mobileImage, link }) => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [cookies, setCookie] = useCookies([CookieKeyEnum.AD_SPLASH]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (typeof document !== "object" || typeof window !== "object") {
            return null;
        }

        setShowOverlay(cookies[CookieKeyEnum.AD_SPLASH] !== AdSplashEnum.SEEN);
    }, [cookies]);

    const onRedirect = () => {
        setCookie(CookieKeyEnum.AD_SPLASH, AdSplashEnum.SEEN, { path: "/" });
        setShowOverlay(false);
    };

    if (!desktopImage || !mobileImage || !link) return null;

    if (status === StatusEnum.DRAFT) return null;

    return (
        showOverlay && (
            <SplashOverlay
                desktopImage={desktopImage}
                mobileImage={mobileImage}
                link={link}
                onClose={() => setShowOverlay(false)}
                onRedirect={onRedirect}
            />
        )
    );
};

Splash.defaultProps = {
    status: null,
    desktopImage: null,
    mobileImage: null,
    link: null,
};

Splash.propTypes = {
    status: PropTypes.string,
    desktopImage: PropTypes.shape(ImageShape),
    mobileImage: PropTypes.shape(ImageShape),
    link: PropTypes.shape({
        url: PropTypes.string.isRequired,
        target: PropTypes.string,
        title: PropTypes.string.isRequired,
    }),
};

export default Splash;
