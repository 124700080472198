import React from "react";
import PropTypes from "prop-types";

import { ContentRow } from "components/content-row/content-row";

import { Colors } from "container/global.colors";

import { StatusEnum } from "enumeration/status.enum";

import Styles from "./board.styles";

export const Board = ({ status }) => {
    if (status === StatusEnum.DRAFT) return null;

    return (
        <>
            <Styles.Frame>
                <ContentRow backgroundColor={Colors.YELLOW_FFECB9}>
                    <Styles.Container withColor>
                        <Styles.TextContainer>
                            <h2>Wer hat entschieden?</h2>

                            <p>Über die Vergabe der Preisgelder entschied ein neutrales Gremium aus:</p>

                            <Styles.List>
                                <Styles.EntryContainer>
                                    <Styles.TextWrapper>
                                        <h3>Politik:</h3>

                                        <p>
                                            Steffen Sambill (Stellv. Fraktionsvorsitzender, Vorsitzender Ausschuss
                                            Schule, Mitglied Ausschuss Sport)
                                        </p>
                                    </Styles.TextWrapper>
                                </Styles.EntryContainer>

                                <Styles.EntryContainer>
                                    <Styles.TextWrapper>
                                        <h3>Sport:</h3>

                                        <p>
                                            David Kozlowski (Leiter Stabsstelle Sportinfrastruktur / Umwelt
                                            Landessportbund Berlin) <br />
                                            Frank Wendler (Vorsitzender des Bezirkssportbundes Treptow-Köpenick)
                                        </p>
                                    </Styles.TextWrapper>
                                </Styles.EntryContainer>

                                <Styles.EntryContainer>
                                    <Styles.TextWrapper>
                                        <h3>CHECK24 Unternehmen:</h3>

                                        <p>Dagmar Ginzel (Chief Communications Officer)</p>
                                    </Styles.TextWrapper>
                                </Styles.EntryContainer>

                                <Styles.EntryContainer>
                                    <Styles.TextWrapper>
                                        <h3>sowie eine Kinderjury</h3>
                                    </Styles.TextWrapper>
                                </Styles.EntryContainer>
                            </Styles.List>
                        </Styles.TextContainer>
                    </Styles.Container>
                </ContentRow>
            </Styles.Frame>

            <ContentRow>
                <Styles.Container />
            </ContentRow>
        </>
    );
};

Board.defaultProps = {
    status: null,
};

Board.propTypes = {
    status: PropTypes.string,
};

export default Board;
