import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Container = styled.div`
    background-color: ${Colors.WHITE_FFFFFF};
    border: none;
    border-radius: 3px 3px 0 0;
    box-shadow: ${(props) =>
        (props.hasError && `inset 0 -2px 0 0 ${Colors.RED_C82D2D}`) ||
        (props.hasFocus && `inset 0 -2px 0 0 ${Colors.BLUE_005EA8}`) ||
        `inset 0 -1px 0 0 ${Colors.GREY_B4B4B4}`};
    box-sizing: border-box;
    color: ${Colors.GREY_333333};
    display: flex;
    flex-direction: row;
    justify-content: center;
    opacity ${(props) => (props.disabled && "0.5") || "1"};
`;

const InputRow = styled.div`
    margin: 20px 0 0;

    ${minMedia.desktop`
        display: grid;
        grid-template-columns: 2fr 5fr;
    `}
`;

const DesktopLabel = styled.div`
    color: ${Colors.GREY_575757};
    display: none;
    font-size: 20px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 12px 0 0;
    text-align: left;

    ${minMedia.desktop`
            display: block;
    `};
`;

const MobileLabel = styled.div`
    color: ${(props) =>
        (props.hasError && Colors.RED_C82D2D) || (props.hasFocus && Colors.BLUE_005EA8) || Colors.GREY_333333};
    flex: 0 0 auto;
    font-size: 16px;
    line-height: 16px;
    margin: 0 0 7px;

    ${minMedia.desktop`
            display: none;
    `};
`;

const Row = styled.div`
    align-items: ${(props) => (props.hasLabel ? "flex-start" : "center")};
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin-bottom: ${(props) => (props.isCounterVisible ? "0" : "23px")};
    width: 100%;

    ${minMedia.desktop`
        align-items: center;
    `};
`;

const Column = styled.div`
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
    margin: 10px 10px 0;

    ${minMedia.desktop`
         margin: 15px 15px 0;
    `}
`;

const Textarea = styled.textarea`
    -moz-appearance: textfield;
    box-sizing: border-box;
    border: none;
    color: ${Colors.GREY_333333};
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 18px;
    height: 160px;
    letter-spacing: 0;
    line-height: 21px;
    outline: none;
    padding: 0;
    resize: none;
    width: 100%;

    &::placeholder {
        color: ${Colors.GREY_999999};
    }

    &:focus {
        outline: none;
    }
`;

const Counter = styled.div`
    align-self: flex-end;
    color: ${(props) => (props.isWarning ? Colors.RED_C82D2D : Colors.GREY_B4B4B4)};
    flex: 0 0 auto;
    font-size: 13px;
    line-height: 13px;
    margin: 5px 0;

    ${minMedia.desktop`
        margin: 5px 0;
    `};
`;

export default {
    Container,
    InputRow,
    DesktopLabel,
    MobileLabel,
    Row,
    Column,
    Textarea,
    Counter,
};
