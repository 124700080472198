import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Container = styled.div`
    background-color: ${(props) => props.backgroundColor};
    margin: 15px 0 0;
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 1280px;
    height: 100%;
    width: 100%;

    &:first-child {
        margin-top: 0;
    }

    ${minMedia.desktop`
        flex-direction: row;
        margin: 30px auto 0;
        max-height: 332px;

        &:first-child {
            margin-top: 0;
        }
    `};
`;

const ImageContainer = styled.div`
    width: 100%;

    ${minMedia.desktop`
        width: 40%;
    `};
`;

const ImageWrapper = styled.div`
    height: 100%;
    position: relative;

    img {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

const Flag = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: ${Colors.WHITE_FFFFFF};
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    position: absolute;
    left: 0;
    top: 0;
    height: 33px;
    background-color: ${Colors.BLUE_063773};
    padding: 0 10px;
    width: 210px;
    z-index: 1;

    &:after {
        width: 0;
        height: 0;
        border-top: 33px solid ${Colors.BLUE_063773};
        border-right: 10px solid transparent;
        display: block;
        position: absolute;
        content: "";
        right: -10px;
        top: 0;
    }

    ${minMedia.desktop`
        height: 50px;
        padding: 0 25px;
        font-size: 20px;
        width: 285px;

        &:after {
            border-top: 50px solid ${Colors.BLUE_063773};
            border-right: 15px solid transparent;
            right: -15px;
        }
    `};
`;

const Content = styled.div`
    box-sizing: border-box;
    padding: 20px 10px;
    width: 100%;

    ${minMedia.desktop`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 25px 35px 40px;
        width: 60%;
    `};
`;

const TextContainer = styled.div``;

const SubTitle = styled.h3`
    color: ${Colors.GREY_575757};
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    margin: 0;
    max-height: 81px;
    overflow: hidden;

    ${minMedia.desktop`
        line-height: 27px;
        font-size: 20px;
    `}
`;

const Paragraph = styled.p`
    color: ${Colors.GREY_575757};
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 16px 0 0;
    max-height: 81px;
    overflow: hidden;

    ${minMedia.desktop`
        font-size: 20px;
        line-height: 27px;
        margin: 20px 0 0;
    `}
`;

const Link = styled.a`
    color: ${Colors.BLUE_005EA8};
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 16px 0 0;
    text-decoration: none;

    ${minMedia.desktop`
        font-size: 20px;
        line-height: 27px;
        margin: 20px 0 0;
    `}
`;

export default {
    Container,
    ImageContainer,
    ImageWrapper,
    Flag,
    Content,
    TextContainer,
    SubTitle,
    Paragraph,
    Link,
};
