import React from "react";
import PropTypes from "prop-types";

// delete_x_grau.svg

export const Delete = ({ height, width, ...rest }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fill="currentColor"
                d="M89.7 0L50 39.6 10.3 0 0 10.3 39.6 50 0 89.7 10.3 100 50 60.4 89.7 100 100 89.7 60.4 50 100 10.3z"
            />
        </svg>
    );
};

Delete.defaultProps = {
    color: null,
};

Delete.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
};

export default Delete;
