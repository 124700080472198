import React, { useContext } from "react";
import { Marker } from "@react-google-maps/api";
import PropTypes from "prop-types";

import MarkerSvg from "assets/images/marker.svg";
import MarkerSvgInverted from "assets/images/marker_inverted.svg";

import { MapContext } from "context/map.context";

export const Markers = ({ locationsJson }) => {
    const { mapRef, center, setCenter, activeLocation, setActiveLocation } = useContext(MapContext);

    if (locationsJson === null) return null;

    const handleClick = (location) => {
        if (location !== activeLocation) {
            if (!activeLocation) {
                const currentCenter = mapRef.current.getCenter().toJSON();
                setCenter(currentCenter);
            }

            setActiveLocation(location);
            mapRef.current.setCenter({ lat: parseFloat(location.lat), lng: parseFloat(location.lng) });
        } else {
            setActiveLocation(null);
            mapRef.current.setCenter(center);
        }
    };

    return locationsJson.data.map((item) => {
        return (
            <Marker
                key={item.key}
                position={{ lat: parseFloat(item.lat), lng: parseFloat(item.lng) }}
                icon={{ url: activeLocation?.key === item.key ? MarkerSvgInverted : MarkerSvg }}
                onClick={() => handleClick(item)}
            />
        );
    });
};

Markers.defaultProps = {
    locationsJson: null,
};

Markers.propTypes = {
    locationsJson: PropTypes.object,
};

export default Markers;
