import React from "react";
import PropTypes from "prop-types";

import { getStrapiMedia } from "lib/media";

import { Link } from "utils/link";

import { FileShape } from "./file.shape";

export const File = ({ file, fileTitle }) => {
    const fileUrl = getStrapiMedia(file);

    return (
        <p>
            <Link href={fileUrl} title={fileTitle} target="_blank">
                {fileTitle}
            </Link>
        </p>
    );
};

File.propTypes = {
    file: PropTypes.shape(FileShape).isRequired,
    fileTitle: PropTypes.string.isRequired,
};

export default File;
