import React from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import RemarkGfm from "remark-gfm";
import RemarkHeadingId from "remark-heading-id";

import { MarkdownImage } from "utils/markdown/markdown-image";
import { MarkdownLink } from "utils/markdown/markdown-link";

export const Markdown = ({ parentIsLink, children }) => {
    return (
        <ReactMarkdown
            skipHtml
            components={{
                // eslint-disable-next-line react/display-name,react/prop-types
                a: ({ node, ...props }) => <MarkdownLink parentIsLink={parentIsLink} {...props} />,
                img: MarkdownImage,
            }}
            remarkPlugins={[RemarkHeadingId, RemarkGfm]}
        >
            {children}
        </ReactMarkdown>
    );
};

Markdown.defaultProps = {
    parentIsLink: false,
    children: null,
};

Markdown.propTypes = {
    parentIsLink: PropTypes.bool,
    children: PropTypes.any,
};

export default Markdown;
