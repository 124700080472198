import React, { useCallback, useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import { Close } from "icons/close";

import Styles from "./modal.styles";

export const Modal = ({ onClose, children, width }) => {
    const overlayRef = useRef(null);
    const closingRef = useRef(false);

    const onEventStart = (event) => {
        const domNode =
            // eslint-disable-next-line react/no-find-dom-node
            overlayRef.current && ReactDOM.findDOMNode(overlayRef.current);

        if (domNode && event.target.contains(domNode)) {
            closingRef.current = true;
        }
    };

    const close = useCallback(
        (...args) => {
            // eslint-disable-next-line no-unused-expressions
            overlayRef.current && (overlayRef.current.style.opacity = "0");

            document.body.style.position = "";
            document.body.style.top = "";
            document.body.classList.remove("modal-body-no-scroll");
            onClose(args);
        },
        [onClose]
    );

    const onEventEnd = (event) => {
        if (closingRef.current) {
            close();
            event.stopPropagation();
            event.preventDefault();
        }
    };

    return ReactDOM.createPortal(
        <Styles.Overlay
            onMouseDown={onEventStart}
            onMouseUp={onEventEnd}
            onTouchStart={onEventStart}
            onTouchEnd={onEventEnd}
            onScroll={(e) => e.stopPropagation()}
            ref={overlayRef}
        >
            <Styles.Container style={{ maxWidth: width }}>
                <Styles.Close onClick={close}>
                    <Close height="20px" width="20px" />
                </Styles.Close>

                <Styles.Content>{children}</Styles.Content>
            </Styles.Container>
        </Styles.Overlay>,
        document.querySelector("body")
    );
};

Modal.defaultProps = {
    remoteClose: false,
    width: "100%",
};

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node,
    remoteClose: PropTypes.bool,
    width: PropTypes.string,
};

export default Modal;
