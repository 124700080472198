import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Container = styled.div`
    margin: 20px;

    & + & {
        border-top: 1px solid ${Colors.GREY_B4B4B4};
    }

    ${minMedia.desktop`
        margin: 30px;

        & + & {
            border-top: 1px solid ${Colors.GREY_333333};
        }
    `};

    h2 {
        color: ${Colors.GREY_575757};
        font-size: 24px;
        font-weight: ${(props) => props.theme.fontWeight.regular};
        margin: 20px 0;
        text-decoration: none solid rgb(87, 87, 87);

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        ${minMedia.desktop`
            font-size: 40px;
            margin: 30px 0;
        `};
    }

    p {
        color: ${Colors.GREY_575757};
        font-size: 16px;
        font-weight: ${(props) => props.theme.fontWeight.regular};
        margin: 20px 0 0;

        &:first-child {
            margin-top: 0;
        }

        ${minMedia.desktop`
            font-size: 20px;
        `};
    }

    ul {
        color: ${Colors.GREY_575757};
        font-size: 16px;
        font-weight: ${(props) => props.theme.fontWeight.regular};
        padding-inline-start: 30px;

        ${minMedia.desktop`
            font-size: 20px;
        `};
    }
`;

export default {
    Container,
};
