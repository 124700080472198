export class FileUploadErrorTypeEnum {
    static FILE_CREATE_CHUNKS = "fileCreateChunks";

    static FILE_UPLOAD_CHUNKS = "fileUploadChunks";

    static FILE_FINISH_UPLOAD = "fileFinishUpload";

    static FILE_UPLOAD = "fileUpload";

    static FILE_MANDATORY = "fileMandatory";

    static FILE_MAX_NUMBER = "fileMaxNumber";

    static FILE_MAX_SIZE = "fileMaxSize";

    static FILE_DUPLICATE = "fileDuplicate";

    static FILE_IDENTIFIER = "fileIdentifier";

    static FILE_MIME_TYPE = "fileMimeType";

    static FILE_NAME = "fileName";

    static FILE_STATE = "fileState";
}

export default FileUploadErrorTypeEnum;
