import styled from "styled-components";

import { minMedia } from "container/global.styles";

const Container = styled.div`
    ${(props) => (props.withColor ? "padding" : "margin")}: 25px 0 25px;

    ${minMedia.desktop`
        text-align: center;
        ${(props) => (props.withColor ? "padding" : "margin")}: 70px 0 70px;
    `};
`;

const TextContainer = styled.div`
    text-align: ${(props) => props.textAlignMobile ?? "left"};
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    ${minMedia.desktop`
        margin-bottom: 30px;
        text-align: ${(props) => props.textAlignDesktop ?? "center"};
    `};
`;

const BtnWrapper = styled.div`
    margin: 25px 0;

    ${minMedia.desktop`
        margin: 0 auto;
        width: 350px;
    `};
`;

export default {
    Container,
    TextContainer,
    BtnWrapper,
};
