import React from "react";
import PropTypes from "prop-types";

import { Colors } from "container/global.colors";

export const Instagram = ({ height, width, ...rest }) => {
    return (
        <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...rest}>
            <g fill="currentColor">
                <path d="M50 0C22.386 0 0 22.386 0 50s22.386 50 50 50 50-22.386 50-50S77.614 0 50 0zm28.874 68.44c0 5.763-4.672 10.435-10.435 10.435H31.561c-5.763 0-10.435-4.672-10.435-10.435V31.562c0-5.763 4.672-10.435 10.435-10.435h36.878c5.763 0 10.435 4.671 10.435 10.434V68.44z" />

                <circle cx="50" cy="50" r="12.887" />

                <path d="M65.916 25.467H34.084a8.617 8.617 0 00-8.617 8.617v31.832a8.617 8.617 0 008.617 8.617h31.832a8.617 8.617 0 008.618-8.617V34.084a8.618 8.618 0 00-8.618-8.617zM50 66.786c-9.27 0-16.786-7.515-16.786-16.786S40.729 33.214 50 33.214 66.786 40.729 66.786 50 59.27 66.786 50 66.786zm17.273-32.21a3.042 3.042 0 110-6.084 3.042 3.042 0 010 6.084z" />
            </g>
        </svg>
    );
};

Instagram.defaultProps = {
    color: Colors.WHITE_FFFFFF,
    height: "20px",
    width: "20px",
};

Instagram.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default Instagram;
