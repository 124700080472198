import React from "react";
import PropTypes from "prop-types";
import { ContentRow } from "components/content-row/content-row";
import Style from "./vimeo-video.styles";
import { EnumHelper } from "helper/enum-helper";

export const VimeoVideo = ({
    vimeoId,
    heightDesktop,
    widthDesktop,
    heightMobile,
    widthMobile,
    backgroundColor,
}) => {
    return (
        <ContentRow backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
            <Style.Container withColor={backgroundColor !== "white"}>
                <Style.VideoWrapper>
                    <Style.IframeWrapper heightDesktop={heightDesktop} widthDesktop={widthDesktop}
                                         heightMobile={heightMobile} widthMobile={widthMobile}>
                        <iframe
                            src={`https://player.vimeo.com/video/${vimeoId}?h=b14e813f5e&title=0&byline=0&portrait=0`}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen />
                    </Style.IframeWrapper>
                </Style.VideoWrapper>
            </Style.Container>
        </ContentRow>
    );
};

VimeoVideo.propTypes = {
    vimeoId: PropTypes.string,
    heightDesktop: PropTypes.number,
    widthDesktop: PropTypes.number,
    heightMobile: PropTypes.number,
    widthMobile: PropTypes.number,
    backgroundColor: PropTypes.string,
};

export default VimeoVideo;
