import React, { useContext } from "react";
import Head from "next/head";
import PropTypes from "prop-types";

import { DeviceOutputContext } from "context/device-output.context";
import { GlobalContext } from "context/global.context";

import { getStrapiMedia } from "lib/media";

export const Seo = ({ seo }) => {
    const { defaultSeo, siteName } = useContext(GlobalContext);
    const { isApp } = useContext(DeviceOutputContext);
    const seoWithDefaults = {
        ...defaultSeo,
        ...seo,
    };

    const fullSeo = {
        ...seoWithDefaults,
        // Add title suffix
        metaTitle: `${siteName} | ${seoWithDefaults.metaTitle}`,
        // Get full image URL
        shareImage: getStrapiMedia(seoWithDefaults.shareImage),
    };

    return (
        <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            {isApp === true && <meta name="app-product" content="web" />}
            {fullSeo.metaTitle && (
                <>
                    <title>{fullSeo.metaTitle}</title>
                    <meta property="og:title" content={fullSeo.metaTitle} />
                    <meta name="twitter:title" content={fullSeo.metaTitle} />
                </>
            )}
            {fullSeo.metaDescription && (
                <>
                    <meta name="description" content={fullSeo.metaDescription} />
                    <meta property="og:description" content={fullSeo.metaDescription} />
                    <meta name="twitter:description" content={fullSeo.metaDescription} />
                </>
            )}
            {fullSeo.shareImage && (
                <>
                    <meta property="og:image" content={fullSeo.shareImage} />
                    <meta name="twitter:image" content={fullSeo.shareImage} />
                    <meta name="image" content={fullSeo.shareImage} />
                </>
            )}
            {fullSeo.article && <meta property="og:type" content="article" />}
            <meta name="twitter:card" content="summary_large_image" />
        </Head>
    );
};

Seo.propTypes = {
    seo: PropTypes.any.isRequired,
};

export default Seo;
