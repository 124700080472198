import styled from "styled-components";

import { Colors } from "container/global.colors";

const Container = styled.div`
    width: 100%;
    text-align: end;

    a,
    button {
        align-items: center;
        background-color: ${(props) => (props.primary ? Colors.BLUE_0271C2 : Colors.WHITE_FFFFFF)};
        border: 1px solid ${Colors.BLUE_0271C2};
        border-radius: 25px;
        box-sizing: border-box;
        color: ${(props) => (props.primary ? Colors.WHITE_FFFFFF : Colors.BLUE_0271C2)};
        cursor: pointer;
        display: flex;
        flex-direction: row;
        font-family: ${(props) => props.theme.buttonFont};
        font-size: 20px;
        font-weight: ${(props) => props.theme.fontWeight.bold};
        justify-content: center;
        line-height: 20px;
        outline: none;
        height: 50px;
        text-decoration: none;
        width: 100%;

        &:hover {
            background-color: ${(props) => (props.primary ? Colors.BLUE_015FA4 : Colors.WHITE_FFFFFF)};
            border: 1px solid ${Colors.BLUE_015FA4};
            color: ${(props) => (props.primary ? Colors.WHITE_FFFFFF : Colors.BLUE_015FA4)};
        }
    }
`;

const IconWrapper = styled.div`
    display: flex;
    height: 16px;
    margin-left: 7px;
    width: 18px;
`;

export default {
    Container,
    IconWrapper,
};
