import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Container = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: ${(props) => (props.atTop ? "15px 0 5px" : "5px 0 15px")};

    margin-left: ${(props) => (props.withMarginLeft ? "10px" : "0")};

    &:last-child {
        margin-bottom: 0;
    }
`;

const Icon = styled.div`
    display: none;

    ${minMedia.desktop`
        display: block;
        color: ${Colors.RED_C82D2D};
        height: 15px;
        margin-right: 10px;
        margin-top: 1px;
        width: 15px;
    `}
`;

const Text = styled.div`
    color: ${Colors.RED_C82D2D};
    font-size: 16px;
    font-weight: normal;
    hyphens: auto;
    line-height: 18px;
    margin-right: 10px;

    p {
        margin: 5px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export default {
    Container,
    Icon,
    Text,
};
