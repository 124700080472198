export class FieldNameEnum {
    static CLUB = "club";

    static ADDRESS = "address";

    static PHONE = "phone";

    static EMAIL = "email";

    static CONTACT = "contact";

    static FUNCTION_OF_CONTACT = "functionOfContact";

    static CLUB_DESCRIPTION = "clubDescription";

    static CLUB_LOGO = "clubLogo";

    static CERTIFICATE_OF_EXEMPTION = "certificateOfExemption";

    static SPORTS_ELIGIBILITY = "sportsEligibility";

    static PROJECT = "project";

    static PROJECT_IMAGES = "projectImages";

    static OTHER_DOCUMENTS = "otherDocuments";

    static GET_TO_KNOW = "getToKnow";

    static ACCOUNT_HOLDER = "accountHolder";

    static BANK = "bank";

    static IBAN = "iban";

    static BIC = "bic";

    static CONFIRM_UPLOAD = "confirmUpload";

    static CONFIRM_CONDITIONS = "confirmConditions";

    static CONFIRM_COPYRIGHT = "confirmCopyright";
}

export default {
    FieldNameEnum,
};
