import React from "react";
import NextLink from "next/link";
import PropTypes from "prop-types";

import { Heart } from "icons/heart";

import { Link } from "utils/link";

import Styles from "./button.styles";

export const Button = ({ linkTitle, linkUrl, externalLink, primary, withHeart, ...rest }) => {
    return (
        <Styles.Container primary={primary}>
            {(externalLink && (
                <Link href={linkUrl} target="_blank" {...rest}>
                    <span>{linkTitle}</span>

                    {withHeart && (
                        <Styles.IconWrapper>
                            <Heart height="16px" width="18px" />
                        </Styles.IconWrapper>
                    )}
                </Link>
            )) || (
                <NextLink href={linkUrl}>
                    <a {...rest}>
                        <span>{linkTitle}</span>

                        {withHeart && (
                            <Styles.IconWrapper>
                                <Heart height="16px" width="18px" />
                            </Styles.IconWrapper>
                        )}
                    </a>
                </NextLink>
            )}
        </Styles.Container>
    );
};

Button.defaultProps = {
    externalLink: false,
    primary: false,
    withHeart: false,
};

Button.propTypes = {
    linkTitle: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired,
    externalLink: PropTypes.bool,
    primary: PropTypes.bool,
    withHeart: PropTypes.bool,
};

export default Button;
