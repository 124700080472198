import React, { useContext, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import { Image } from "components/image/image";
import { ImageShape } from "components/image/image.shape";

import { MediaContext } from "context/media.context";

import { Close } from "icons/close";

import Styles from "./splash-overlay.styles";

export const SplashOverlay = ({ desktopImage, mobileImage, link, onClose, onRedirect }) => {
    const media = useContext(MediaContext);

    const overlayRef = useRef(null);
    const closingRef = useRef(false);

    useEffect(() => {
        document.body.classList.add("no-scroll");

        return () => {
            document.body.classList.remove("no-scroll");
        };
    }, []);

    const onEventStart = (event) => {
        const domNode =
            // eslint-disable-next-line react/no-find-dom-node
            overlayRef.current && ReactDOM.findDOMNode(overlayRef.current);

        if (domNode && event.target.contains(domNode)) {
            closingRef.current = true;
        }
    };

    const handleClose = () => {
        onClose();

        // eslint-disable-next-line no-unused-expressions
        overlayRef.current && (overlayRef.current.style.opacity = "0");

        document.body.style.position = "";
        document.body.style.top = "";
        document.body.classList.remove("no-scroll");
    };

    const handleRedirect = (e) => {
        handleClose(e);
        onRedirect();
    };

    const onEventEnd = (event) => {
        if (closingRef.current) {
            handleClose(event);
            event.stopPropagation();
            event.preventDefault();
        }
    };

    const image = media.isDesktop ? desktopImage : mobileImage;

    return ReactDOM.createPortal(
        <Styles.Overlay
            onMouseDown={onEventStart}
            onMouseUp={onEventEnd}
            onTouchStart={onEventStart}
            onTouchEnd={onEventEnd}
            onScroll={(e) => e.stopPropagation()}
            ref={overlayRef}
        >
            <Styles.Container>
                <Styles.ImageContainer href={link.url} target={link.target} title={link.title} onClick={handleRedirect}>
                    <Styles.Close
                        onClick={(e) => {
                            handleClose(e);
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                    >
                        <Close height="20px" width="20px" />
                    </Styles.Close>

                    <Styles.ImageWrapper>
                        <Image image={image} />
                    </Styles.ImageWrapper>
                </Styles.ImageContainer>
            </Styles.Container>
        </Styles.Overlay>,
        document.querySelector("body")
    );
};

SplashOverlay.propTypes = {
    desktopImage: PropTypes.shape(ImageShape).isRequired,
    mobileImage: PropTypes.shape(ImageShape).isRequired,
    link: PropTypes.shape({
        url: PropTypes.string.isRequired,
        target: PropTypes.string,
        title: PropTypes.string.isRequired,
    }).isRequired,
    onRedirect: PropTypes.func.isRequired,
};

export default SplashOverlay;
