import React from "react";
import NextLink from "next/link";

export class LinkHelper {
    static renderComponentLink = (Component, props) => {
        const { key, linkUrl, externalLink, isApp, media, ...otherProps } = props;

        if (!linkUrl) return <Component key={key} {...otherProps} />;

        const trimmedLinkUrl = linkUrl.trim();

        const scrollToAnchor = () => {
            const offset = media.isDesktop ? 70 : 25;
            const navHeight = media.isDesktop ? 100 : 50;
            const top =
                document.querySelector(trimmedLinkUrl).getBoundingClientRect().top - offset - (isApp ? 0 : navHeight);

            window.scrollBy({
                top,
                left: 0,
                behavior: "smooth",
            });
        };

        if (trimmedLinkUrl?.startsWith("#")) {
            return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <Component as="a" className="anchor" key={key} onClick={scrollToAnchor} parentIsLink {...otherProps} />
            );
        }

        if (externalLink === true) {
            return (
                <Component
                    as="a"
                    className="anchor"
                    key={key}
                    href={trimmedLinkUrl}
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                    parentIsLink
                    {...otherProps}
                />
            );
        }

        return (
            <NextLink href={trimmedLinkUrl} key={key}>
                <Component as="a" className="anchor" parentIsLink {...otherProps} />
            </NextLink>
        );
    };
}

export default LinkHelper;
