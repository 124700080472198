import React from "react";
import PropTypes from "prop-types";

import { Button } from "components/button/button";
import { ContentRow } from "components/content-row/content-row";

import { StatusEnum } from "enumeration/status.enum";

import { EnumHelper } from "helper/enum-helper";

import Styles from "./donation-button.styles";

export const DonationButton = ({ status, title, url, backgroundColor }) => {
    if (status === StatusEnum.DRAFT) return null;

    return (
        <ContentRow backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
            <Styles.Container>
                <Styles.BtnWrapper>
                    <Button linkUrl={url} linkTitle={title} externalLink primary withHeart />
                </Styles.BtnWrapper>
            </Styles.Container>
        </ContentRow>
    );
};

DonationButton.defaultProps = {
    status: null,
};

DonationButton.propTypes = {
    status: PropTypes.string,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
};

export default DonationButton;
