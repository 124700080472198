import React, { useContext } from "react";
import PropTypes from "prop-types";

import { DeviceOutputContext } from "context/device-output.context";
import { MediaContext } from "context/media.context";

import { StringHelper } from "helper/string-helper";

import { Link } from "utils/link";

export const MarkdownLink = ({ href, parentIsLink, children }) => {
    const { isApp } = useContext(DeviceOutputContext);
    const media = useContext(MediaContext);

    const trimmedHref = parentIsLink ? null : href?.trim();

    const scrollToAnchor = () => {
        const offset = media.isDesktop ? 70 : 25;
        const navHeight = media.isDesktop ? 100 : 50;
        const top = document.querySelector(trimmedHref).getBoundingClientRect().top - offset - (isApp ? 0 : navHeight);

        window.scrollBy({
            top,
            left: 0,
            behavior: "smooth",
        });
    };

    if (StringHelper.isNullOrEmpty(trimmedHref) || trimmedHref === "link") {
        return <span className="linkPlaceholder">{children}</span>;
    }

    if (
        trimmedHref.match("https://www.check24.de/popup/datenschutz/") ||
        trimmedHref.match("/popup/teilnahmebedingungen")
    ) {
        return (
            <Link href={trimmedHref} target="popup">
                {children}
            </Link>
        );
    }

    if (trimmedHref.startsWith("http") || trimmedHref.startsWith("www")) {
        return (
            <Link href={trimmedHref} target="_blank">
                {children}
            </Link>
        );
    }

    if (trimmedHref.startsWith("/")) {
        return (
            <Link href={trimmedHref} target="_self">
                {children}
            </Link>
        );
    }

    if (trimmedHref.startsWith("#")) {
        return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <a onClick={scrollToAnchor}>{children}</a>
        );
    }

    return <Link href={trimmedHref}>{children}</Link>;
};

MarkdownLink.defaultProps = {
    parentIsLink: false,
    children: null,
};

MarkdownLink.propTypes = {
    href: PropTypes.string.isRequired,
    parentIsLink: PropTypes.bool,
    children: PropTypes.any,
};

export default MarkdownLink;
