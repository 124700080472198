import React, { useState } from "react";
import PropTypes from "prop-types";

import { ContentRow } from "components/content-row/content-row";
import { HorizontalTile } from "components/horizontal-tile/horizontal-tile";
import { TileShape } from "components/tile/tile.shape";

import { StatusEnum } from "enumeration/status.enum";

import { EnumHelper } from "helper/enum-helper";

import SharedStyles from "styles/shared.styles";

import Styles from "./actions.styles";

const TileList = ({ tiles, tilesPerPage, tileHeight }) => {
    const [numberOfTiles, setNumberOfTiles] = useState(tilesPerPage);

    const showMore = () => {
        setNumberOfTiles(numberOfTiles + 3);
    };

    return (
        <>
            {tiles.slice(0, numberOfTiles).map((tile, i) => {
                const { title, description, image, flag, backroundColor } = tile;

                return (
                    <HorizontalTile
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        title={title}
                        description={description}
                        image={image}
                        flag={flag}
                        height={tileHeight}
                        backgroundColor={backroundColor}
                    />
                );
            })}

            {numberOfTiles <= tiles.length && (
                <Styles.More type="button" onClick={showMore}>
                    weitere laden
                </Styles.More>
            )}
        </>
    );
};

TileList.defaultProps = {
    tilesPerPage: 3,
    tileHeight: 330,
};

TileList.propTypes = {
    tiles: PropTypes.array.isRequired,
    tilesPerPage: PropTypes.number,
    tileHeight: PropTypes.number,
};

export const Actions = ({ status, title, tiles, tilesPerPage, tileHeight, backgroundColor }) => {
    if (status === StatusEnum.DRAFT) return null;

    return (
        <ContentRow backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
            <Styles.Container>
                <SharedStyles.SubTitle>{title}</SharedStyles.SubTitle>

                <TileList tiles={tiles} tilesPerPage={tilesPerPage} tileHeight={tileHeight} />
            </Styles.Container>
        </ContentRow>
    );
};

Actions.defaultProps = {
    status: null,
    tilesPerPage: 3,
    tileHeight: 330,
};

Actions.propTypes = {
    status: PropTypes.string,
    title: PropTypes.string.isRequired,
    tiles: PropTypes.arrayOf(PropTypes.shape(TileShape)).isRequired,
    tilesPerPage: PropTypes.number,
    tileHeight: PropTypes.number,
    backgroundColor: PropTypes.string.isRequired,
};

export default Actions;
