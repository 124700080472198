import React from "react";
import PropTypes from "prop-types";

import { Spinner } from "icons/spinner";

import Styles from "./form-button.styles";

export const FormButton = ({ btnText, isSpinning, disabled, ...rest }) => {
    const size = 30;

    return (
        <Styles.Container>
            <Styles.Button type="submit" disabled={disabled} {...rest}>
                <span>{btnText}</span>
            </Styles.Button>

            <Styles.SpinnerWrapper>
                {isSpinning === true && !disabled && (
                    <Styles.Spinner size={size}>
                        <Spinner
                            style={{
                                border: 0,
                                borderRadius: 0,
                                content: "",
                                height: size,
                                left: 0,
                                position: "absolute",
                                top: 0,
                                width: size,
                            }}
                        />
                    </Styles.Spinner>
                )}
            </Styles.SpinnerWrapper>
        </Styles.Container>
    );
};

FormButton.defaultProps = {
    isSpinning: false,
    disabled: false,
};

FormButton.propTypes = {
    btnText: PropTypes.string.isRequired,
    isSpinning: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default FormButton;
