import React from "react";
import PropTypes from "prop-types";

export const Heart = ({ height, width, ...rest }) => {
    return (
        <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 16" {...rest}>
            <path
                d="M13.168 0C11.416 0 9.876.96 9 2.384 8.124.96 6.632 0 4.849 0 2.173 0 0 2.24 0 5.008S2.416 9.936 9 16c6.584-6.064 9-8.208 9-10.992C18 2.224 15.876 0 13.168 0z"
                fill="currentColor"
            />
            <animateTransform
                attributeName="transform"
                type="scale"
                values="0.9; 1; 0.9; 1; 1; 0.9;"
                dur="1.2s"
                repeatCount="indefinite"
                additive="sum"
            />
        </svg>
    );
};

Heart.defaultProps = {
    height: "16px",
    width: "18px",
};

Heart.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
};

export default Heart;
