export class FileTypeEnum {
    static JPG = "jpg";

    static JPEG = "jpeg";

    static PNG = "png";

    static PDF = "pdf";

    static SVG = "svg";
}

export default FileTypeEnum;
