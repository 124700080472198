import React from "react";
import PropTypes from "prop-types";

export const Previous = ({ height, width, ...rest }) => {
    return (
        <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 100" {...rest}>
            <path d="M6.261 50L27 6.5 23.87 0 0 50l23.87 50L27 93.5 6.261 50z" fill="currentColor" />
        </svg>
    );
};

Previous.defaultProps = {
    height: "100px",
    width: "27px",
};

Previous.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
};

export default Previous;
