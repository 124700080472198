import React from "react";
import PropTypes from "prop-types";

import { ContentRow } from "components/content-row/content-row";
import { Image } from "components/image/image";
import { ImageShape } from "components/image/image.shape";

import { StatusEnum } from "enumeration/status.enum";

import { Markdown } from "utils/markdown";

import Styles from "./project-intro.styles";

export const ProjectIntro = ({
    status,
    date,
    text,
    textAlignMobile,
    textAlignDesktop,
    image,
    caption,
    imageWidthDesktop,
    imageWidthMobile
}) => {
    if (status === StatusEnum.DRAFT) return null;

    if (!text && !image) return null;

    return (
        <ContentRow>
            <Styles.Container>
                <Styles.TextContainer textAlignMobile={textAlignMobile} textAlignDesktop={textAlignDesktop}>
                    {date && <Styles.Date>{date}</Styles.Date>}

                    {text && <Markdown>{text}</Markdown>}
                </Styles.TextContainer>

                <Styles.ImageWrapper imageWidthMobile={imageWidthMobile} imageWidthDesktop={imageWidthDesktop}>
                    {image && image.url && <Image image={image} />}

                    {caption && <Styles.Caption>{caption}</Styles.Caption>}
                </Styles.ImageWrapper>
            </Styles.Container>
        </ContentRow>
    );
};

ProjectIntro.defaultProps = {
    status: null,
    date: null,
    text: null,
    caption: null,
};

ProjectIntro.propTypes = {
    status: PropTypes.string,
    date: PropTypes.string,
    text: PropTypes.string,
    textAlignMobile: PropTypes.string.isRequired,
    textAlignDesktop: PropTypes.string.isRequired,
    image: PropTypes.shape(ImageShape).isRequired,
    caption: PropTypes.string,
    imageHeightMobile: PropTypes.string,
    imageHeightDesktop: PropTypes.string
};

export default ProjectIntro;
