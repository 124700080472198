import React, { Fragment, useContext, useState } from "react";
import PropTypes from "prop-types";

import { Button } from "components/button/button";
import CarouselComponent from "components/carousel/carousel";
import { ContentRow } from "components/content-row/content-row";
import { Slide } from "components/slide/slide";
import { SlideShape } from "components/slide/slide.shape";

import { DeviceOutputContext } from "context/device-output.context";
import { MediaContext } from "context/media.context";

import { StatusEnum } from "enumeration/status.enum";

import { EnumHelper } from "helper/enum-helper";
import { LinkHelper } from "helper/link-helper";

import { Markdown } from "utils/markdown";

import Styles from "./carousel.styles";

const TileList = ({ tiles, slidesPerPageMobile }) => {
    const [numberOfTiles, setNumberOfTiles] = useState(slidesPerPageMobile);

    const showMore = () => {
        setNumberOfTiles(numberOfTiles + 3);
    };

    return (
        <>
            {tiles.slice(0, numberOfTiles).map((Component, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={i}>{Component}</Fragment>
            ))}

            {tiles.length > numberOfTiles && (
                <Styles.More type="button" onClick={showMore}>
                    mehr anzeigen
                </Styles.More>
            )}
        </>
    );
};

TileList.defaultProps = {
    slidesPerPageMobile: 3,
};

TileList.propTypes = {
    tiles: PropTypes.array.isRequired,
    slidesPerPageMobile: PropTypes.number,
};

export const Carousel = ({
    status,
    content,
    showArrows,
    showDots,
    infiniteScrolling,
    slidesPerPageMobile,
    slidesPerPageDesktop,
    slides,
    backgroundColor,
    ctaLinkUrl,
    ctaLinkTitle,
    ctaExternalLink
}) => {
    const OFFSET = 10;

    const { isApp } = useContext(DeviceOutputContext);
    const media = useContext(MediaContext);

    if (status === StatusEnum.DRAFT) return null;

    const filteredSlides = slides.filter((item) => {
        return item.status !== StatusEnum.DRAFT;
    });

    const tiles = filteredSlides.map((tile, i) => {
        return LinkHelper.renderComponentLink(Slide, {
            key: i,
            isApp,
            media,
            backgroundColor: tile.tileBackgroundColor,
            ...tile,
        });
    });

    const needsSliding = tiles.length > slidesPerPageDesktop;

    return (
        <ContentRow backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)}>
            <Styles.Container withColor={backgroundColor !== "white"}>
                <Styles.TextContainer>{content && <Markdown>{content}</Markdown>}</Styles.TextContainer>

                {media.isDesktop && (
                    <Styles.CarouselContainer>
                        <CarouselComponent
                            slidesPerPage={slidesPerPageDesktop}
                            arrows={showArrows && needsSliding}
                            draggable={needsSliding}
                            infinite={infiniteScrolling && needsSliding}
                            dots={showDots && needsSliding}
                            offset={OFFSET}
                        >
                            {tiles}
                        </CarouselComponent>
                    </Styles.CarouselContainer>
                )}

                {!media.isDesktop && <TileList tiles={tiles} slidesPerPageMobile={slidesPerPageMobile}/>}

                {ctaLinkUrl && ctaLinkTitle && (
                    <Styles.BtnWrapper>
                        <Button
                            linkTitle={ctaLinkTitle}
                            linkUrl={ctaLinkUrl}
                            externalLink={ctaExternalLink}
                            primary={true}
                            withHeart={false}
                        />
                    </Styles.BtnWrapper>
                )}
            </Styles.Container>
        </ContentRow>
    );
};

Carousel.defaultProps = {
    status: null,
    content: null,
    infiniteScrolling: true,
    ctaExternalLink: false,
};

Carousel.propTypes = {
    status: PropTypes.string,
    content: PropTypes.any,
    slides: PropTypes.arrayOf(PropTypes.shape(SlideShape)).isRequired,
    showArrows: PropTypes.bool.isRequired,
    showDots: PropTypes.bool.isRequired,
    infiniteScrolling: PropTypes.bool,
    slidesPerPageMobile: PropTypes.number.isRequired,
    slidesPerPageDesktop: PropTypes.number.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    ctaLinkUrl: PropTypes.string,
    ctaLinkTitle: PropTypes.string,
    ctaExternalLink: PropTypes.bool
};
export default Carousel;
