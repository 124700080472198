import React from "react";
import PropTypes from "prop-types";

export const Next = ({ height, width, ...rest }) => {
    return (
        <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 100" {...rest}>
            <path d="M20.74 50L0 6.5 3.13 0 27 50 3.13 100 0 93.5 20.74 50z" fill="currentColor" />
        </svg>
    );
};

Next.defaultProps = {
    height: "100px",
    width: "27px",
};

Next.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
};

export default Next;
