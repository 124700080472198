import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Container = styled.div`
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height: 138vw;
    justify-content: center;
    margin: 0 auto;
    max-height: 800px;
    max-width: 1280px;
    width: 100%;

    ${minMedia.desktop`
        flex-direction: row;
        overflow-y: auto;
    `};
`;

const ResetButton = styled.button`
    align-items: center;
    background-color: ${Colors.WHITE_FFFFFF};
    border: 0;
    border-radius: 2px;
    box-shadow: rgb(0 0 0 / 30%) 0 1px 4px -1px;
    color: ${Colors.GREY_666666};
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    left: 10px;
    position: absolute;
    top: 101px;
    width: 40px;
    z-index: 10;
`;

export default {
    Container,
    ResetButton,
};
