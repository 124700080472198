import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { GoogleMap } from "components/map/component/google-map/google-map";
import { InfoCard } from "components/map/component/info-card/info-card";

import { MapContext } from "context/map.context";

import { useResettableState } from "hook/use-resettable-state";

import { Refresh } from "icons/map/refresh";

import Styles from "./map.styles";

export const Map = ({ locationsJson, children }) => {
    const getDefaultCenter = () => {
        return {
            lat: 51.2139656394792,
            lng: 10.4837384396111,
        };
    };

    const mapRef = useRef(null);
    const containerRef = useRef(null);
    const [center, setCenter, resetCenter] = useResettableState(getDefaultCenter());
    const [activeLocation, setActiveLocation, resetActiveLocation] = useResettableState(null);
    const [defaultZoom, setDefaultZoom] = useState(null);

    const getDefaultZoom = () => {
        const highestViewRatio = 95.2558148; // km per pixel at zoom level 1
        const containerRect = containerRef.current.getBoundingClientRect();

        const northSouthExtension = 879;
        const westEastExtension = 641;

        const maxHorizontalZoom = Math.floor(
            Math.log((highestViewRatio * containerRect.width) / westEastExtension) / Math.log(2)
        );
        const maxVerticalZoom = Math.floor(
            Math.log((highestViewRatio * containerRect.height) / northSouthExtension) / Math.log(2)
        );

        return Math.min(maxHorizontalZoom, maxVerticalZoom);
    };

    const onResize = useCallback(() => {
        setDefaultZoom(getDefaultZoom());
    }, []);

    useEffect(() => {
        onResize();

        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [onResize]);

    const handleReset = () => {
        resetCenter();
        resetActiveLocation();
        mapRef.current.setZoom(getDefaultZoom());
    };

    return (
        <MapContext.Provider value={{ mapRef, center, setCenter, activeLocation, setActiveLocation }}>
            <Styles.Container ref={containerRef}>
                {defaultZoom && (
                    <GoogleMap locationsJson={locationsJson} center={center} zoom={defaultZoom}>
                        <Styles.ResetButton onClick={handleReset}>
                            <Refresh height="30px" width="30px" />
                        </Styles.ResetButton>

                        {children}
                    </GoogleMap>
                )}

                <InfoCard />
            </Styles.Container>
        </MapContext.Provider>
    );
};

Map.displayName = "Map";

Map.defaultProps = {
    locationsJson: null,
    children: null,
};

Map.propTypes = {
    locationsJson: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default Map;
