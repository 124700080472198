import React from "react";
import PropTypes from "prop-types";

import { FormDataProvider } from "provider/form-data.provider";

import { Accordion } from "sections/accordion/accordion";
import { Actions } from "sections/actions/actions";
import { Board } from "sections/board/board";
import { Carousel } from "sections/carousel/carousel";
import { DonationButton } from "sections/donation-button/donation-button";
import { EmailForm } from "sections/email-form/email-form";
import { Field } from "sections/field/field";
import { Map } from "sections/map/map";
import { News } from "sections/news/news";
import { PartnerField } from "sections/partner-field/partner-field";
import { PieChart } from "sections/pie-chart/pie-chart";
import { ProjectButton } from "sections/project-button/project-button";
import { ProjectField } from "sections/project-field/project-field";
import { ProjectIntro } from "sections/project-intro/project-intro";
import { Quote } from "sections/quote/quote";
import { Splash } from "sections/splash/splash";
import { StandardText } from "sections/standard-text/standard-text";
import { Stats } from "sections/stats/stats";
import { Thanks } from "sections/thanks/thanks";
import { ThreePillars } from "sections/three-pillars/three-pillars";
import { VimeoVideo } from "sections/vimeo-video/vimeo-video";
import { YoutubeVideo } from "sections/youtube-video/youtube-video";

import Styles from "styles/shared.styles";

import { ErrorBoundary } from "utils/error-boundary";

const Section = ({ section, articles }) => {
    // eslint-disable-next-line react/prop-types,no-underscore-dangle
    switch (section.__component) {
        case "sections.accordion":
            return (
                <ErrorBoundary>
                    <Accordion {...section} />
                </ErrorBoundary>
            );
        case "sections.actions":
            return (
                <ErrorBoundary>
                    <Actions {...section} />
                </ErrorBoundary>
            );
        case "sections.board":
            return (
                <ErrorBoundary>
                    <Board {...section} />
                </ErrorBoundary>
            );
        case "sections.carousel":
            return (
                <ErrorBoundary>
                    <Carousel {...section} />
                </ErrorBoundary>
            );
        case "sections.donate-button":
            return (
                <ErrorBoundary>
                    <DonationButton {...section} />
                </ErrorBoundary>
            );
        case "sections.email-form":
            return (
                <ErrorBoundary>
                    <FormDataProvider>
                        <EmailForm {...section} />
                    </FormDataProvider>
                </ErrorBoundary>
            );
        case "sections.field":
            return (
                <ErrorBoundary>
                    <Field {...section} />
                </ErrorBoundary>
            );
        case "sections.map":
            return (
                <ErrorBoundary>
                    <Map {...section} />
                </ErrorBoundary>
            );
        case "sections.news":
            return (
                <ErrorBoundary>
                    <News articles={articles} {...section} />
                </ErrorBoundary>
            );
        case "sections.partner-field":
            return (
                <ErrorBoundary>
                    <PartnerField {...section} />
                </ErrorBoundary>
            );
        case "sections.pie-chart":
            return (
                <ErrorBoundary>
                    <PieChart {...section} />
                </ErrorBoundary>
            );
        case "sections.project-field":
            return (
                <ErrorBoundary>
                    <ProjectField articles={articles} {...section} />
                </ErrorBoundary>
            );
        case "sections.project-hero":
            return (
                <ErrorBoundary>
                    <ProjectIntro {...section} />
                </ErrorBoundary>
            );
        case "sections.secondary-button":
            return (
                <ErrorBoundary>
                    <ProjectButton {...section} />
                </ErrorBoundary>
            );
        case "sections.quote":
            return (
                <ErrorBoundary>
                    <Quote {...section} />
                </ErrorBoundary>
            );
        case "sections.splash":
            return (
                <ErrorBoundary>
                    <Splash {...section} />
                </ErrorBoundary>
            );
        case "sections.standard-text":
            return (
                <ErrorBoundary>
                    <StandardText {...section} />
                </ErrorBoundary>
            );
        case "sections.stats":
            return (
                <ErrorBoundary>
                    <Stats {...section} />
                </ErrorBoundary>
            );
        case "sections.thanks":
            return (
                <ErrorBoundary>
                    <Thanks {...section} />
                </ErrorBoundary>
            );
        case "sections.three-pillars":
            return (
                <ErrorBoundary>
                    <ThreePillars {...section} />
                </ErrorBoundary>
            );
        case "sections.youtube-video":
            return (
                <ErrorBoundary>
                    <YoutubeVideo {...section} />
                </ErrorBoundary>
            );
        case "sections.vimeo-video":
            return (
                <ErrorBoundary>
                    <VimeoVideo {...section} />
                </ErrorBoundary>
            );
        default:
            return null;
    }
};

Section.propTypes = {
    section: PropTypes.any.isRequired,
    articles: PropTypes.any.isRequired,
};

export const Content = ({ content, articles }) => {
    return (
        <Styles.Content>
            {content.map((section, i) => {
                // eslint-disable-next-line react/no-array-index-key
                return <Section section={section} articles={articles} key={i} />;
            })}
        </Styles.Content>
    );
};

Content.propTypes = {
    content: PropTypes.any.isRequired,
    articles: PropTypes.any.isRequired,
};

export default Content;
