import React from "react";
import PropTypes from "prop-types";

import { FormDataContext } from "context/form-data.context";

export const FormDataProvider = ({ children }) => {
    return <FormDataContext.Provider value={{ files: {}, data: {} }}>{children}</FormDataContext.Provider>;
};

FormDataProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default FormDataProvider;
