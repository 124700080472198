import styled from "styled-components";

const ImageWrapper = styled.div`
    display: block;
    margin: 0 auto;
    max-width: 1000px;
    position: relative;

    img {
        object-fit: contain;
        width: 100%;
    }
`;

export default {
    ImageWrapper,
};
