import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";

import { ErrorMessage } from "components/error-message/error-message";

import { Checkbox as CheckboxIcon } from "icons/checkbox";

import Styles from "./checkbox.styles";

export const Checkbox = ({ name, label, disabled, control, errors, defaultValues }) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValues[name]}
            render={({ field: { onChange, onFocus, onBlur, value } }) => {
                const checked = !!value;
                const hasError = !disabled && !!errors[name];

                return (
                    <Styles.Container>
                        <Styles.Label htmlFor={name} isDisabled={disabled} hasError={!!hasError}>
                            <Styles.Row>
                                <Styles.CheckBox hasError={!!hasError}>
                                    <CheckboxIcon isChecked={checked} width="25px" height="25px" />
                                </Styles.CheckBox>

                                <Styles.Input
                                    id={name}
                                    name={name}
                                    type="checkbox"
                                    disabled={disabled}
                                    checked={checked}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />

                                <Styles.Column>
                                    <Styles.TextField>{label}</Styles.TextField>

                                    {errors[name] && <ErrorMessage>{errors[name].message}</ErrorMessage>}
                                </Styles.Column>
                            </Styles.Row>
                        </Styles.Label>
                    </Styles.Container>
                );
            }}
        />
    );
};

Checkbox.defaultProps = {
    label: null,
    disabled: false,
};

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.any,
    disabled: PropTypes.bool,
    control: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    defaultValues: PropTypes.object.isRequired,
};

export default Checkbox;
