import styled, { css } from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Container = styled.div`
    background-color: ${(props) => props.backgroundColor};
    display: block;
    margin: 15px 0 0;
    text-align: left;
    height: 100%;
    width: 100%;

    &:first-child {
        margin-top: 0;
    }

    ${minMedia.desktop`
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0 20px;

        &:first-child {
            margin-left: 0;
         }

        &:last-child {
            margin-right: 0;
        }
    `};
`;

const ImageWrapper = styled.div`
    flex: 0 0 auto;
    height: 0;
    padding-bottom: ${(props) => (1 / props.aspectRatio) * 100}%;
    position: relative;
    width: 100%;

    img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        object-fit: cover;

        ${(props) =>
            props.padding > 0 &&
            css`
                padding: ${props.padding}% 0;
                object-fit: contain;
            `};
    }
`;

const Flag = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: ${Colors.WHITE_FFFFFF};
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    position: absolute;
    left: 0;
    top: 0;
    height: 33px;
    background-color: ${Colors.BLUE_063773};
    padding: 0 10px;
    width: 210px;
    z-index: 1;

    &:after {
        width: 0;
        height: 0;
        border-top: 33px solid ${Colors.BLUE_063773};
        border-right: 10px solid transparent;
        display: block;
        position: absolute;
        content: "";
        right: -10px;
        top: 0;
    }

    ${minMedia.desktop`
        height: 50px;
        padding: 0 25px;
        font-size: 20px;
        width: 285px;

        &:after {
            border-top: 50px solid ${Colors.BLUE_063773};
            border-right: 15px solid transparent;
            right: -15px;
        }
    `};
`;

const Content = styled.div`
    box-sizing: border-box;
    flex: 1 1 auto;
    padding: 20px 15px;
    text-align: ${(props) => props.textAlign};
    width: 100%;

    ${minMedia.desktop`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 25px 35px 40px;
    `};
`;

const TextContainer = styled.div`
    hyphens: auto;

    p {
        a,
        span.linkPlaceholder {
            color: ${Colors.BLUE_005EA8} !important;
            font-weight: ${(props) => props.theme.fontWeight.regular} !important;
        }
    }

    ${minMedia.desktop`
    h3 {
      display: block;
      min-height: 54px;
    }
  `};
`;

const Link = styled.span`
    color: ${Colors.BLUE_005EA8};
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 16px 0 0;
    text-decoration: none;

    ${minMedia.desktop`
        font-size: 20px;
        line-height: 27px;
        margin: 20px 0 0;
    `}
`;

export default {
    Container,
    ImageWrapper,
    Flag,
    Content,
    TextContainer,
    Link,
};
