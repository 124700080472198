import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

import { Link } from "utils/link";

const Nav = styled.div`
    // margin: 40px 0 20px;
    text-align: center;

    a,
    a > p,
    span {
        color: ${Colors.GREY_333333};
        display: inline;
        font-size: 12px;
        margin: 0;

        &:hover {
            opacity: 0.8;
        }
    }

    ${minMedia.desktop`
        text-align: left;
    `};
`;

const AntiDiscrimination = styled.div`
    color: ${Colors.GREY_333333};
    font-size: 12px;
    margin: 12px 0;
    text-align: center;

    ${minMedia.desktop`
        text-align: left;
    `};
`;

const Copyright = styled.div`
    color: ${Colors.GREY_333333};
    font-size: 12px;
    margin: 12px 0 22px;
    text-align: center;

    ${minMedia.desktop`
        text-align: left;
    `};
`;

const Row = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    width: 100%;

    ${minMedia.desktop`
        height: 100px;
    `};
`;

const LogoWrapper = styled.div`
    height: 25px;
    color: ${Colors.WHITE_FFFFFF};
    width: 100px;

    svg {
        height: inherit;
        width: inherit;
    }

    &:hover {
        opacity: 0.8;
    }

    ${minMedia.desktop`
        height: 45px;
        width: 200px;
    `};
`;

const SocialMedia = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 30px;
    width: 150px;

    ${minMedia.desktop`
        height: 50px;
        width: 200px;
    `};
`;

const SocialMediaLink = styled(Link)`
    cursor: pointer;
    color: ${Colors.WHITE_FFFFFF};
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7px;

    &:hover {
        opacity: 0.8;
    }

    ${minMedia.desktop`
        height: 50px;
        margin-left: 10px;
        width: 50px;
    `};
`;

export default {
    Nav,
    AntiDiscrimination,
    Copyright,
    Row,
    LogoWrapper,
    SocialMedia,
    SocialMediaLink,
};
