import styled from "styled-components";

import { minMedia } from "container/global.styles";

import SharedStyles from "styles/shared.styles";

const Container = styled.div`
    ${(props) => (props.withColor ? "padding" : "margin")}: 25px 0 25px;

    ${minMedia.desktop`
        text-align: center;
        ${(props) => (props.withColor ? "padding" : "margin")}: 70px 0 70px;
   `};
`;

const TextContainer = styled.div`
    text-align: ${(props) => props.textAlignMobile ?? "left"};
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    ${minMedia.desktop`
        margin-bottom: 30px;
        text-align: ${(props) => props.textAlignDesktop ?? "center"};
    `};
`;

const CarouselContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${minMedia.desktop`
        flex-direction: row;
    `};
`;

const More = styled(SharedStyles.TextButton)`
    align-items: center;
    display: flex;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    height: 50px;
    justify-content: center;
    width: 100%;
`;

const TileWrapper = styled.a`
    display: block;
    height: 100%;
    margin: 15px 0 0;
    text-align: left;
    transform: scale(1) translateY(0);
    transition: all 0.3s;
    width: 100%;

    &:first-child {
        margin-top: 0;
    }

    &:hover {
        transform: scale(1.01) translateY(-3px);
        box-shadow: 0 7px 8px 0 rgb(0 0 0 / 20%);
    }

    ${minMedia.desktop`
        margin: 0 20px;

        &:first-child {
            margin-left: 0;
         }

        &:last-child {
            margin-right: 0;
        }
    `};
`;

export default {
    Container,
    TextContainer,
    CarouselContainer,
    More,
    TileWrapper,
};
