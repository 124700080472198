import React, { forwardRef, useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import isEmpty from "lodash/isEmpty"
import PropTypes from "prop-types";

import { SelectorEnum } from "enumeration/selector.enum";

const useYupValidationResolver = (validationSchema) =>
    useCallback(
        async (data) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors, currentError) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

const scrollToError = () => {
    if (typeof window === "undefined" || typeof document === "undefined") {
        return;
    }

    let topPosition = null;
    let topEl = null;

    setTimeout(() => {
        const errorElements = document.querySelectorAll(`.${SelectorEnum.ERROR}`);
        errorElements.forEach((el) => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const verticalPosition = el.getBoundingClientRect().top + scrollTop;
            if (topPosition === null || verticalPosition < topPosition) {
                topPosition = verticalPosition;
                topEl = el;
            }
        });

        if (topEl !== null) {
            topEl.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, 200);
};

export const Form = forwardRef(({ defaultValues, validationSchema, onSubmit, children }, ref) => {
    const resolver = useYupValidationResolver(validationSchema);
    const formProps = useForm({ resolver });
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitted },
    } = formProps;

    useEffect(() => {
        if (isSubmitted && !isEmpty(errors)) {
            scrollToError();
        }
    }, [isSubmitted, errors]);

    return (
        <FormProvider {...formProps}>
            <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
                {children({
                    control,
                    errors,
                    defaultValues,
                })}
            </form>
        </FormProvider>
    );
});

Form.propTypes = {
    defaultValues: PropTypes.object.isRequired,
    validationSchema: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired,
};

export default Form;
