import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const Container = styled.div`
    position: relative;

    ${minMedia.desktop`
        width: 350px;
    `};
`;

const Button = styled.button`
    align-items: center;
    background-color: ${(props) => (props.disabled ? Colors.GREY_CDCDCD : Colors.BLUE_0271C2)};
    border: 1px solid ${(props) => (props.disabled ? Colors.GREY_CDCDCD : Colors.BLUE_0271C2)};
    border-radius: 25px;
    box-sizing: border-box;
    color: ${Colors.WHITE_FFFFFF};
    cursor: ${(props) => (props.disabled ? "initial" : "pointer")};
    display: flex;
    flex-direction: row;
    font-family: ${(props) => props.theme.buttonFont};
    font-size: 20px;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    justify-content: center;
    line-height: 20px;
    outline: none;
    height: 50px;
    text-decoration: none;
    width: 100%;

    &:hover {
        background-color: ${(props) => (props.disabled ? Colors.GREY_CDCDCD : Colors.BLUE_015FA4)};
        border: 1px solid ${(props) => (props.disabled ? Colors.GREY_CDCDCD : Colors.BLUE_015FA4)};
        color: ${Colors.WHITE_FFFFFF};
    }
`;

const SpinnerWrapper = styled.span`
    color: ${Colors.WHITE_FFFFFF};
    height: 30px;
    position: absolute;
    right: 40px;
    top: 10px;
`;

const Spinner = styled.span`
    height: ${(props) => props.size}px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: ${(props) => props.size}px;

    ${minMedia.desktop`
        height: ${(props) => props.size};
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: ${(props) => props.size};
    `};
`;

export default {
    Container,
    Button,
    SpinnerWrapper,
    Spinner,
};
