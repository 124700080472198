import React from "react";

import { Colors } from "container/global.colors";

import { EnumHelper } from "../../helper/enum-helper";
import { Image } from "../image/image";
import { HorizontalTileShape } from "./horizontal-tile.shape";
import Styles from "./horizontal-tile.styles";

export const HorizontalTile = ({ title, description, flag, backgroundColor, image, height }) => {
    return (
        <Styles.Container backgroundColor={EnumHelper.mapBackgroundColor(backgroundColor)} height={height}>
            <Styles.ImageContainer>
                <Styles.ImageWrapper width={height / 0.7}>
                    {flag && <Styles.Flag>{flag}</Styles.Flag>}

                    {image && image.url && <Image image={image} />}
                </Styles.ImageWrapper>
            </Styles.ImageContainer>

            <Styles.Content>
                <Styles.TextContainer>
                    <Styles.SubTitle>{title}</Styles.SubTitle>

                    <Styles.Paragraph>{description}</Styles.Paragraph>
                </Styles.TextContainer>

                {/* <Styles.Link href={linkUrl} title="mehr erfahren">
                    mehr erfahren
                </Styles.Link> */}
            </Styles.Content>
        </Styles.Container>
    );
};

HorizontalTile.defaultProps = {
    flag: null,
    backgroundColor: Colors.BLUE_DFEAF7,
    height: 330,
};

HorizontalTile.propTypes = HorizontalTileShape;

export default HorizontalTile;
