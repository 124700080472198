import styled from "styled-components";

import { minMedia } from "container/global.styles";

const Frame = styled.div`
    background-color: ${(props) => props.backgroundColor};
    position: relative;
    width: 100%;
`;

const Container = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;

    ${minMedia.desktop`
        padding: 0 30px;
    `};
`;

export default {
    Frame,
    Container,
};
