import styled from "styled-components";

import { Colors } from "container/global.colors";
import { minMedia } from "container/global.styles";

const StandardTextContainer = styled.div`
    text-align: ${(props) => props.textAlignMobile ?? "left"};
    margin: 25px 0 15px;

    ${minMedia.desktop`
        margin: 70px 0 30px;
        text-align: ${(props) => props.textAlignDesktop ?? "center"};
    `};
`;

const Frame = styled.div`
    margin: 20px 0;

    ${minMedia.desktop`
        background-color: ${(props) => props.backgroundColor};
        margin: 5px 0;
    `};
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;

    ${minMedia.desktop`
        align-items: center;
        display: table-row;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: ${(props) =>
            props.rtl ? `${props.width}% ${100 - props.width}%` : `${100 - props.width}% ${props.width}%`};
        min-height: ${(props) => props.minHeightDesktop}px;
    `};
`;

const ImageWrapper = styled.div`
    background-color: ${(props) => props.backgroundColor};
    display: flex;
    flex-direction: column;
    height: ${(props) => (props.objectFit === "contain" ? "initial" : "250px")};
    justify-content: center;
    min-height: 300px;
    position: relative;
    width: 100%;

    img {
        position: ${(props) => (props.objectFit === "contain" ? "relative" : "absolute")};
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        object-fit: ${(props) => props.objectFit};
        object-position: ${(props) => props.objectPosition ?? "center center"};
    }

    ${minMedia.desktop`
        align-self: stretch;
        height: 100%;
        min-height: ${(props) => props.minHeightDesktop}px;
        transform: translateX(${(props) => (props.rtl ? "30px" : "-30px")});
    `}
`;

const Content = styled.div`
    background-color: ${(props) => props.backgroundColor};
    box-sizing: border-box;
    padding: 20px 15px;
    width: 100%;

    ${minMedia.desktop`
        display: table-cell;
        flex-direction: column;
        flex: 1 1 660px;
        justify-content: center;
        margin: 0;
        padding: 70px 50px;
    `}
`;

const TextContainer = styled.div`
    hyphens: auto;

    p {
        a,
        span.linkPlaceholder {
            color: ${Colors.BLUE_005EA8} !important;
            font-weight: ${(props) => props.theme.fontWeight.regular} !important;
        }
    }
`;

const SubTitle = styled.div`
    color: ${Colors.GREY_575757};
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    margin: 0;
    overflow: hidden;

    ${minMedia.desktop`
        line-height: 27px;
        font-size: 20px;
        min-height: 81px;
    `}
`;

const Date = styled.div`
    border-bottom: 1px solid ${Colors.GREY_999999};
    color: ${Colors.GREY_999999};
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 16px 0 0;
    width: 63%;

    ${minMedia.desktop`
        border-bottom: 2px solid ${Colors.GREY_B4B4B4};
        font-size: 20px;
        line-height: 30px;
        margin: 20px 0 0;
    `}
`;

const Paragraph = styled.div`
    color: ${Colors.GREY_575757};
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 16px 0 0;

    ${minMedia.desktop`
        font-size: 20px;
        line-height: 27px;
        margin: 20px 0 0;
    `}
`;

const Link = styled.div`
    color: ${Colors.BLUE_005EA8};
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    margin: 16px 0 0;
    text-decoration: none;

    ${minMedia.desktop`
        font-size: 20px;
        line-height: 27px;
        margin: 20px 0 0;
    `}
`;

export default {
    StandardTextContainer,
    Frame,
    Container,
    ImageWrapper,
    Content,
    TextContainer,
    SubTitle,
    Date,
    Paragraph,
    Link,
};
