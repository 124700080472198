import React, { useState } from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";

import { ErrorMessage } from "components/error-message/error-message";

import { StringHelper } from "helper/string-helper";

import { Delete } from "icons/delete";

import Styles from "./input.styles";

export const Input = ({ name, label, placeholder, disabled, readOnly, hideDelete, control, errors, defaultValues }) => {
    const [hasFocus, setHasFocus] = useState(false);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValues[name]}
            render={({ field: { onChange, onFocus, onBlur, value } }) => {
                const hasValue = StringHelper.isNotNullOrEmpty(value);
                const hasLabel = StringHelper.isNotNullOrEmpty(label);
                const hasError = !disabled && !!errors[name];

                const handleFocus = (e) => {
                    if (readOnly) return;

                    setHasFocus(true);
                    if (onFocus) onFocus(e);
                };

                const handleBlur = (e) => {
                    if (readOnly) return;

                    setHasFocus(false);
                    if (onBlur) onBlur(e);
                };

                const handleReset = (e) => {
                    e.preventDefault();
                    onChange(defaultValues[name]);
                };

                return (
                    <Styles.InputRow>
                        <Styles.DesktopLabel>{label}</Styles.DesktopLabel>

                        <div>
                            <Styles.Container
                                as="label"
                                htmlFor={name}
                                hasFocus={hasFocus}
                                hasError={hasError}
                                isDisabled={disabled}
                            >
                                <Styles.Column>
                                    {hasLabel && (
                                        <Styles.MobileLabel hasFocus={hasFocus} hasError={hasError}>
                                            {label}
                                        </Styles.MobileLabel>
                                    )}

                                    <Styles.Row hasLabel={hasLabel}>
                                        <Styles.Input
                                            id={name}
                                            name={name}
                                            autoComplete="off"
                                            spellCheck="false"
                                            disabled={disabled}
                                            onFocus={handleFocus}
                                            placeholder={placeholder}
                                            onBlur={handleBlur}
                                            onChange={onChange}
                                            readOnly={readOnly}
                                            value={value}
                                        />
                                    </Styles.Row>
                                </Styles.Column>

                                {!readOnly && !hideDelete && hasValue && !disabled && (
                                    <Styles.Delete onClick={handleReset}>
                                        <Delete height="20px" width="20px" />
                                    </Styles.Delete>
                                )}
                            </Styles.Container>

                            {errors[name] && <ErrorMessage>{errors[name].message}</ErrorMessage>}
                        </div>
                    </Styles.InputRow>
                );
            }}
        />
    );
};

Input.defaultProps = {
    label: null,
    placeholder: null,
    disabled: false,
    readOnly: false,
    hideDelete: false,
};

Input.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    hideDelete: PropTypes.bool,
    control: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    defaultValues: PropTypes.object.isRequired,
};

export default Input;
